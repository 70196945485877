import { Box, Title, TitleOrder } from '@mantine/core';
import { TextFragment } from './TextFragment';
import { HeadingTextFragment } from './HeadingTextFragment';
import { useScaleToContainer } from '@/hooks/useScaleToContainer';

function extractHeadingLevel(string: string): TitleOrder {
  const match = string.match(/\d+/);
  const level = match ? parseInt(match[0], 10) : 1;
  return level > 3 ? 3 : (level as TitleOrder);
}

export function Paragraph({ item, formValues = {} }) {
  const paragraphStyle = item.paragraphStyle;
  const headingLevel =
    paragraphStyle === 'title'
      ? 1
      : paragraphStyle === 'subtitle'
        ? 2
        : extractHeadingLevel(paragraphStyle);

  if (
    paragraphStyle === 'title' ||
    paragraphStyle === 'subtitle' ||
    paragraphStyle.includes('heading')
  ) {
    return (
      <Title
        ref={useScaleToContainer({
          fontSize: formValues[`h${headingLevel}_font_size`],
          marginBottom: '32px',
        })}
        order={headingLevel}
        mb="2rem"
        style={{
          color: formValues[`h${headingLevel}_font_color`],
          fontFamily: formValues[`h${headingLevel}_font_family`],
          fontWeight: formValues[`h${headingLevel}_font_weight`],
          fontSize: formValues[`h${headingLevel}_font_size`],
          textAlign: formValues[`h${headingLevel}_alignment`],
          fontStyle: formValues[`h${headingLevel}_italic`] ? 'italic' : 'normal',
          textDecoration:
            `${formValues[`h${headingLevel}_underlined`] ? 'underline ' : ''}${formValues[`h${headingLevel}_strikethrough`] ? 'line-through' : ''}`.trim(),
        }}
      >
        {item.data.map((d, index) => (
          <HeadingTextFragment fragment={d} key={index} formValues={formValues} />
        ))}
      </Title>
    );
  }

  return (
    <Box
      ref={useScaleToContainer({
        fontSize: formValues.body_font_size,
      })}
      style={{
        color: formValues.body_font_color,
        fontFamily: formValues.body_font_family,
        fontWeight: formValues.body_font_weight,
        fontSize: formValues.body_font_size,
        textAlignment: formValues.body_alignment,
        fontStyle: formValues.body_italic ? 'italic' : 'normal',
        textDecoration:
          `${formValues.body_underlined ? 'underline ' : ''}${formValues.body_strikethrough ? 'line-through' : ''}`.trim(),
      }}
    >
      {item.data.map((d, index) => (
        <TextFragment fragment={d} key={index} formValues={formValues} />
      ))}
    </Box>
  );
}
