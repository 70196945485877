import { Button, Container, Group, Text, Title } from '@mantine/core';
import classes from './NotFoundTitle.module.css';

export function NotFoundTitle() {
  return (
    <Container className={classes.root}>
      <div className={classes.label}>404</div>
      <Title className={classes.title}>Presentation not found.</Title>
      <Text c="dimmed" size="lg" ta="center" className={classes.description}>
        Presentation was not found for the given presentation ID.
      </Text>
      <Group justify="center">
        <Button component="a" href="/" variant="subtle" size="md">
          Back to my presentations
        </Button>
      </Group>
    </Container>
  );
}
