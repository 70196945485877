import { useRef, useEffect } from 'react';
import { IconCloudUpload, IconDownload, IconX } from '@tabler/icons-react';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { Group, Text, Button, Paper, Image, CloseButton, Title, Box, rem } from '@mantine/core';
import classes from './ImageUpload.module.css';
import { uploadLogo, getPresentation } from '@/requests';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { notifications } from '@mantine/notifications';
import { processError } from '@/helpers';

export function ImageUpload({ form, formValues }) {
  const openRef = useRef<() => void>(null);
  const { id } = useParams();
  const presentationRef = useRef(null);
  const queryClient = useQueryClient();

  const { data: presentation, isLoading: presentationLoading } = useQuery({
    queryKey: ['presentation', id],
    queryFn: () => getPresentation(id),
  });

  // Avoid stale value of presentationRef due to closures.
  useEffect(() => {
    presentationRef.current = presentation;
    form.setValues({
      logo_url: presentation.theme?.logo_url,
      attachment_id: presentation.theme?.attachment_id,
    });
  }, [presentation]);

  const uploadLogoMutation = useMutation({
    mutationFn: uploadLogo,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['presentation', id] });
      notifications.show({
        message: 'Logo updated successfully!',
      });
    },
    onError: processError,
  });

  const handleDrop = (files) => {
    const file = files[0];
    if (file) {
      const formData = new FormData();
      formData.append('logo', file);
      formData.append('id', presentationRef.current?.theme?.id);
      formData.append('presentationId', id);
      uploadLogoMutation.mutate(formData);
    }
  };

  const handleRemove = () => {
    const formData = new FormData();
    formData.append('logo', '');
    formData.append('id', presentationRef.current?.theme?.id);
    formData.append('presentationId', id);
    uploadLogoMutation.mutate(formData);
  };

  return (
    <Box ta="center" pos="relative" mb="md">
      <Dropzone
        w="100%"
        openRef={openRef}
        onDrop={handleDrop}
        className={classes.dropzone}
        radius="md"
        accept={IMAGE_MIME_TYPE}
        maxSize={30 * 1024 ** 2}
      >
        <div style={{ pointerEvents: 'none' }}>
          <Group justify="center">
            <Dropzone.Accept>
              <IconDownload style={{ width: rem(50), height: rem(50) }} color="blue" stroke={1.5} />
            </Dropzone.Accept>
            <Dropzone.Reject>
              <IconX style={{ width: rem(50), height: rem(50) }} color="red" stroke={1.5} />
            </Dropzone.Reject>
            <Dropzone.Idle>
              <IconCloudUpload style={{ width: rem(50), height: rem(50) }} stroke={1.5} />
            </Dropzone.Idle>
          </Group>

          <Text ta="center" fw={700} fz="lg" mt="xl">
            <Dropzone.Accept>Drop files here</Dropzone.Accept>
            <Dropzone.Reject>Image file less than 10mb</Dropzone.Reject>
            <Dropzone.Idle>Upload image</Dropzone.Idle>
          </Text>
          <Text ta="center" fz="sm" mt="xs" c="dimmed">
            Drag&apos;n&apos;drop files here to upload.
          </Text>
        </div>
      </Dropzone>

      <Button
        className={classes.control}
        size="md"
        mb="md"
        radius="xl"
        onClick={() => openRef.current?.()}
      >
        Select files
      </Button>
      {formValues.logo_url && (
        <Paper>
          <Title order={5} mb="md">
            Current logo
          </Title>
          <Group justify="space-between">
            <Image radius="md" w="70%" src={formValues.logo_url} />
            <CloseButton onClick={handleRemove} />
          </Group>
        </Paper>
      )}
    </Box>
  );
}
