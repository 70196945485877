import { processError } from '@/helpers';
import { useScaleToContainer } from '@/hooks/useScaleToContainer';
import { deleteSlide, updatePresentation } from '@/requests';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import {
  ActionIcon,
  Anchor,
  Box,
  Flex,
  Group,
  Image,
  List,
  Paper,
  Stack,
  Table,
  Text,
  Tooltip,
  getGradient,
  useMantineTheme,
} from '@mantine/core';
import { IconTrashFilled, IconVideo, IconWorldWww, IconX } from '@tabler/icons-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { Paragraph } from './Paragraph';
import { TextFragment } from './TextFragment';
import { useSearchParams } from 'react-router-dom';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';

function Element({ item, formValues }) {
  switch (item.type) {
    case 'text':
      return <Paragraph item={item} formValues={formValues} />;
    case 'image':
      return (
        <Image
          ref={useScaleToContainer({ marginBottom: '32px' })}
          src={item.url}
          alt="Image"
          mb="2rem"
        />
      );
    case 'video':
      return (
        <Flex h="100%" w="100%" align="center" justify="center">
          <Tooltip label={item.url}>
            <Anchor href={item.url} target="_blank">
              <IconVideo />
            </Anchor>
          </Tooltip>
        </Flex>
      );
    case 'website':
      return (
        <Flex h="100%" w="100%" align="center" justify="center">
          <Tooltip label={item.url}>
            <Anchor href={item.url} target="_blank">
              <IconWorldWww />
            </Anchor>
          </Tooltip>
        </Flex>
      );
    case 'list':
      return (
        <List
          withPadding
          spacing="2px"
          ref={useScaleToContainer({
            fontSize: formValues.body_font_size,
          })}
          style={{
            color: formValues.body_font_color,
            fontFamily: formValues.body_font_family,
            fontWeight: formValues.body_font_weight,
            fontSize: formValues.body_font_size,
            textAlign: formValues.body_alignment,
            fontStyle: formValues.body_italic ? 'italic' : 'normal',
            textDecoration:
              `${formValues.body_underlined ? 'underline ' : ''}${formValues.body_strikethrough ? 'line-through' : ''}`.trim(),
          }}
          center
        >
          {item.data.map((listItem, index) => (
            <List.Item key={index} style={{ lineHeight: 1.5 }}>
              {listItem.data.map((textFragment, index) => (
                <TextFragment fragment={textFragment} key={index} formValues={formValues} />
              ))}
            </List.Item>
          ))}
        </List>
      );

    case 'table':
      return (
        <Table
          style={{
            color: formValues.body_font_color,
            fontFamily: formValues.body_font_family,
            fontWeight: formValues.body_font_weight,
            fontSize: formValues.body_font_size,
            textAlign: formValues.body_alignment,
            fontStyle: formValues.body_italic ? 'italic' : 'normal',
            textDecoration:
              `${formValues.body_underlined ? 'underline ' : ''}${formValues.body_strikethrough ? 'line-through' : ''}`.trim(),
          }}
        >
          <Table.Thead>
            <Table.Tr>
              {item.data[0].data
                .map((cell, cellIndex) => {
                  if (cell.colspan === 0 && cell.rowspan === 0) return;

                  return (
                    <Table.Th
                      key={cellIndex}
                      rowSpan={cell.rowspan}
                      colSpan={cell.colspan}
                      style={{
                        backgroundColor: cell.backgroundColor,
                      }}
                    >
                      {cell.data.map((d, index) => (
                        <Paragraph item={d} key={index} />
                      ))}
                    </Table.Th>
                  );
                })
                .filter((cell) => cell)}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {item.data.slice(1).map((row, rowIndex) => (
              <Table.Tr key={rowIndex}>
                {row.data.map((cell, cellIndex) => {
                  if (cell.colspan === 0 && cell.rowspan === 0) return;

                  return (
                    <Table.Td key={cellIndex} rowSpan={cell.rowspan} colSpan={cell.colspan}>
                      {cell.data.map((d, index) => (
                        <Paragraph item={d} key={index} />
                      ))}
                    </Table.Td>
                  );
                })}
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      );
    default:
      return null;
  }
}

export function PageList({ presentation, closePageList, formValues }) {
  const [searchParams] = useSearchParams();
  const [slides, setSlides] = useState(presentation.content);
  const [selectedPageIndex, setSelectedPageIndex] = useState(parseInt(searchParams.get('slide')));
  const mantineTheme = useMantineTheme();
  const queryClient = useQueryClient();
  const updatePresentationMutation = useMutation({
    mutationFn: updatePresentation,
    onSuccess: (presentation) => {
      setSlides(presentation.content);
      queryClient.invalidateQueries({ queryKey: ['presentation', presentation.presentation_id] });
    },
    onError: processError,
  });
  const deleteSlideMutation = useMutation({
    mutationFn: deleteSlide,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['presentation', presentation.presentation_id] });
      notifications.show({
        message: `Successfully deleted slide.`,
      });
    },
    onError: processError,
  });
  const openModal = (slideId) =>
    modals.openConfirmModal({
      title: 'Are you sure?',
      children: <Text size="sm">This action is irreversible.</Text>,
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () =>
        deleteSlideMutation.mutate({
          presentation_id: presentation.presentation_id,
          id: slideId,
        }),
    });

  useEffect(() => {
    setSlides(presentation.content);
  }, [presentation]);

  useEffect(() => {
    setSelectedPageIndex(parseInt(searchParams.get('slide')));
  }, [searchParams]);

  return (
    <DragDropContext
      onDragEnd={({ destination, source }) => {
        updatePresentationMutation.mutate({
          id: presentation.presentation_id,
          slides_order: slides
            .map((slide, index) => {
              if (index === source.index) return slides[destination.index];
              if (index === destination.index) return slides[source.index];
              return slide;
            })
            .map((slide, index) => ({
              id: slide.id,
              order: index,
            })),
        });
      }}
    >
      <Droppable droppableId="dnd-list" direction="vertical">
        {(provided) => (
          <Paper
            ref={provided.innerRef}
            p="xs"
            pt="xl"
            shadow="md"
            withBorder
            h="600px"
            w="200px"
            pos="absolute"
            style={{
              zIndex: 102,
              top: 5,
              left: 50,
              overflow: 'auto',
            }}
            {...provided.droppableProps}
          >
            <Group pos="absolute" gap="0" style={{ top: 5, right: 5 }}>
              <Tooltip label="Close">
                <ActionIcon
                  onClick={() => closePageList()}
                  radius="sm"
                  size="sm"
                  variant="subtle"
                  aria-label="Close"
                  c="bright"
                  className="cancel"
                >
                  <IconX style={{ width: '80%', height: '80%' }} stroke={1.5} />
                </ActionIcon>
              </Tooltip>
            </Group>
            <Stack gap="10px">
              {slides.map((slide, index) => (
                <Draggable key={slide.id} index={index} draggableId={String(slide.id)}>
                  {(provided, snapshot) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      onClick={() => {
                        window.emblaInstance.scrollTo(index);
                        setSelectedPageIndex(index);
                      }}
                    >
                      <Group gap="1px">
                        <Paper
                          bd={selectedPageIndex === index && '4px solid #FFBF00'}
                          h="70px"
                          w="140px"
                          p="5px"
                          style={{
                            overflow: 'hidden',
                            backgroundColor: formValues.background_color,
                            backgroundImage:
                              formValues.background_color_type === 'solid'
                                ? 'none'
                                : getGradient(
                                    {
                                      deg: formValues.background_color_gradient_angle,
                                      from: formValues.background_color_gradient_from,
                                      to: formValues.background_color_gradient_to,
                                    },
                                    mantineTheme
                                  ),
                          }}
                        >
                          {slide.elements.map((item, index) => (
                            <Element item={item} key={index} formValues={formValues} />
                          ))}
                        </Paper>
                        <Tooltip label="Delete slide">
                          <ActionIcon
                            variant="subtle"
                            size="sm"
                            aria-label="Delete slide"
                            onClick={(e) => {
                              e.preventDefault();
                              openModal(slide.id);
                            }}
                          >
                            <IconTrashFilled style={{ width: '70%', height: '70%' }} stroke={1.5} />
                          </ActionIcon>
                        </Tooltip>
                      </Group>
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Stack>
          </Paper>
        )}
      </Droppable>
    </DragDropContext>
  );
}
