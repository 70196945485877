import { containsMath } from '@/helpers';
import { Text, Anchor } from '@mantine/core';
import { MathJax } from 'better-react-mathjax';

type Style = {
  color?: string;
  backgroundColor?: string;
};

export function TextFragment({ fragment, formValues }) {
  const {
    content,
    bold,
    italic,
    underline,
    strikethrough,
    superscript,
    subscript,
    color,
    backgroundColor,
    linkUrl,
  } = fragment;

  let textElement = undefined;

  const initialContent = () => {
    if (superscript) return <sup>{content}</sup>;
    if (subscript) return <sub>{content}</sub>;

    return content;
  };

  if (linkUrl) {
    textElement = (
      <Anchor
        c={formValues?.all_headings_font_color}
        href={linkUrl}
        target="_blank"
        fw={bold ? 700 : ''}
        fs={italic ? 'italic' : ''}
        td={`underline ${strikethrough ? 'line-through' : ''}`.trim()}
        inherit
      >
        {initialContent()}
      </Anchor>
    );
  } else if (containsMath(content)) {
    return <MathJax>{initialContent()}</MathJax>;
  } else {
    let style: Style = {};
    if (color) style.color = color;
    if (backgroundColor) style.backgroundColor = backgroundColor;

    textElement = (
      <Text
        fw={bold ? 700 : ''}
        fs={italic ? 'italic' : ''}
        td={strikethrough ? 'line-through' : underline ? 'underline' : ''}
        style={style}
        span
        inherit
      >
        {initialContent()}
      </Text>
    );
  }

  return textElement;
}
