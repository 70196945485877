import {
  ActionIcon,
  Box,
  Button,
  Container,
  Grid,
  Group,
  Paper,
  rem,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core';
import { IconBackspace, IconMaximize, IconMinimize, IconX } from '@tabler/icons-react';
import { useState } from 'react';
import { Rnd } from 'react-rnd';

function CalculatorButton({ value, handleButtonClick, fullscreen }) {
  return (
    <Button
      h={fullscreen ? 90 : 50}
      fullWidth
      onClick={() => handleButtonClick(value)}
      variant={value === '=' ? 'filled' : 'subtle'}
      className="cancel"
    >
      {value === 'Backspace' ? (
        <IconBackspace
          style={{ width: fullscreen ? 50 : 25, height: fullscreen ? 50 : 25 }}
          stroke={1.2}
        />
      ) : (
        <Text fz={fullscreen ? rem(35) : rem(21)}>{value}</Text>
      )}
    </Button>
  );
}

export function Calculator({ closeCalculator }) {
  const [position, setPosition] = useState({ x: 50, y: 5 });
  const [display, setDisplay] = useState('');
  const [fullscreen, setFullscreen] = useState(false);
  const [result, setResult] = useState('');
  const [resultCalled, setResultCalled] = useState(false);
  const buttons = [
    'C',
    '%',
    'Backspace',
    '÷',
    '7',
    '8',
    '9',
    '×',
    '4',
    '5',
    '6',
    '−',
    '1',
    '2',
    '3',
    '+',
    '00',
    '0',
    '.',
    '=',
  ];

  const operators = ['+', '−', '×', '÷'];
  const expression = (str) =>
    str.replace(/÷/g, '/').replace(/×/g, '*').replace(/−/g, '-').replace(/%/g, '/100');

  const handleButtonClick = (value) => {
    if (value === 'C') {
      setDisplay('');
      setResult('');
    } else if (value === 'Backspace') {
      setDisplay(display.slice(0, -1));
    } else if (value === '=') {
      setResultCalled(true);
      try {
        setDisplay(eval(expression(display)).toString());
        setResult('');
      } catch {
        setResult('Error');
      }
    } else {
      const lastChar = display[display.length - 1];
      if (operators.includes(lastChar) && operators.includes(value)) {
        return;
      }
      setDisplay((prev) => {
        if (resultCalled) {
          setResultCalled(false);
          return value;
        } else {
          const newString = prev + value;
          try {
            setResult(eval(expression(newString).toString()));
            return newString;
          } catch {
            return newString;
          }
        }
      });
    }
  };

  return (
    <Rnd
      enableResizing={false}
      disableDragging={fullscreen}
      style={{ zIndex: 102 }}
      position={position}
      onDragStop={(e, d) => {
        setPosition({ x: d.x, y: d.y });
      }}
      cancel=".cancel"
    >
      <Paper
        p="md"
        w={fullscreen ? '100vw' : 300}
        h={fullscreen ? 'calc(100dvh - var(--app-shell-header-offset, 0rem))' : 'auto'}
      >
        <Group pos="absolute" gap="0" style={{ top: 5, right: 5 }}>
          {fullscreen ? (
            <Tooltip label="Exit Fullscreen">
              <ActionIcon
                onClick={() => {
                  setFullscreen(false);
                  setPosition({ x: 50, y: 5 });
                }}
                radius="sm"
                size="sm"
                variant="subtle"
                aria-label="Minimize"
                c="bright"
                className="cancel"
              >
                <IconMinimize style={{ width: '80%', height: '80%' }} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          ) : (
            <Tooltip label="Fullscreen">
              <ActionIcon
                onClick={() => {
                  setFullscreen(true);
                  setPosition({ x: 0, y: 0 });
                }}
                radius="sm"
                size="sm"
                variant="subtle"
                aria-label="Maximize"
                c="bright"
                className="cancel"
              >
                <IconMaximize style={{ width: '80%', height: '80%' }} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          )}
          <Tooltip label="Close calculator">
            <ActionIcon
              onClick={closeCalculator}
              radius="sm"
              size="sm"
              variant="subtle"
              aria-label="Close"
              c="bright"
              className="cancel"
            >
              <IconX style={{ width: '80%', height: '80%' }} stroke={1.5} />
            </ActionIcon>
          </Tooltip>
        </Group>

        <Container p={0} w={fullscreen ? 700 : 'auto'}>
          <Box p="md" mb="md">
            <Text ta="right" size={fullscreen ? rem(80) : rem(40)}>
              {display || '0'}
            </Text>
            <Stack h={40} justify="end">
              <Text ta="right" size="xl" c="dimmed">
                {result}
              </Text>
            </Stack>
          </Box>

          <Grid gutter={0}>
            {buttons.map((value, index) => (
              <Grid.Col span={3} key={index}>
                <CalculatorButton
                  fullscreen={fullscreen}
                  value={value}
                  handleButtonClick={handleButtonClick}
                />
              </Grid.Col>
            ))}
          </Grid>
        </Container>
      </Paper>
    </Rnd>
  );
}
