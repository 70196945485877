import { calculateNewSetting } from '@/helpers';
import { useEffect, useRef } from 'react';

export function useScaleToContainer(initialStyles, containerElement = '.mantine-Carousel-root') {
  const ref = useRef(null);

  useEffect(() => {
    const updateProperties = () =>
      Object.keys(initialStyles).forEach((property) => {
        if (!initialStyles[property]) return;

        const element = ref.current;
        if (element) {
          const container =
            element?.closest(containerElement) || element?.closest('.mantine-Paper-root');
          const width = container.clientWidth;

          if (property.startsWith('--')) {
            // Custom CSS properties, e.g. '--line-spacing'
            element.style.setProperty(
              property,
              calculateNewSetting(width, initialStyles[property])
            );
          } else {
            element.style[property] = calculateNewSetting(width, initialStyles[property]);
          }
        }
      });

    updateProperties();
    window.addEventListener('resize', updateProperties);
  }, [initialStyles]);

  return ref;
}
