import { Accordion, Anchor, Container, rem, Text, Title } from '@mantine/core';

export function Faqs() {
  return (
    <Container size="lg" p="lg" pt="xl">
      <Title order={2} fz={{ base: rem(40), sm: rem(50) }} ta="center" mb="xl" fw="800">
        Frequently Asked Questions
      </Title>
      <Accordion>
        <Accordion.Item value="after-subscribing">
          <Accordion.Control>
            <Text fw="700" fz="18px">
              What happens after I've subscribed?
            </Text>
          </Accordion.Control>
          <Accordion.Panel>
            After subscribing, return to the{' '}
            <Text
              component="span"
              fw="800"
              variant="gradient"
              gradient={{ from: '#FE9920', to: '#FF2A77', deg: 90 }}
              inherit
            >
              Slides Generator
            </Text>{' '}
            add-on and refresh the page. You should see a message showing when your subscription
            ends and will be able to continue using the add-on. If you have any issues accessing the
            add-on, please <Anchor href="#tally-open=mJ6BOY&tally-emoji-text=👋&tally-emoji-animation=wave">Contact Us</Anchor>.
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="cancel-subscription">
          <Accordion.Control>
            <Text fw="700" fz="18px">
              How do I cancel my subscription?
            </Text>
          </Accordion.Control>
          <Accordion.Panel>
            You can cancel your subscription at any time. Visit{' '}
            <Anchor href="/help/how-do-i-cancel-my-subscription">
              How to cancel your subscription
            </Anchor>{' '}
            for detailed instructions.
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="run-slides-generator">
          <Accordion.Control>
            <Text fw="700" fz="18px">
              How do I run{' '}
              <Text
                component="span"
                fw="800"
                variant="gradient"
                gradient={{ from: '#FE9920', to: '#FF2A77', deg: 90 }}
                inherit
              >
                Slides Generator
              </Text>
              ?
            </Text>
          </Accordion.Control>
          <Accordion.Panel>
            Once you have installed{' '}
            <Text
              component="span"
              fw="800"
              variant="gradient"
              gradient={{ from: '#FE9920', to: '#FF2A77', deg: 90 }}
              inherit
            >
              Slides Generator
            </Text>
            , the add-on will become available in the right-hand side panel in Google Docs™ or
            Google Slides™.
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="need-help">
          <Accordion.Control>
            <Text fw="700" fz="18px">
              I need some help! Where can I go?
            </Text>
          </Accordion.Control>
          <Accordion.Panel>
            We're always happy to help! Feel free to reach out via our Support page.
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="google-workspace-account">
          <Accordion.Control>
            <Text fw="700" fz="18px">
              Do I need a Google Workspace Account to use{' '}
              <Text
                component="span"
                fw="800"
                variant="gradient"
                gradient={{ from: '#FE9920', to: '#FF2A77', deg: 90 }}
                inherit
              >
                Slides Generator
              </Text>
              ?
            </Text>
          </Accordion.Control>
          <Accordion.Panel>
            Nope. Simply log in with your Gmail account and install the add-on from the{' '}
            <Anchor href="https://workspace.google.com/marketplace/app/slides_generator/398255519813">
              Google Workspace Marketplace
            </Anchor>
            .
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="active-user">
          <Accordion.Control>
            <Text fw="700" fz="18px">
              What is an "active user"?
            </Text>
          </Accordion.Control>
          <Accordion.Panel>
            Active users are relevant only in relation to the team quota. Only active users are
            counted in the team quota. An active user is one who has accessed the{' '}
            <Text
              component="span"
              fw="800"
              variant="gradient"
              gradient={{ from: '#FE9920', to: '#FF2A77', deg: 90 }}
              inherit
            >
              Slides Generator
            </Text>{' '}
            add-on in the last 7 days. After 7 days of inactivity, the user will be marked as
            inactive.
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="store-personal-data">
          <Accordion.Control>
            <Text fw="700" fz="18px">
              Do you store any Personal Identifiable Information?
            </Text>
          </Accordion.Control>
          <Accordion.Panel>
            The only information we store is the email address of the user. We store this encrypted
            with a non-deterministic approach for encryption. This approach improves security by
            making crypto-analysis of ciphertexts harder, and querying the database impossible.
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="team-license">
          <Accordion.Control>
            <Text fw="700" fz="18px">
              How do I subscribe to a team license?
            </Text>
          </Accordion.Control>
          <Accordion.Panel>
            To subscribe to a team license, sign in to your Google account, click Upgrade in the top
            navbar and select the team license. You will be redirected to Stripe to complete
            payment. Once payment is complete,{' '}
            <Text
              component="span"
              fw="800"
              variant="gradient"
              gradient={{ from: '#FE9920', to: '#FF2A77', deg: 90 }}
              inherit
            >
              Slides Generator
            </Text>{' '}
            will be available to all users on your team.
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="quotas">
          <Accordion.Control>
            <Text fw="700" fz="18px">
              Are there any quotas to be aware of?
            </Text>
          </Accordion.Control>
          <Accordion.Panel>
            Use of the app is subject to Quotas for Google Services. There is a limit of 250 slides
            a day for gmail.com accounts or 1,500 per day for Google Workspace accounts. And 250
            presentations a day for gmail.com accounts or 1,500 presentations a day for Google
            Workspace accounts.
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Container>
  );
}
