import { loadFont, normalize, processError } from '@/helpers';
import { deletePresentation, getFonts } from '@/requests';
import { ActionIcon, Card, Group, SimpleGrid, Text, Title, Tooltip } from '@mantine/core';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import { IconInfoCircle, IconTrashFilled } from '@tabler/icons-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Screens } from '../Presentation/Screens';

export function Presentations({ user }) {
  const queryClient = useQueryClient();
  const { data: presentations, isLoading: presentationsLoading } = useQuery({
    queryKey: ['presentations'],
    queryFn: () =>
      fetch(`/api/presentations/`, {
        headers: {
          Authorization: `Bearer ${document.getElementById('root').getAttribute('data-key')}`,
        },
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error('Failed to fetch');
          }
          return res.json();
        })
        .then((data) => {
          return data.presentations;
        }),
  });

  const deletePresentationMutation = useMutation({
    mutationFn: (id) => deletePresentation(id),
    onSuccess: (data) => {
      queryClient.setQueryData(['presentations'], (oldData: []) => {
        if (!oldData) return oldData;

        return oldData.filter(
          (presentation) => presentation.presentation_id !== data.presentation_id
        );
      });
      notifications.show({
        message: `Successfully deleted ${presentation.title}!`,
      });
    },
    onError: processError,
  });

  const openModal = (presentationId) =>
    modals.openConfirmModal({
      title: 'Are you sure?',
      children: <Text size="sm">This action is irreversible.</Text>,
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => deletePresentationMutation.mutate(presentationId),
    });

  const { data: fonts } = useQuery({
    queryKey: ['fonts'],
    queryFn: getFonts,
  });
  const normalizedFonts = normalize(fonts);

  useEffect(() => {
    if (presentations && presentations.length) {
      if (Object.values(normalizedFonts).length === 0) return;

      presentations.forEach((presentation) => {
        if (!presentation.theme || !presentation.theme.styles) return;
        if (presentation.theme.styles.h1_font_family) {
          loadFont(
            normalizedFonts[presentation.theme.styles.h1_font_family],
            presentation.theme.styles.h1_font_weight
          );
        }
        if (presentation.theme.styles.h2_font_family) {
          loadFont(
            normalizedFonts[presentation.theme.styles.h2_font_family],
            presentation.theme.styles.h2_font_weight
          );
        }
        if (presentation.theme.styles.h3_font_family) {
          loadFont(
            normalizedFonts[presentation.theme.styles.h3_font_family],
            presentation.theme.styles.h3_font_weight
          );
        }
        if (presentation.theme.styles.body_font_family) {
          loadFont(
            normalizedFonts[presentation.theme.styles.body_font_family],
            presentation.theme.styles.body_font_weight
          );
        }
      });
    }
  }, [presentations, normalizedFonts]);

  return (
    <>
      <Title order={2} my="md">
        View Presentations
      </Title>
      <SimpleGrid cols={{ base: 1, md: 2, lg: 3, xl: 4 }}>
        {presentations?.map((presentation) => (
          <Card
            shadow="sm"
            padding="lg"
            radius="md"
            withBorder
            component="a"
            href={`/presentations/${presentation.presentation_id}`}
          >
            <Card.Section>
              <Screens
                sidenavOpened={false}
                fullscreen={false}
                isLoading={presentationsLoading}
                formValues={presentation.theme?.styles || {}}
                presentation={presentation}
                height="250px"
                displayOnly
              />
            </Card.Section>

            <Group justify="space-between" align="center">
              <Text fw={500} my="md" flex="1">
                {presentation.title}
              </Text>
              <ActionIcon
                variant="subtle"
                aria-label="Delete presentation"
                onClick={(e) => {
                  e.preventDefault();
                  openModal(presentation.presentation_id);
                }}
              >
                <IconTrashFilled style={{ width: '70%', height: '70%' }} stroke={1.5} />
              </ActionIcon>
            </Group>

            {!user.active_subscription && (
              <Group justify="right" align="center" gap="2px">
                <Text size="sm">{presentation.days_remaining} days remaining</Text>
                <Tooltip label="Days remaining until this presentation is deleted. Presentations are stored for 7 days from when they were created. Upgrade to store your presentations indefinitely.">
                  <IconInfoCircle stroke={1.5} />
                </Tooltip>
              </Group>
            )}
          </Card>
        ))}
      </SimpleGrid>
    </>
  );
}
