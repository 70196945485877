import { Website } from '@/components/Website/Website';
import { useScaleToContainer } from '@/hooks/useScaleToContainer';
import { Image, List, Table, ThemeIcon } from '@mantine/core';
import { Paragraph } from './Paragraph';
import { TextFragment } from './TextFragment';
import { Video } from './Video';

function extractYouTubeID(url) {
  const pattern = /(?:v=)([a-zA-Z0-9_-]+)/;
  const match = url.match(pattern);
  return match ? match[1] : null;
}

export function Element({ item, formValues }) {
  switch (item.type) {
    case 'text':
      return <Paragraph item={item} formValues={formValues} />;
    case 'image':
      return (
        <Image
          ref={useScaleToContainer({ marginBottom: '32px' })}
          src={item.url}
          alt="Image"
          mb="2rem"
        />
      );
    case 'video':
      return <Video src={`https://www.youtube.com/embed/${extractYouTubeID(item.url)}`} />;
    case 'website':
      return <Website src={item.url} />;
    case 'list':
      return (
        <List
          withPadding
          spacing="xl"
          ref={useScaleToContainer({
            fontSize: formValues.body_font_size,
            '--list-spacing': '32px',
          })}
          style={{
            color: formValues.body_font_color,
            fontFamily: formValues.body_font_family,
            fontWeight: formValues.body_font_weight,
            fontSize: formValues.body_font_size,
            textAlign: formValues.body_alignment,
            fontStyle: formValues.body_italic ? 'italic' : 'normal',
            textDecoration:
              `${formValues.body_underlined ? 'underline ' : ''}${formValues.body_strikethrough ? 'line-through' : ''}`.trim(),
          }}
          icon={
            <ThemeIcon
              color="teal"
              size={formValues.body_font_size}
              radius="xl"
              mr="lg"
            ></ThemeIcon>
          }
          center
        >
          {item.data.map((listItem, index) => (
            <List.Item key={index} style={{ lineHeight: 1.5 }}>
              {listItem.data.map((textFragment, index) => (
                <TextFragment fragment={textFragment} key={index} formValues={formValues} />
              ))}
            </List.Item>
          ))}
        </List>
      );

    case 'table':
      return (
        <Table
          style={{
            color: formValues.body_font_color,
            fontFamily: formValues.body_font_family,
            fontWeight: formValues.body_font_weight,
            fontSize: formValues.body_font_size,
            textAlign: formValues.body_alignment,
            fontStyle: formValues.body_italic ? 'italic' : 'normal',
            textDecoration:
              `${formValues.body_underlined ? 'underline ' : ''}${formValues.body_strikethrough ? 'line-through' : ''}`.trim(),
          }}
        >
          <Table.Thead>
            <Table.Tr>
              {item.data[0].data
                .map((cell, cellIndex) => {
                  if (cell.colspan === 0 && cell.rowspan === 0) return;

                  return (
                    <Table.Th
                      key={cellIndex}
                      rowSpan={cell.rowspan}
                      colSpan={cell.colspan}
                      style={{
                        backgroundColor: cell.backgroundColor,
                      }}
                    >
                      {cell.data.map((d, index) => (
                        <Paragraph item={d} key={index} />
                      ))}
                    </Table.Th>
                  );
                })
                .filter((cell) => cell)}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {item.data.slice(1).map((row, rowIndex) => (
              <Table.Tr key={rowIndex}>
                {row.data.map((cell, cellIndex) => {
                  if (cell.colspan === 0 && cell.rowspan === 0) return;

                  return (
                    <Table.Td key={cellIndex} rowSpan={cell.rowspan} colSpan={cell.colspan}>
                      {cell.data.map((d, index) => (
                        <Paragraph item={d} key={index} />
                      ))}
                    </Table.Td>
                  );
                })}
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      );
    default:
      return null;
  }
}
