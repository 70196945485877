import {
  ActionIcon,
  Box,
  Button,
  CSSProperties,
  Group,
  Paper,
  RingProgress,
  Select,
  Stack,
  Text,
  TextInput,
  Tooltip,
  rem,
} from '@mantine/core';
import { useFocusTrap, useInterval, useViewportSize } from '@mantine/hooks';
import {
  IconCheck,
  IconMaximize,
  IconMinimize,
  IconMusic,
  IconPlayerPauseFilled,
  IconPlayerPlayFilled,
  IconRotateClockwise,
  IconWindowMaximize,
  IconWindowMinimize,
  IconX,
} from '@tabler/icons-react';
import { useEffect, useRef, useState } from 'react';
import { Rnd } from 'react-rnd';
import timer from './Timer.module.css';

export function Timer({ closeTimer }) {
  const focusTrapRef = useFocusTrap();
  const { width: viewportWidth } = useViewportSize();
  const [hideButtons, setHideButtons] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const [position, setPosition] = useState({ x: 50, y: 5 });
  const [initialMinutes, setInitialMinutes] = useState(1);
  const [initialSeconds, setInitialSeconds] = useState(0);
  const [initialTime, setInitialTime] = useState(initialMinutes * 60 + initialSeconds);
  const [remainingMinutes, setRemainingMinutes] = useState(1);
  const [remainingSeconds, setRemainingSeconds] = useState(0);
  const [totalTimeRemaining, setTotalTimeRemaining] = useState(
    remainingMinutes * 60 + remainingSeconds
  );
  const [selectedSong, setSelectedSong] = useState(null);
  const audioRef = useRef(null);
  const data = {
    '/music/nothings-quite-the-same.mp3': {
      label: "Nothing's Quite the Same",
      artist: 'Kit Wheston',
      value: '/music/nothings-quite-the-same.mp3',
      style: 'Calm Lofi',
    },
    '/music/5-stages-of-you.mp3': {
      label: '5 Stages of You',
      artist: 'Kit Wheston',
      value: '/music/5-stages-of-you.mp3',
      style: 'Chill Lofi',
    },
    '/music/tea.mp3': {
      label: 'Tea',
      artist: 'Coldise',
      value: '/music/tea.mp3',
      style: 'Lofi Chill',
    },
    '/music/the-intro-we-have-been-waiting-for.mp3': {
      label: 'The Intro We Have Been Waiting For',
      artist: 'Kevin Shrout',
      value: '/music/the-intro-we-have-been-waiting-for.mp3',
      style: 'Happy Energy',
    },
    '/music/naya.mp3': {
      label: 'Naya',
      artist: 'HaTom',
      value: '/music/naya.mp3',
      style: 'Tropical Chilled',
    },
  };

  const reset = (newInitialTime) => {
    setInitialTime(newInitialTime);
    setInitialMinutes(Math.floor(newInitialTime / 60));
    setInitialSeconds(newInitialTime % 60);
    setRemainingMinutes(Math.floor(newInitialTime / 60));
    setRemainingSeconds(newInitialTime % 60);
    setTotalTimeRemaining(newInitialTime);
  };

  const interval = useInterval(() => {
    setTotalTimeRemaining((prev) => {
      const newTime = prev - 1;
      setRemainingMinutes(Math.floor(newTime / 60));
      setRemainingSeconds(newTime % 60);
      return newTime;
    });
  }, 1000);

  const handleTimeChange = (minutes, seconds) => {
    const newInitialTime = minutes * 60 + seconds;
    reset(newInitialTime);
  };

  useEffect(() => {
    if (totalTimeRemaining <= 0) {
      interval.stop();
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    }
  }, [totalTimeRemaining, interval]);

  const startTimer = () => {
    if (totalTimeRemaining > 0) {
      interval.start();
      if (audioRef.current) {
        audioRef.current.play();
      }
    }
  };

  const stopTimer = () => {
    interval.stop();
    if (audioRef.current) {
      audioRef.current.pause();
    }
  };

  const resetTimer = () => {
    interval.stop();
    reset(initialMinutes * 60 + initialSeconds);
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  };

  const inputStyles: CSSProperties = {
    fontSize: fullscreen ? `${Math.max(Math.floor(viewportWidth / 11), 50)}px` : '50px',
    height: fullscreen ? `${Math.max(Math.floor(viewportWidth / 8), 80)}px` : '80px',
    width: fullscreen ? `${Math.max(Math.floor(viewportWidth / 8), 80)}px` : '80px',
    textAlign: 'center',
  };

  return (
    <Rnd
      disableDragging={fullscreen}
      enableResizing={false}
      style={{ zIndex: 102 }}
      position={position}
      onDragStop={(e, d) => {
        setPosition({ x: d.x, y: d.y });
      }}
      cancel=".cancel"
    >
      <Paper
        ref={focusTrapRef}
        p="md"
        shadow="md"
        withBorder
        pos="relative"
        w={fullscreen ? '100vw' : 'auto'}
        h={fullscreen ? 'calc(100dvh - var(--app-shell-header-offset, 0rem))' : 'auto'}
      >
        <Group pos="absolute" gap="0" style={{ top: 5, right: 5 }}>
          {fullscreen ? (
            <Tooltip label="Exit Fullscreen">
              <ActionIcon
                onClick={() => {
                  setFullscreen(false);
                  setPosition({ x: 50, y: 5 });
                }}
                radius="sm"
                size="sm"
                variant="subtle"
                aria-label="Minimize"
                c="bright"
                className="cancel"
              >
                <IconMinimize style={{ width: '80%', height: '80%' }} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          ) : (
            <Tooltip label="Fullscreen">
              <ActionIcon
                onClick={() => {
                  setFullscreen(true);
                  setPosition({ x: 0, y: 0 });
                }}
                radius="sm"
                size="sm"
                variant="subtle"
                aria-label="Maximize"
                c="bright"
                className="cancel"
              >
                <IconMaximize style={{ width: '80%', height: '80%' }} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          )}
          {hideButtons ? (
            <Tooltip label="Show buttons">
              <ActionIcon
                onClick={() => setHideButtons(false)}
                radius="sm"
                size="sm"
                variant="subtle"
                aria-label="Show buttons"
                c="bright"
                className="cancel"
              >
                <IconWindowMaximize style={{ width: '80%', height: '80%' }} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          ) : (
            <Tooltip label="Hide buttons">
              <ActionIcon
                onClick={() => setHideButtons(true)}
                radius="sm"
                size="sm"
                variant="subtle"
                aria-label="Hide buttons"
                c="bright"
                className="cancel"
              >
                <IconWindowMinimize style={{ width: '80%', height: '80%' }} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          )}
          <Tooltip label="Close timer">
            <ActionIcon
              onClick={() => closeTimer(false)}
              radius="sm"
              size="sm"
              variant="subtle"
              aria-label="Close"
              c="bright"
              className="cancel"
            >
              <IconX style={{ width: '80%', height: '80%' }} stroke={1.5} />
            </ActionIcon>
          </Tooltip>
        </Group>

        {hideButtons ? (
          <Group justify="center" gap="5px">
            <TextInput
              disabled={interval.active}
              variant="unstyled"
              value={String(remainingMinutes).padStart(2, '0')}
              onChange={(event) => {
                const value = event.target.value;
                const minutes = parseInt(value, 10);
                if (!isNaN(minutes) && minutes >= 0) {
                  handleTimeChange(minutes, remainingSeconds);
                }
              }}
              classNames={timer}
              styles={{
                input: inputStyles,
              }}
              className="cancel"
            />
            <Text c="blue" fw={700} fz={fullscreen ? Math.floor(viewportWidth / 11) : 50}>
              :
            </Text>
            <TextInput
              disabled={interval.active}
              variant="unstyled"
              value={String(remainingSeconds).padStart(2, '0')}
              onChange={(event) => {
                const value = event.target.value;
                const seconds = parseInt(value, 10);
                if (!isNaN(seconds) && seconds >= 0 && seconds < 60) {
                  handleTimeChange(remainingMinutes, seconds);
                }
              }}
              classNames={timer}
              styles={{
                input: inputStyles,
              }}
              className="cancel"
            />
          </Group>
        ) : (
          <Stack align="center">
            <RingProgress
              thickness={7}
              size={fullscreen ? Math.max(Math.floor(viewportWidth / 2.7), 250) : 250}
              sections={[{ value: (totalTimeRemaining / initialTime) * 100, color: 'blue' }]}
              roundCaps
              label={
                <Group justify="center" gap="5px">
                  <TextInput
                    disabled={interval.active}
                    variant="unstyled"
                    value={String(remainingMinutes).padStart(2, '0')}
                    onChange={(event) => {
                      const value = event.target.value;
                      const minutes = parseInt(value, 10);
                      if (!isNaN(minutes) && minutes >= 0) {
                        handleTimeChange(minutes, remainingSeconds);
                      }
                    }}
                    classNames={timer}
                    styles={{
                      input: inputStyles,
                    }}
                    className="cancel"
                  />
                  <Text c="blue" fw={700} fz={fullscreen ? Math.floor(viewportWidth / 11) : 50}>
                    :
                  </Text>
                  <TextInput
                    disabled={interval.active}
                    variant="unstyled"
                    value={String(remainingSeconds).padStart(2, '0')}
                    onChange={(event) => {
                      const value = event.target.value;
                      const seconds = parseInt(value, 10);
                      if (!isNaN(seconds) && seconds >= 0 && seconds < 60) {
                        handleTimeChange(remainingMinutes, seconds);
                      }
                    }}
                    classNames={timer}
                    styles={{
                      input: inputStyles,
                    }}
                    className="cancel"
                  />
                </Group>
              }
            />
            <Box w={250}>
              <Select
                disabled={interval.active}
                w="100%"
                mb="md"
                value={selectedSong}
                leftSection={<IconMusic style={{ width: rem(13), height: rem(13) }} />}
                size="xs"
                radius="xl"
                placeholder="No music selected"
                className="cancel"
                data={Object.values(data)}
                onChange={setSelectedSong}
                allowDeselect={false}
                renderOption={({ option, checked }) => {
                  const iconProps = {
                    stroke: 1.5,
                    color: 'currentColor',
                    opacity: 0.6,
                    size: 18,
                  };
                  return (
                    <Group justify="space-between" w="100%" wrap="nowrap">
                      <Stack gap="0">
                        <Text span size="sm">
                          {option.label}
                        </Text>
                        <Text size="xs" c="gray">
                          {data[option.value].artist} | {data[option.value].style}
                        </Text>
                      </Stack>
                      {checked && (
                        <IconCheck style={{ marginInlineStart: 'auto' }} {...iconProps} />
                      )}
                    </Group>
                  );
                }}
              />
              <Group justify="space-between">
                <Tooltip label="Reset timer">
                  <ActionIcon
                    onClick={resetTimer}
                    radius="xl"
                    size="xl"
                    variant="subtle"
                    aria-label="Reset timer"
                    className="cancel"
                  >
                    <IconRotateClockwise style={{ width: '40%', height: '40%' }} stroke={1.5} />
                  </ActionIcon>
                </Tooltip>

                {interval.active ? (
                  <Tooltip label="Stop timer">
                    <ActionIcon
                      onClick={stopTimer}
                      radius="xl"
                      size="xl"
                      variant="filled"
                      color="green"
                      aria-label="Stop timer"
                      className="cancel"
                    >
                      <IconPlayerPauseFilled style={{ width: '40%', height: '40%' }} stroke={1.5} />
                    </ActionIcon>
                  </Tooltip>
                ) : (
                  <Tooltip label="Start timer">
                    <ActionIcon
                      onClick={startTimer}
                      radius="xl"
                      size="xl"
                      variant="filled"
                      color="red"
                      aria-label="Start timer"
                      className="cancel"
                    >
                      <IconPlayerPlayFilled style={{ width: '40%', height: '40%' }} stroke={1.5} />
                    </ActionIcon>
                  </Tooltip>
                )}
                <Tooltip label="Add 1 minute">
                  <Button
                    variant="outline"
                    size="xs"
                    radius="xl"
                    onClick={() => {
                      setRemainingMinutes((value) => value + 1);
                      setInitialTime(totalTimeRemaining + 60);
                      setTotalTimeRemaining((value) => value + 60);
                    }}
                    className="cancel"
                  >
                    +1m
                  </Button>
                </Tooltip>
                <Tooltip label="Add 5 minutes">
                  <Button
                    variant="outline"
                    size="xs"
                    radius="xl"
                    onClick={() => {
                      setRemainingMinutes((value) => value + 5);
                      setInitialTime(totalTimeRemaining + 60 * 5);
                      setTotalTimeRemaining((value) => value + 60 * 5);
                    }}
                    className="cancel"
                  >
                    +5m
                  </Button>
                </Tooltip>
              </Group>
            </Box>
          </Stack>
        )}
        {selectedSong && <audio ref={audioRef} src={selectedSong} loop />}
      </Paper>
    </Rnd>
  );
}
