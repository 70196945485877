import { Carousel } from '@mantine/carousel';
import { Container, Flex, Skeleton } from '@mantine/core';

export function Skel() {
  return (
    <Carousel.Slide>
      <Flex h="100%" w="100%" justify="center" align="center">
        <Container w="1400px" p="2rem">
          <Skeleton height={150} circle mb="xl" />
          <Skeleton height={8} radius="xl" />
          <Skeleton height={8} mt={6} radius="xl" />
          <Skeleton height={8} mt={6} width="70%" radius="xl" />
        </Container>
      </Flex>
    </Carousel.Slide>
  );
}
