import { Carousel } from '@mantine/carousel';
import { Box, Container, Flex, getGradient, useMantineTheme } from '@mantine/core';
import { useEffect, useState } from 'react';
import { Annotations } from '../Annotations/Annotations';
import { Element } from './Element';
import { SlideLogo } from './SlideLogo';

export function Slides({
  presentation,
  formValues,
  displayOnly,
  showAnnotations,
  setUpdateFunction,
  updateFunctionRef,
  annotationSettingsRef,
  updateAnnotationSettings,
}) {
  const mantineTheme = useMantineTheme();
  const [slides, setSlides] = useState(presentation.content);

  useEffect(() => {
    setSlides(presentation.content);
  }, [presentation.content]);

  return slides.map((slide) => (
    <Carousel.Slide
      key={slide.order}
      style={{
        backgroundColor: formValues.background_color,
        backgroundImage:
          formValues.background_color_type === 'solid'
            ? 'none'
            : getGradient(
                {
                  deg: formValues.background_color_gradient_angle,
                  from: formValues.background_color_gradient_from,
                  to: formValues.background_color_gradient_to,
                },
                mantineTheme
              ),
      }}
    >
      <Box
        w="100%"
        h="calc(100dvh - var(--app-shell-header-offset, 0rem))"
        pos="relative"
        style={{ overflow: 'auto' }}
      >
        {/* min-height: 100% so that container is vertically centred */}
        {/* height: auto so that absolute position child will take up 100% height */}
        <Flex justify="center" align="center" h="auto" mih="100%" pos="relative">
          {!displayOnly && (
            <Annotations
              key={slide.order}
              annotationSettingsRef={annotationSettingsRef}
              updateAnnotationSettings={updateAnnotationSettings}
              setUpdateFunction={setUpdateFunction}
              updateFunctionRef={updateFunctionRef}
              slide={slide}
              showAnnotations={showAnnotations}
            />
          )}

          <SlideLogo presentation={presentation} formValues={formValues} />
          {/* Auto margins on flex item allow scrolling when flex item height is greater than parent height */}
          <Container h="auto" w="1400px" p="2rem" m="auto">
            {slide.elements.map((item, index) => (
              <Element item={item} key={index} formValues={formValues} />
            ))}
          </Container>
        </Flex>
      </Box>
    </Carousel.Slide>
  ));
}
