import {
  Badge,
  Box,
  Button,
  Card,
  Container,
  Group,
  List,
  SegmentedControl,
  SimpleGrid,
  Stack,
  Text,
  Title,
  rem,
} from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import { useState } from 'react';
import segmentedControl from './SegmentedControl.module.css';

export function PricingTables() {
  const [billingFrequency, setBillingFrequency] = useState('annual');
  const [currency, setCurrency] = useState<string>('usd');

  return (
    <Container size="lg" pos="relative" px="lg" py="xl">
      <Title order={2} fz={{ base: rem(40), sm: rem(50) }} ta="center" mb="xl" fw="800">
        Pricing
      </Title>
      <Box pos="relative" style={{ zIndex: 1 }}>
        <Title ta="center" fz={{ base: rem(30), sm: rem(40) }} mb="md">
          Generate unlimited presentations for{' '}
          <Text
            component="span"
            fw="800"
            variant="gradient"
            gradient={{ from: '#FE9920', to: '#FF2A77', deg: 90 }}
            inherit
          >
            free
          </Text>{' '}
        </Title>

        <Text size="lg" c="dimmed" ta="center" mb="xl" fz={{ base: rem(20), sm: rem(25) }}>
          Generate unlimited presentations with{' '}
          <Text
            component="span"
            fw="800"
            variant="gradient"
            gradient={{ from: '#FE9920', to: '#FF2A77', deg: 90 }}
            inherit
          >
            Slides Generator
          </Text>{' '}
          free of charge, including our AI features. Subject only to{' '}
          <a
            href="https://developers.google.com/apps-script/guides/services/quotas"
            target="_blank"
            rel="noopener noreferrer"
          >
            Google Apps Script quotas
          </a>
          .
        </Text>
      </Box>

      <Card shadow="md" p="lg" mb="60px" radius={0}>
        <List center icon={<IconCheck size={16} color="green" />}>
          <SimpleGrid cols={{ base: 1, sm: 2, lg: 3 }}>
            <List.Item>
              <Text fz={rem(17)}>
                Instantly convert a Google Doc to Google Slides or Web Slides presentation.
              </Text>
            </List.Item>
            <List.Item>
              <Text fz={rem(17)}>
                Convert titles, body text, lists, images, tables and video links to slides.
              </Text>
            </List.Item>
            <List.Item>
              <Text fz={rem(17)}>Keep the formatting and hyperlinks from your Google Doc.</Text>
            </List.Item>
            <List.Item>
              <Text fz={rem(17)}>Configure consistent styling across your presentation.</Text>
            </List.Item>
            <List.Item>
              <Text fz={rem(17)}>
                Generate slides from the entire Google Doc or a selected text range.
              </Text>
            </List.Item>
            <List.Item>
              <Text fz={rem(17)}>
                Presentations stored for 7 days.{' '}
                <Badge
                  radius="sm"
                  size="xs"
                  variant="gradient"
                  gradient={{ from: '#FE9920', to: '#FF2A77' }}
                >
                  {' '}
                  Web{' '}
                </Badge>
              </Text>
            </List.Item>
            <List.Item>
              <Text fz={rem(17)}>
                Easily apply formatting across your presentation.{' '}
                <Badge
                  radius="sm"
                  size="xs"
                  variant="gradient"
                  gradient={{ from: '#FE9920', to: '#FF2A77' }}
                >
                  {' '}
                  Web{' '}
                </Badge>
              </Text>
            </List.Item>
            <List.Item>
              <Text fz={rem(17)}>
                Easily publish and unpublish presentations.{' '}
                <Badge
                  radius="sm"
                  size="xs"
                  variant="gradient"
                  gradient={{ from: '#FE9920', to: '#FF2A77' }}
                >
                  {' '}
                  Web{' '}
                </Badge>
              </Text>
            </List.Item>
            <List.Item>
              <Text fz={rem(17)}>
                Access in-built timer widget with music.{' '}
                <Badge
                  radius="sm"
                  size="xs"
                  variant="gradient"
                  gradient={{ from: '#FE9920', to: '#FF2A77' }}
                >
                  {' '}
                  Web{' '}
                </Badge>
              </Text>
            </List.Item>
            <List.Item>
              <Text fz={rem(17)}>
                Slide content optimized for a range of devices.{' '}
                <Badge
                  radius="sm"
                  size="xs"
                  variant="gradient"
                  gradient={{ from: '#FE9920', to: '#FF2A77' }}
                >
                  {' '}
                  Web{' '}
                </Badge>
              </Text>
            </List.Item>
            <List.Item>
              <Text fz={rem(17)}>
                Display equations beautifully.{' '}
                <Badge
                  radius="sm"
                  size="xs"
                  variant="gradient"
                  gradient={{ from: '#FE9920', to: '#FF2A77' }}
                >
                  {' '}
                  Web{' '}
                </Badge>
              </Text>
            </List.Item>
            <List.Item>
              <Text fz={rem(17)}>
                Automatically embed websites in slides.{' '}
                <Badge
                  radius="sm"
                  size="xs"
                  variant="gradient"
                  gradient={{ from: '#FE9920', to: '#FF2A77' }}
                >
                  {' '}
                  Web{' '}
                </Badge>
              </Text>
            </List.Item>
          </SimpleGrid>
        </List>
      </Card>

      <Box pos="relative" style={{ zIndex: 1 }}>
        <Title ta="center" fz={{ base: rem(30), sm: rem(40) }} mb="md">
          Make{' '}
          <Text
            component="span"
            inherit
            fw="800"
            variant="gradient"
            gradient={{ from: '#FE9920', to: '#FF2A77', deg: 90 }}
          >
            Slides Generator
          </Text>{' '}
          your own by going pro.
        </Title>
      </Box>

      <Stack align="center" justify="center" gap="md" mt="md" mb="lg">
        <SegmentedControl
          value={billingFrequency}
          radius="xl"
          size="md"
          data={[
            { label: 'Annual billing', value: 'annual' },
            { label: 'Monthly billing', value: 'monthly' },
          ]}
          classNames={segmentedControl}
          onChange={setBillingFrequency}
          mb="md"
        />

        <SegmentedControl
          value={currency}
          radius="xl"
          size="md"
          data={[
            { label: 'USD', value: 'usd' },
            { label: 'AUD', value: 'aud' },
          ]}
          classNames={segmentedControl}
          onChange={setCurrency}
          mb="md"
        />
      </Stack>

      <SimpleGrid cols={{ base: 1, sm: 2, lg: 3 }} spacing="md">
        <Card shadow="sm" radius={0} p="lg">
          <Box>
            <Title mb="sm" fz={rem(20)}>
              Slides Generator Pro
            </Title>
            <Title mb="sm" c="orange" fz={rem(25)}>
              Individual
            </Title>
            <Group align="baseline" gap="0">
              <Text size="xl" c="gray">
                $
              </Text>
              {currency === 'usd' ? (
                <Title fw="800" size={rem(40)}>
                  {billingFrequency === 'annual' ? '120' : '15'}
                </Title>
              ) : (
                <Title fw="800" size={rem(40)}>
                  {billingFrequency === 'annual' ? '180' : '22.5'}
                </Title>
              )}
              <Text c="gray">/{billingFrequency === 'annual' ? 'year' : 'month'}</Text>
            </Group>
            <Text c="yellow" fw="600" mb="md">
              Get 4 months free when you subscribe yearly.
            </Text>

            <Text mb="md" fw="800" fz={rem(17)}>
              What's included:
            </Text>
            <List spacing="md" mb="xl" center icon={<IconCheck color="green" size={16} />}>
              <List.Item>
                <Text fz={rem(17)}>
                  Remove Slides Generator branding from presentations and replace with your own.
                </Text>
              </List.Item>
              <List.Item>
                <Text fz={rem(17)}>
                  Generate slides into your own custom theme (when converting to Google Slides).
                </Text>
              </List.Item>
              <List.Item>
                <Text fz={rem(17)}>Save current web styling into a theme for later use.</Text>
              </List.Item>
              <List.Item>
                <Text fz={rem(17)}>Protect presentations with a passcode.</Text>
              </List.Item>
              <List.Item>
                <Text fz={rem(17)}>Presentations stored indefinitely.</Text>
              </List.Item>
            </List>
          </Box>

          <Stack justify="flex-end" h="100%">
            <Button
              size="md"
              bg="#FF2A77"
              component="a"
              href={
                currency === 'usd'
                  ? billingFrequency === 'annual'
                    ? 'https://buy.stripe.com/aEUeVzaEX80R1fG4gK'
                    : 'https://buy.stripe.com/dR6aFj28r1CtcYo00v'
                  : billingFrequency === 'annual'
                    ? 'https://buy.stripe.com/28ocNrfZhch7bUk4gM'
                    : 'https://buy.stripe.com/14k8xbbJ12GxbUk28F'
              }
            >
              Upgrade
            </Button>
          </Stack>
        </Card>

        <Card shadow="sm" radius={0} p="lg">
          <Box>
            <Title mb="sm" fz={rem(20)}>
              Slides Generator Pro
            </Title>
            <Title mb="sm" c="orange" fz={rem(25)}>
              Team
            </Title>
            <Group align="baseline" gap="0">
              <Text size="xl" c="gray">
                $
              </Text>
              {currency === 'usd' ? (
                <Title fw="800" size={rem(40)}>
                  {billingFrequency === 'annual' ? '240' : '30'}
                </Title>
              ) : (
                <Title fw="800" size={rem(40)}>
                  {billingFrequency === 'annual' ? '360' : '45'}
                </Title>
              )}
              <Text c="gray">/{billingFrequency === 'annual' ? 'year' : 'month'}</Text>
            </Group>
            <Text c="yellow" fw="600" mb="md">
              Get 4 months free when you subscribe yearly.
            </Text>
            <Text mb="md" fw="800" fz={rem(17)}>
              What's included:
            </Text>
            <List spacing="md" mb="xl" center icon={<IconCheck color="green" size={16} />}>
              <List.Item>
                <Text fz={rem(17)}>
                  All features of <b>Individual</b> for up to 10 team members.
                </Text>
              </List.Item>
              <List.Item>
                <Text fz={rem(17)}>Dedicated onboarding.</Text>
              </List.Item>
              <List.Item>
                <Text fz={rem(17)}>Priority support.</Text>
              </List.Item>
            </List>
          </Box>

          <Stack justify="flex-end" h="100%">
            <Button
              size="md"
              bg="#FF2A77"
              component="a"
              href={
                currency === 'usd'
                  ? billingFrequency === 'annual'
                    ? 'https://buy.stripe.com/aEUcNr14nch72jK9B0'
                    : 'https://buy.stripe.com/4gwfZD3cv94V4rS8wX'
                  : billingFrequency === 'annual'
                    ? 'https://buy.stripe.com/00geVz00jch75vWcNe'
                    : 'https://buy.stripe.com/00gaFjaEXch7e2sbJb'
              }
            >
              Upgrade
            </Button>
          </Stack>
        </Card>

        <Card shadow="sm" radius={0} p="lg">
          <Box>
            <Title mb="sm" fz={rem(20)}>
              Slides Generator Pro
            </Title>
            <Title c="orange" fz={rem(25)} mb="sm">
              Enterprise
            </Title>

            <Text mb="md" fw="800" fz={rem(17)}>
              What's included:
            </Text>
            <List spacing="md" mb="xl" center icon={<IconCheck color="green" size={16} />}>
              <List.Item>
                <Text fz={rem(17)}>
                  All features of <b>Team</b> plus custom enhancements.
                </Text>
              </List.Item>
            </List>
          </Box>

          <Stack justify="flex-end" h="100%">
            <Button
              size="md"
              bg="#FF2A77"
              component="a"
              href="#tally-open=mJ6BOY&tally-emoji-text=👋&tally-emoji-animation=wave"
              target="_blank"
            >
              Get in Touch
            </Button>
          </Stack>
        </Card>
      </SimpleGrid>
    </Container>
  );
}
