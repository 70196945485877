import { processError } from '@/helpers';
import { addUserToWorkspace, getWorkspace, removeUserFromWorkspace } from '@/requests';
import { Button, Skeleton, Table, TextInput, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { IconPlus } from '@tabler/icons-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export function Collaborators({ user }) {
  const { data: workspace, isLoading: workspaceLoading } = useQuery({
    queryKey: ['workspace', user.id],
    queryFn: getWorkspace,
  });
  const [opened, { open }] = useDisclosure(false);
  const form = useForm({
    mode: 'uncontrolled',
    initialValues: { email: '' },
    validate: {
      email: (value) => {
        if (!value.trim()) {
          return 'Please enter email address';
        } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
          return 'Please enter a valid email address';
        }
        return null;
      },
    },
  });
  const queryClient = useQueryClient();
  const addUserMutation = useMutation({
    mutationFn: addUserToWorkspace,
    onSuccess: (data) => {
      queryClient.setQueryData(['workspace', user.id], (_oldData: []) => data);
      form.reset();
      notifications.show({
        message: 'Successfully added collaborator.',
      });
    },
    onError: processError,
  });
  const removeUserMutation = useMutation({
    mutationFn: removeUserFromWorkspace,
    onSuccess: (data) => {
      queryClient.setQueryData(['workspace', user.id], (_oldData: []) => data);
      notifications.show({
        message: 'Successfully removed collaborator.',
      });
    },
    onError: processError,
  });

  if (!user?.active_subscription?.domain_id || !user.admin || workspaceLoading)
    return (
      <>
        <Skeleton height={50} circle mb="xl" />
        <Skeleton height={8} radius="xl" />
        <Skeleton height={8} mt={6} radius="xl" />
        <Skeleton height={8} mt={6} width="70%" radius="xl" />
      </>
    );

  return (
    <>
      <Title order={2} my="md">
        Collaborators
      </Title>
      <Table w="500px">
        <Table.Thead>
          <Table.Tr>
            <Table.Th w="500px">Email</Table.Th>
            <Table.Th w="200px">Actions</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {workspace.collaborators
            .sort((a, b) => (a.email === user.email ? -1 : b.email === user.email ? 1 : 0))
            .map((collaborator) => (
              <Table.Tr key={collaborator.id}>
                <Table.Td>{collaborator.email}</Table.Td>
                <Table.Td>
                  {collaborator.email !== user.email && (
                    <Button
                      size="xs"
                      bg="red"
                      onClick={() => removeUserMutation.mutate(collaborator.email)}
                    >
                      Remove User
                    </Button>
                  )}
                </Table.Td>
              </Table.Tr>
            ))}
          <Table.Tr>
            <Table.Td>
              {opened ? (
                <form
                  onSubmit={form.onSubmit(() => addUserMutation.mutate(form.getValues().email))}
                >
                  <TextInput
                    mb="md"
                    style={{ flexGrow: 1 }}
                    placeholder="Enter email address"
                    {...form.getInputProps('email')}
                  />
                  <Button variant="filled" aria-label="Settings" type="submit">
                    Add Collaborator
                  </Button>
                </form>
              ) : (
                <Button variant="outline" onClick={open} rightSection={<IconPlus size={14} />}>
                  Add collaborators to your workspace
                </Button>
              )}
            </Table.Td>
            <Table.Td />
          </Table.Tr>
        </Table.Tbody>
      </Table>
    </>
  );
}
