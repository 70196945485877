import { Box, Text, Group, rem } from '@mantine/core';
import { IconListSearch } from '@tabler/icons-react';
import classes from './TableOfContents.module.css';
import { useState } from 'react';

export function TableOfContents({ links }) {
  if (links.length === 0) return;

  const [active, setActive] = useState(links[0].link);

  const items = links.map((item) => (
    <Box<'a'>
      component="a"
      href={item.link}
      key={item.label}
      className={`${classes.link} ${active === item.link ? classes.linkActive : ''}`}
      pl={`calc(${item.order} * var(--mantine-spacing-md))`}
      fz={rem(18)}
      onClick={() => setActive(item.link)}
    >
      {item.label}
    </Box>
  ));

  return (
    <Box mb="lg">
      <Group mb="md">
        <IconListSearch style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
        <Text>Table of contents</Text>
      </Group>
      {items}
    </Box>
  );
}
