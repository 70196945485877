import { rem, Title } from '@mantine/core';
import { Footer } from '../HomePage/Footer';
import { NavBar } from '../HomePage/NavBar';
import { BlogList } from './BlogList';

export function BlogIndex() {
  return (
    <>
      <NavBar />
      <Title
        my={{ base: rem(100), md: rem(150) }}
        ta="center"
        order={1}
        fz={{ sm: rem(40), md: rem(65) }}
        fw={900}
      >
        Blog
      </Title>
      <BlogList />
      <Footer />
    </>
  );
}
