import { useEffect, useState } from 'react';
import {
  Title,
  Paper,
  Text,
  UnstyledButton,
  Group,
  ActionIcon,
  getGradient,
  useMantineTheme,
} from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { IconTrashFilled, IconPencil } from '@tabler/icons-react';
import { useForm } from '@mantine/form';
import { useScaleToContainer } from '@/hooks/useScaleToContainer';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import { deleteTheme } from '@/requests';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ThemeCardTextInput } from './ThemeCardTextInput';
import { processError } from '@/helpers';

function TextComponent({ theme, level, Component, text }) {
  const ref = useScaleToContainer(
    { fontSize: theme.styles[`${level}_font_size`] },
    '.mantine-Paper-root'
  );

  return (
    <Component
      ref={ref}
      order={1}
      style={{
        color: theme.styles[`${level}_font_color`],
        fontFamily: theme.styles[`${level}_font_family`],
        fontWeight: theme.styles[`${level}_font_weight`],
        fontSize: theme.styles[`${level}_font_size`],
        textAlign: theme.styles[`${level}_alignment`],
        fontStyle: theme.styles[`${level}_italic`] ? 'italic' : 'normal',
        textDecoration:
          `${theme.styles[`${level}_underlined`] ? 'underline ' : ''}${theme.styles[`${level}_strikethrough`] ? 'line-through' : ''}`.trim(),
      }}
    >
      {text}
    </Component>
  );
}

export function ThemeCard({ setShowThemeCardTitleInput, themeType, theme, form }) {
  const mantineTheme = useMantineTheme();
  const { hovered, ref } = useHover();
  const queryClient = useQueryClient();
  const themeCardForm = useForm({ mode: 'uncontrolled' });
  const [showEdit, setShowEdit] = useState(false);
  const deleteThemeMutation = useMutation({
    mutationFn: () => deleteTheme(theme.id),
    onSuccess: () => {
      queryClient.setQueryData(['themes'], (oldData: []) => {
        if (!oldData) return oldData;

        return oldData.filter((t) => t.id !== theme.id);
      });
      notifications.show({
        message: `Successfully deleted ${theme.name}!`,
      });
    },
    onError: processError,
  });
  const openModal = () =>
    modals.openConfirmModal({
      title: 'Are you sure?',
      children: (
        <Text size="sm">
          Any presentations using this theme will no longer have it applied. This action is
          irreversible.
        </Text>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => deleteThemeMutation.mutate(),
    });

  useEffect(() => {
    if (theme.id) {
      themeCardForm.initialize({ [`name-${theme.id}`]: theme.name });
    }
  }, [theme.id]);

  return (
    <Paper
      ref={ref}
      w="280px"
      bg={hovered ? 'lightblue' : 'transparent'}
      shadow="none"
      radius="md"
      p="sm"
    >
      <UnstyledButton
        w="100%"
        onClick={() =>
          form.setValues({
            ...theme.styles,
            logo_url: theme.logo_url,
            attachment_id: theme.attachment_id,
          })
        }
      >
        <Paper
          shadow="none"
          radius="md"
          p="xl"
          withBorder
          pos="relative"
          style={{
            backgroundColor: theme.styles?.background_color,
            backgroundImage:
              theme.styles?.background_color_type === 'solid'
                ? 'none'
                : getGradient(
                    {
                      deg: theme.styles?.background_color_gradient_angle,
                      from: theme.styles?.background_color_gradient_from,
                      to: theme.styles?.background_color_gradient_to,
                    },
                    mantineTheme
                  ),
          }}
        >
          <TextComponent theme={theme} level="h1" Component={Title} text="Heading 1" />
          <TextComponent theme={theme} level="h2" Component={Title} text="Heading 2" />
          <TextComponent theme={theme} level="h3" Component={Title} text="Heading 3" />
          <TextComponent theme={theme} level="body" Component={Text} text="Body" />
        </Paper>
      </UnstyledButton>
      <Group justify="space-between">
        {themeCardForm && showEdit ? (
          <ThemeCardTextInput
            setShowEdit={setShowEdit}
            setShowThemeCardTitleInput={setShowThemeCardTitleInput}
            theme={theme}
          />
        ) : (
          <Group gap="0">
            <Title size="h5" c="#242424">
              {theme.name}
            </Title>
            {themeType === 'custom' && (
              <ActionIcon
                color="#242424"
                variant="subtle"
                aria-label="Settings"
                onClick={() => {
                  setShowEdit(true);
                  setShowThemeCardTitleInput(true);
                }}
              >
                <IconPencil style={{ width: '70%', height: '70%' }} stroke={1.5} />
              </ActionIcon>
            )}
          </Group>
        )}

        {themeType === 'custom' && (
          <ActionIcon color="#242424" variant="subtle" aria-label="Settings" onClick={openModal}>
            <IconTrashFilled style={{ width: '70%', height: '70%' }} stroke={1.5} />
          </ActionIcon>
        )}
      </Group>
    </Paper>
  );
}
