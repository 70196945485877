import { Button, Container, Text, Title, rem } from '@mantine/core';

export function Hero() {
  return (
    <Container size="lg" ta="center">
      <Title order={1} fz={{ sm: rem(40), md: rem(65) }} fw={900} mb="md">
        Presentations made{' '}
        <Text
          component="span"
          variant="gradient"
          gradient={{ from: '#FE9920', to: '#FF2A77' }}
          inherit
        >
          easy
        </Text>
      </Title>

      <Text c="dimmed" mb="xl" fz={{ base: rem(20), sm: rem(25) }}>
        Instantly convert Google Docs to Google Slides presentations. Turn your document into a
        presentation management system. Or embrace a whole new way of presenting your documents with
        Web Slides.
      </Text>

      <Button
        component="a"
        href="https://workspace.google.com/marketplace/app/slides_generator_convert_docs%E1%B5%80%E1%B4%B9_to_slide/398255519813"
        size="xl"
        variant="gradient"
        gradient={{ from: '#FE9920', to: '#FF2A77' }}
      >
        Get Started
      </Button>
    </Container>
  );
}
