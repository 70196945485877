import { processError } from '@/helpers';
import { updatePresentation } from '@/requests';
import {
  ActionIcon,
  Badge,
  Box,
  Button,
  CopyButton,
  Drawer,
  Group,
  Menu,
  Paper,
  PasswordInput,
  Popover,
  Switch,
  Text,
  TextInput,
  Tooltip,
  rem,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import {
  IconCheck,
  IconCopy,
  IconHome,
  IconLink,
  IconMenu,
  IconMoon,
  IconPalette,
  IconPencil,
  IconPlayerPlay,
  IconShare,
  IconSun,
} from '@tabler/icons-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import QRCode from 'react-qr-code';
import classes from './NavBar.module.css';

export function NavBar({
  toggleFullscreen,
  presentation,
  handleEditStyles,
  updateFunction,
  user,
}) {
  const [opened, setOpened] = useState(false);
  const { colorScheme, setColorScheme } = useMantineColorScheme();
  const theme = useMantineTheme();
  const queryClient = useQueryClient();
  const [drawerOpened, { open: openDrawer, close: closeDrawer }] = useDisclosure(false);
  const form = useForm({
    mode: 'uncontrolled',
    initialValues: { title: presentation.title, password: presentation.password },
  });
  const updatePresentationMutation = useMutation({
    mutationFn: updatePresentation,
    onSuccess: (data) => {
      queryClient.setQueryData(['presentation', presentation.presentation_id], (oldData) => ({
        ...oldData,
        ...data,
      }));
      setOpened(false);
      form.resetDirty({ title: data.title });
      notifications.show({
        message: 'Successfully updated presentation.',
      });
    },
    onError: processError,
  });

  return (
    <Box>
      <header className={classes.header}>
        <Group justify="space-between" h="100%" gap="0">
          <Group>
            <ActionIcon
              component="a"
              href="/presentations"
              size={42}
              variant="default"
              aria-label="ActionIcon with size as a number"
              style={{ border: 0 }}
            >
              <IconHome style={{ width: rem(24), height: rem(24) }} />
            </ActionIcon>
            <Group gap="0">
              <Text truncate="end" w={{ base: 120, sm: 'auto' }}>
                {presentation.title}
              </Text>
              {user.user_id === presentation.user_id && (
                <Popover
                  width={300}
                  trapFocus
                  position="bottom-start"
                  shadow="md"
                  opened={opened}
                  onChange={setOpened}
                >
                  <Popover.Target>
                    <ActionIcon
                      variant="subtle"
                      aria-label="Settings"
                      onClick={() => setOpened((o) => !o)}
                    >
                      <IconPencil style={{ width: '70%', height: '70%' }} stroke={1.5} />
                    </ActionIcon>
                  </Popover.Target>
                  <Popover.Dropdown>
                    <Group align="center">
                      <TextInput
                        style={{ flexGrow: 1 }}
                        key={form.key('title')}
                        {...form.getInputProps('title')}
                        placeholder="Enter presentation title"
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            if (form.isDirty('title')) {
                              updatePresentationMutation.mutate({
                                ...form.getValues(),
                                id: presentation.presentation_id,
                              });
                            }
                          }
                        }}
                      />
                      <ActionIcon
                        size={36}
                        variant="filled"
                        aria-label="Settings"
                        onClick={() => {
                          if (form.isDirty('title')) {
                            updatePresentationMutation.mutate({
                              ...form.getValues(),
                              id: presentation.presentation_id,
                            });
                          }
                        }}
                      >
                        <IconCheck style={{ width: '70%', height: '70%' }} stroke={1.5} />
                      </ActionIcon>
                    </Group>
                  </Popover.Dropdown>
                </Popover>
              )}
            </Group>
            {user.user_id === presentation.user_id && (
              <Badge color={updateFunction ? 'orange' : 'green'}>
                {updateFunction ? 'Saving' : 'Saved'}
              </Badge>
            )}
          </Group>

          <Group hiddenFrom="sm">
            <Menu shadow="md" width={200}>
              <Menu.Target>
                <ActionIcon size="lg" variant="subtle" aria-label="Menu">
                  <IconMenu style={{ width: '70%', height: '70%' }} stroke={1.5} />
                </ActionIcon>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Item>
                  <Group gap="0" justify="space-between">
                    <Switch
                      checked={colorScheme === 'light'}
                      onChange={(event) =>
                        setColorScheme(event.currentTarget.checked ? 'light' : 'dark')
                      }
                      size="md"
                      mr="md"
                      onLabel={
                        <IconSun
                          style={{ width: rem(16), height: rem(16) }}
                          stroke={2.5}
                          color={theme.colors.yellow[4]}
                        />
                      }
                      offLabel={
                        <IconMoon
                          style={{ width: rem(16), height: rem(16) }}
                          stroke={2.5}
                          color={theme.colors.blue[6]}
                        />
                      }
                    />
                    <Button
                      size="xs"
                      onClick={toggleFullscreen}
                      variant="gradient"
                      gradient={{ from: '#FE9920', to: '#FF2A77' }}
                      leftSection={<IconPlayerPlay size={14} />}
                    >
                      Present
                    </Button>
                  </Group>
                </Menu.Item>
                <Menu.Item
                  leftSection={<IconPalette style={{ width: rem(14), height: rem(14) }} />}
                  onClick={handleEditStyles}
                >
                  Edit Styles
                </Menu.Item>
                <Menu.Item
                  leftSection={<IconShare style={{ width: rem(14), height: rem(14) }} />}
                  onClick={openDrawer}
                >
                  Share
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>

          <Group h="100%" gap={0} visibleFrom="sm">
            <Switch
              checked={colorScheme === 'light'}
              onChange={(event) => setColorScheme(event.currentTarget.checked ? 'light' : 'dark')}
              size="lg"
              mr="md"
              onLabel={
                <IconSun
                  style={{ width: rem(16), height: rem(16) }}
                  stroke={2.5}
                  color={theme.colors.yellow[4]}
                />
              }
              offLabel={
                <IconMoon
                  style={{ width: rem(16), height: rem(16) }}
                  stroke={2.5}
                  color={theme.colors.blue[6]}
                />
              }
            />
            <Group>
              {user.user_id === presentation.user_id && (
                <>
                  <Button
                    variant="default"
                    leftSection={<IconPalette size={14} />}
                    onClick={handleEditStyles}
                  >
                    Edit Styles
                  </Button>
                  <Popover width={600} trapFocus position="bottom-start" shadow="md">
                    <Popover.Target>
                      <Button variant="default" leftSection={<IconShare size={14} />}>
                        Share
                      </Button>
                    </Popover.Target>
                    <Popover.Dropdown>
                      <Paper p="md" mb="md">
                        <Group justify="center" mb="md">
                          <div style={{ background: 'white', padding: '16px', width: '300px' }}>
                            <QRCode
                              size={256}
                              style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                              value={window.location.href}
                              viewBox={`0 0 256 256`}
                            />
                          </div>
                        </Group>
                        <TextInput
                          value={window.location.href}
                          mb="md"
                          size="lg"
                          leftSection={
                            <IconLink style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
                          }
                          rightSection={
                            <CopyButton value={window.location.href} timeout={2000}>
                              {({ copied, copy }) => (
                                <Tooltip
                                  label={copied ? 'Copied' : 'Copy'}
                                  withArrow
                                  position="right"
                                >
                                  <ActionIcon
                                    color={copied ? 'teal' : 'gray'}
                                    variant="subtle"
                                    onClick={copy}
                                  >
                                    {copied ? (
                                      <IconCheck style={{ width: rem(16) }} />
                                    ) : (
                                      <IconCopy style={{ width: rem(16) }} />
                                    )}
                                  </ActionIcon>
                                </Tooltip>
                              )}
                            </CopyButton>
                          }
                        />
                      </Paper>

                      <Paper p="md" mb="md">
                        <Group justify="space-between">
                          <Text fw="bold">Publish</Text>
                          <Switch
                            size="lg"
                            onLabel="ON"
                            offLabel="OFF"
                            checked={presentation.published}
                            onChange={(e) => {
                              updatePresentationMutation.mutate({
                                published: e.currentTarget.checked,
                                id: presentation.presentation_id,
                              });
                            }}
                          />
                        </Group>
                      </Paper>

                      <Paper p="md">
                        <Group justify="space-between">
                          <Group gap="0">
                            <Text fw="bold">Protect with passcode</Text>
                            <Badge
                              ml="xs"
                              radius="sm"
                              size="xs"
                              variant="gradient"
                              gradient={{ from: '#FE9920', to: '#FF2A77' }}
                            >
                              PRO
                            </Badge>
                          </Group>
                          <Switch
                            size="lg"
                            onLabel="ON"
                            offLabel="OFF"
                            checked={presentation.password_protected}
                            onChange={(e) => {
                              updatePresentationMutation.mutate({
                                password_protected: e.currentTarget.checked,
                                id: presentation.presentation_id,
                              });
                            }}
                          />
                        </Group>
                        {presentation.password_protected && (
                          <Group justify="space-between" mt="md">
                            <PasswordInput
                              flex="1"
                              size="md"
                              key={form.key('password')}
                              {...form.getInputProps('password')}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  updatePresentationMutation.mutate({
                                    password: form.getValues().password,
                                    id: presentation.presentation_id,
                                  });
                                }
                              }}
                            />
                            <Button
                              size="md"
                              variant="outline"
                              onClick={() =>
                                updatePresentationMutation.mutate({
                                  password: form.getValues().password,
                                  id: presentation.presentation_id,
                                })
                              }
                            >
                              Update
                            </Button>
                          </Group>
                        )}
                      </Paper>
                    </Popover.Dropdown>
                  </Popover>
                </>
              )}
              <Button
                onClick={toggleFullscreen}
                variant="gradient"
                gradient={{ from: '#FE9920', to: '#FF2A77' }}
                leftSection={<IconPlayerPlay size={14} />}
              >
                Present
              </Button>
            </Group>
          </Group>
        </Group>
      </header>
      <Drawer opened={drawerOpened} onClose={closeDrawer} title="Share settings">
        <Paper p="md" mb="md">
          <Group justify="center" mb="md">
            <div style={{ background: 'white', padding: '16px', width: '300px' }}>
              <QRCode
                size={256}
                style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                value={window.location.href}
                viewBox={`0 0 256 256`}
              />
            </div>
          </Group>
          <TextInput
            value={window.location.href}
            mb="md"
            size="lg"
            leftSection={<IconLink style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
            rightSection={
              <CopyButton value={window.location.href} timeout={2000}>
                {({ copied, copy }) => (
                  <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
                    <ActionIcon color={copied ? 'teal' : 'gray'} variant="subtle" onClick={copy}>
                      {copied ? (
                        <IconCheck style={{ width: rem(16) }} />
                      ) : (
                        <IconCopy style={{ width: rem(16) }} />
                      )}
                    </ActionIcon>
                  </Tooltip>
                )}
              </CopyButton>
            }
          />
        </Paper>

        <Paper p="md" mb="md">
          <Group justify="space-between">
            <Text fw="bold">Publish</Text>
            <Switch
              size="lg"
              onLabel="ON"
              offLabel="OFF"
              checked={presentation.published}
              onChange={(e) => {
                updatePresentationMutation.mutate({
                  published: e.currentTarget.checked,
                  id: presentation.presentation_id,
                });
              }}
            />
          </Group>
        </Paper>

        <Paper p="md">
          <Group justify="space-between">
            <Group gap="0">
              <Text fw="bold">Protect with passcode</Text>
              <Badge
                ml="xs"
                radius="sm"
                size="xs"
                variant="gradient"
                gradient={{ from: '#FE9920', to: '#FF2A77' }}
              >
                PRO
              </Badge>
            </Group>
            <Switch
              size="lg"
              onLabel="ON"
              offLabel="OFF"
              checked={presentation.password_protected}
              onChange={(e) => {
                updatePresentationMutation.mutate({
                  password_protected: e.currentTarget.checked,
                  id: presentation.presentation_id,
                });
              }}
            />
          </Group>
          {presentation.password_protected && (
            <Group justify="space-between" mt="md">
              <PasswordInput
                flex="1"
                size="md"
                key={form.key('password')}
                {...form.getInputProps('password')}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    updatePresentationMutation.mutate({
                      password: form.getValues().password,
                      id: presentation.presentation_id,
                    });
                  }
                }}
              />
              <Button
                size="md"
                variant="outline"
                onClick={() =>
                  updatePresentationMutation.mutate({
                    password: form.getValues().password,
                    id: presentation.presentation_id,
                  })
                }
              >
                Update
              </Button>
            </Group>
          )}
        </Paper>
      </Drawer>
    </Box>
  );
}
