import {
  Anchor,
  Box,
  Burger,
  Button,
  Center,
  Container,
  Drawer,
  Group,
  Image,
  Menu,
  NavLink,
  ScrollArea,
  Switch,
  rem,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconChevronDown, IconMoon, IconSun } from '@tabler/icons-react';

export function NavBar() {
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);
  const theme = useMantineTheme();
  const { colorScheme, setColorScheme } = useMantineColorScheme();

  return (
    <Box
      pos="fixed"
      style={{ zIndex: 1, top: 0, left: 0, right: 0 }}
      bg="var(--mantine-color-body)"
    >
      <Box component="header">
        <Container size="lg" h={{ base: 60, sm: 70 }} px="md">
          <Group justify="space-between" h="100%">
            <Anchor href="/">
              <Image src="/images/slides-generator-branding.png" h={{ base: 30, sm: 40 }} />
            </Anchor>

            <Group h="100%" gap={0} visibleFrom="md" align="center">
              <Switch
                checked={colorScheme === 'light'}
                onChange={(event) => setColorScheme(event.currentTarget.checked ? 'light' : 'dark')}
                size="lg"
                mr="md"
                onLabel={
                  <IconSun
                    style={{ width: rem(16), height: rem(16) }}
                    stroke={2.5}
                    color={theme.colors.yellow[4]}
                  />
                }
                offLabel={
                  <IconMoon
                    style={{ width: rem(16), height: rem(16) }}
                    stroke={2.5}
                    color={theme.colors.blue[6]}
                  />
                }
              />
              <Menu position="bottom-start" radius={0}>
                <Menu.Target>
                  <Anchor
                    px="md"
                    td="none"
                    c="light-dark(var(--mantine-color-black), var(--mantine-color-white))"
                    fw={500}
                    fz="sm"
                  >
                    <Center inline>
                      <Box component="span" mr={5}>
                        Features
                      </Box>
                      <IconChevronDown
                        style={{ width: rem(16), height: rem(16) }}
                        color={theme.colors.blue[6]}
                      />
                    </Center>
                  </Anchor>
                </Menu.Target>

                <Menu.Dropdown>
                  <Menu.Item>
                    <Anchor c="white" underline="never" href="/#convert-to-google-slides">
                      Convert to Google Slides
                    </Anchor>
                  </Menu.Item>
                  <Menu.Item>
                    <Anchor c="white" underline="never" href="/#present-as-web-slides">
                      Present as Web Slides
                    </Anchor>
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
              <Anchor
                href="/#pricing"
                px="md"
                td="none"
                c="light-dark(var(--mantine-color-black), var(--mantine-color-white))"
                fw={500}
                fz="sm"
              >
                Pricing
              </Anchor>
              <Anchor
                href="/blog"
                px="md"
                td="none"
                c="light-dark(var(--mantine-color-black), var(--mantine-color-white))"
                fw={500}
                fz="sm"
              >
                Blog
              </Anchor>
              <Menu position="bottom-start" radius={0}>
                <Menu.Target>
                  <Anchor
                    px="md"
                    td="none"
                    c="light-dark(var(--mantine-color-black), var(--mantine-color-white))"
                    fw={500}
                    fz="sm"
                  >
                    <Center inline>
                      <Box component="span" mr={5}>
                        Support
                      </Box>
                      <IconChevronDown
                        style={{ width: rem(16), height: rem(16) }}
                        color={theme.colors.blue[6]}
                      />
                    </Center>
                  </Anchor>
                </Menu.Target>

                <Menu.Dropdown>
                  <Menu.Item>
                    <Anchor
                      c="white"
                      underline="never"
                      href="#tally-open=mJ6BOY&tally-emoji-text=👋&tally-emoji-animation=wave"
                    >
                      Contact Us
                    </Anchor>
                  </Menu.Item>
                  <Menu.Item>
                    <Anchor c="white" underline="never" href="/help/get-started">
                      Help Center
                    </Anchor>
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
              <Group ml="xs">
                <Button variant="default" component="a" href="/users/sign_in">
                  Sign in
                </Button>
                <Button
                  component="a"
                  href="https://workspace.google.com/marketplace/app/slides_generator_convert_docs%E1%B5%80%E1%B4%B9_to_slide/398255519813"
                  variant="gradient"
                  gradient={{ from: '#FE9920', to: '#FF2A77' }}
                >
                  Get started
                </Button>
              </Group>
            </Group>

            <Burger opened={drawerOpened} onClick={toggleDrawer} hiddenFrom="md" />
          </Group>
        </Container>
      </Box>

      <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        size="100%"
        title={
          <Anchor href="/">
            <Image src="/images/slides-generator-branding.png" h={30} />
          </Anchor>
        }
        padding="md"
        hiddenFrom="md"
        zIndex={1000000}
      >
        <ScrollArea h={`calc(100vh - ${rem(80)})`} mx="-md">
          <Switch
            checked={colorScheme === 'light'}
            onChange={(event) => setColorScheme(event.currentTarget.checked ? 'light' : 'dark')}
            size="lg"
            ml="md"
            mb="md"
            onLabel={
              <IconSun
                style={{ width: rem(16), height: rem(16) }}
                stroke={2.5}
                color={theme.colors.yellow[4]}
              />
            }
            offLabel={
              <IconMoon
                style={{ width: rem(16), height: rem(16) }}
                stroke={2.5}
                color={theme.colors.blue[6]}
              />
            }
          />
          <NavLink
            c="light-dark(var(--mantine-color-black), var(--mantine-color-white))"
            fw={500}
            pl="lg"
            label="Features"
            childrenOffset={28}
          >
            <NavLink
              c="light-dark(var(--mantine-color-black), var(--mantine-color-white))"
              fw={500}
              pl="lg"
              label="Convert to Google Slides"
              href="/#convert-to-google-slides"
              onClick={closeDrawer}
            />
            <NavLink
              c="light-dark(var(--mantine-color-black), var(--mantine-color-white))"
              fw={500}
              pl="lg"
              label="Present as Web Slides"
              href="/#present-as-web-slides"
              onClick={closeDrawer}
            />
          </NavLink>
          <NavLink
            c="light-dark(var(--mantine-color-black), var(--mantine-color-white))"
            fw={500}
            pl="lg"
            href="/#pricing"
            label="Pricing"
            onClick={closeDrawer}
          />
          <NavLink
            c="light-dark(var(--mantine-color-black), var(--mantine-color-white))"
            fw={500}
            pl="lg"
            href="/blog"
            label="Blog"
          />
          <NavLink
            c="light-dark(var(--mantine-color-black), var(--mantine-color-white))"
            fw={500}
            pl="lg"
            label="Support"
            childrenOffset={28}
          >
            <NavLink
              c="light-dark(var(--mantine-color-black), var(--mantine-color-white))"
              fw={500}
              pl="lg"
              label="Contact Us"
              href="#tally-open=mJ6BOY&tally-emoji-text=👋&tally-emoji-animation=wave"
              onClick={closeDrawer}
            />
            <NavLink
              c="light-dark(var(--mantine-color-black), var(--mantine-color-white))"
              fw={500}
              pl="lg"
              label="Help Center"
              href="/help/get-started"
              onClick={closeDrawer}
            />
          </NavLink>

          <Group justify="center" grow pb="xl" px="md" mt="md">
            <Button variant="default" component="a" href="/users/sign_in">
              Sign in
            </Button>
            <Button
              component="a"
              href="https://workspace.google.com/marketplace/app/slides_generator_convert_docs%E1%B5%80%E1%B4%B9_to_slide/398255519813"
              variant="gradient"
              gradient={{ from: '#FE9920', to: '#FF2A77' }}
            >
              Get started
            </Button>
          </Group>
        </ScrollArea>
      </Drawer>
    </Box>
  );
}
