import { AspectRatio, Box } from '@mantine/core';
import { useInViewport } from '@mantine/hooks';

export function Website({ src }) {
  const { ref, inViewport } = useInViewport();

  return (
    <AspectRatio ref={ref}>
      {inViewport ? (
        <iframe
          src={src}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            border: 0,
          }}
        ></iframe>
      ) : (
        <Box
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            border: 0,
          }}
        ></Box>
      )}
    </AspectRatio>
  );
}
