import { modals } from '@mantine/modals';
import { Group, Text, Button } from '@mantine/core';

export function normalize(dataArray) {
  if (!dataArray) return {};

  return dataArray.reduce((acc, currentItem) => {
    acc[currentItem.value] = currentItem;
    return acc;
  }, {});
}

export async function loadFont(fontsData, fontWeight) {
  if (!fontsData) return;

  const fontWeightString = fontWeight === '400' ? 'regular' : fontWeight;
  const fontUrl = fontsData.metadata.files[fontWeightString] || fontsData.metadata.files['regular'];
  const font = new FontFace(fontsData.value, `url(${fontUrl})`, { weight: fontWeight });

  await font.load();
  document.fonts.add(font);
}

export function processError(error) {
  if (error.message.includes('401 Unauthorized')) {
    modals.open({
      title: 'Session expired',
      children: (
        <>
          <Text>Your session has expired. Please refresh your browser and try again.</Text>
          <Group justify="right">
            <Button onClick={() => window.location.reload()}>Refresh</Button>
          </Group>
        </>
      ),
    });
  }
}

export function containsMath(str) {
  const inlineMathDelimiters = [
    { left: '$', right: '$' },
    { left: '\\(', right: '\\)' },
  ];

  const displayMathDelimiters = [
    { left: '$$', right: '$$' },
    { left: '\\[', right: '\\]' },
  ];

  const allDelimiters = [...inlineMathDelimiters, ...displayMathDelimiters];

  for (const { left, right } of allDelimiters) {
    const regex = new RegExp(
      `${left.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')}.*?${right.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')}`,
      's'
    );
    if (regex.test(str)) {
      return true;
    }
  }

  return false;
}

export function calculateNewSetting(width, property) {
  return width >= 960
    ? `${parseInt(property)}px`
    : `${Math.ceil((width / 960) * parseInt(property))}px`;
}

export function formatDate(string) {
  return new Date(string).toLocaleDateString(navigator.language || 'en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
}
