import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Box, Title, Skeleton, SegmentedControl } from '@mantine/core';
import { getFonts } from '@/requests';
import { normalize } from '@/helpers';
import { StylesGroup } from './StylesGroup';
import { StylesGroupAllHeadings } from './StylesGroupAllHeadings';

export function Typography({ form, formValues }) {
  let { id } = useParams();
  const [textLevel, setTextLevel] = useState('all');

  const { data: fonts, isLoading } = useQuery({
    queryKey: ['fonts'],
    queryFn: getFonts,
  });

  if (isLoading) {
    return (
      <>
        <Skeleton height={50} circle mb="xl" />
        <Skeleton height={8} radius="xl" />
        <Skeleton height={8} mt={6} radius="xl" />
        <Skeleton height={8} mt={6} width="70%" radius="xl" />
      </>
    );
  }

  const normalizedFonts = normalize(fonts);
  const styleGroupProps = { fonts, normalizedFonts, formValues, form };

  return (
    <form>
      <Title size="h3" mb="md">
        Typography
      </Title>

      <Box mb="md">
        <SegmentedControl
          withItemsBorders={false}
          value={textLevel}
          onChange={setTextLevel}
          data={[
            { label: 'HEADINGS', value: 'all' },
            { label: 'H1', value: 'h1' },
            { label: 'H2', value: 'h2' },
            { label: 'H3', value: 'h3' },
            { label: 'BODY', value: 'body' },
          ]}
          mb="md"
        />
        {textLevel === 'all' && <StylesGroupAllHeadings {...styleGroupProps} />}
        {textLevel === 'h1' && <StylesGroup level="h1" {...styleGroupProps} />}
        {textLevel === 'h2' && <StylesGroup level="h2" {...styleGroupProps} />}
        {textLevel === 'h3' && <StylesGroup level="h3" {...styleGroupProps} />}
        {textLevel === 'body' && <StylesGroup level="body" {...styleGroupProps} />}
      </Box>
    </form>
  );
}
