import { ThemeCard } from './ThemeCard';

export function ThemeCards({
  showThemeCardTitleInput,
  setShowThemeCardTitleInput,
  themeType,
  themes,
  form,
}) {
  if (themes && themes.length) {
    return themes.map((theme) => {
      return (
        <ThemeCard
          showThemeCardTitleInput={showThemeCardTitleInput}
          setShowThemeCardTitleInput={setShowThemeCardTitleInput}
          themeType={themeType}
          theme={theme}
          form={form}
        />
      );
    });
  } else {
    return null;
  }
}
