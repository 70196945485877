export function getUser() {
  return fetch(`/api/users/1`, {
    headers: {
      Authorization: `Bearer ${document.getElementById('root').getAttribute('data-key')}`,
    },
  }).then((res) => {
    if (!res.ok) {
      throw new Error(`${res.status} ${res.statusText}`);
    }
    return res.json();
  });
}

export function deleteUser() {
  return fetch(`/api/users/1`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${document.getElementById('root').getAttribute('data-key')}`,
    },
  }).then((res) => {
    if (!res.ok) {
      throw new Error(`${res.status} ${res.statusText}`);
    }
    return res.json();
  });
}

export function getFonts() {
  return fetch(`/api/fonts`, {
    headers: {
      Authorization: `Bearer ${document.getElementById('root').getAttribute('data-key')}`,
    },
  })
    .then((res) => {
      if (!res.ok) {
        throw new Error(`${res.status} ${res.statusText}`);
      }
      return res.json();
    })
    .then((data) => {
      return data.map((font) => ({
        value: font.identifier,
        label: font.name,
        metadata: font.data,
      }));
    });
}

export function getThemes() {
  return fetch(`/api/themes`, {
    headers: {
      Authorization: `Bearer ${document.getElementById('root').getAttribute('data-key')}`,
    },
  }).then((res) => {
    if (!res.ok) {
      throw new Error(`${res.status} ${res.statusText}`);
    }
    return res.json();
  });
}

export function createTheme(theme: any) {
  return fetch(`/api/themes`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${document.getElementById('root').getAttribute('data-key')}`,
    },
    body: JSON.stringify(theme),
  }).then((res) => {
    if (!res.ok) {
      throw new Error('Failed');
    }
    return res.json();
  });
}

export function updateTheme(theme: any) {
  return fetch(`/api/themes/${theme.id}?presentation_id=${theme.presentationId}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${document.getElementById('root').getAttribute('data-key')}`,
    },
    body: JSON.stringify(theme),
  }).then((res) => {
    if (!res.ok) {
      throw new Error(`${res.status} ${res.statusText}`);
    }
    return res.json();
  });
}

export function uploadLogo(theme: any) {
  return fetch(`/api/themes/${theme.get('id')}?presentation_id=${theme.get('presentationId')}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${document.getElementById('root').getAttribute('data-key')}`,
    },
    body: theme,
  }).then((res) => {
    if (!res.ok) {
      throw new Error(`${res.status} ${res.statusText}`);
    }
    return res.json();
  });
}

export function updatePresentation(presentation) {
  return fetch(`/api/presentations/${presentation.id}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${document.getElementById('root').getAttribute('data-key')}`,
    },
    body: JSON.stringify(presentation),
  }).then((res) => {
    if (!res.ok) {
      throw new Error(`${res.status} ${res.statusText}`);
    }
    return res.json();
  });
}

export function createSlide({ presentation_id, index }) {
  return fetch(`/api/presentations/${presentation_id}/slides`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${document.getElementById('root').getAttribute('data-key')}`,
    },
    body: JSON.stringify({ index }),
  }).then((res) => {
    if (!res.ok) {
      throw new Error(`${res.status} ${res.statusText}`);
    }
    return res.json();
  });
}

export function updateSlide(slide) {
  return fetch(`/api/presentations/${slide.presentation_id}/slides/${slide.id}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${document.getElementById('root').getAttribute('data-key')}`,
    },
    body: JSON.stringify(slide),
  }).then((res) => {
    if (!res.ok) {
      throw new Error(`${res.status} ${res.statusText}`);
    }
    return res.json();
  });
}

export function deleteSlide(slide) {
  return fetch(`/api/presentations/${slide.presentation_id}/slides/${slide.id}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${document.getElementById('root').getAttribute('data-key')}`,
    },
  }).then((res) => {
    if (!res.ok) {
      throw new Error(`${res.status} ${res.statusText}`);
    }
    return res.json();
  });
}

export function deleteTheme(id) {
  return fetch(`/api/themes/${id}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${document.getElementById('root').getAttribute('data-key')}`,
    },
  }).then((res) => {
    if (!res.ok) {
      throw new Error(`${res.status} ${res.statusText}`);
    }
    return res.json();
  });
}

export function getPresentation(id, password = null) {
  let baseUrl = `/api/presentations/${id}`;
  if (password) {
    baseUrl += `?password=${password}`;
  }
  return fetch(baseUrl, {
    headers: {
      Authorization: `Bearer ${document.getElementById('root').getAttribute('data-key')}`,
    },
  })
    .then((res) => {
      if (!res.ok) {
        throw new Error(`${res.status} ${res.statusText}`);
      }
      return res.json();
    })
    .then((res) => {
      if (res.error) {
        throw new Error(res.error);
      } else {
        return res;
      }
    });
}

export function deletePresentation(id) {
  return fetch(`/api/presentations/${id}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${document.getElementById('root').getAttribute('data-key')}`,
    },
  }).then((res) => {
    if (!res.ok) {
      throw new Error(`${res.status} ${res.statusText}`);
    }
    return res.json();
  });
}

export function getWorkspace() {
  return fetch(`/api/workspaces/0`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${document.getElementById('root').getAttribute('data-key')}`,
    },
  }).then((res) => {
    if (!res.ok) {
      throw new Error(`${res.status} ${res.statusText}`);
    }
    return res.json();
  });
}

export function addUserToWorkspace(email) {
  return fetch(`/api/workspaces/add_user`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${document.getElementById('root').getAttribute('data-key')}`,
    },
    body: JSON.stringify({ email: email.trim() }),
  }).then((res) => {
    if (!res.ok) {
      throw new Error(`${res.status} ${res.statusText}`);
    }
    return res.json();
  });
}

export function removeUserFromWorkspace(email) {
  return fetch(`/api/workspaces/remove_user`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${document.getElementById('root').getAttribute('data-key')}`,
    },
    body: JSON.stringify({ email }),
  }).then((res) => {
    if (!res.ok) {
      throw new Error(`${res.status} ${res.statusText}`);
    }
    return res.json();
  });
}
