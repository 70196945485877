import { Container, Title } from '@mantine/core';
import Markdown from 'react-markdown';
import { Footer } from '../HomePage/Footer';
import { NavBar } from '../HomePage/NavBar';
import PrivacyPolicyMarkdown from './PrivacyPolicy.md?raw';

export function PrivacyPolicy() {
  return (
    <>
      <NavBar />
      <Container my="lg">
        <Title order={1}>Privacy Policy</Title>
        <Markdown>{PrivacyPolicyMarkdown}</Markdown>
      </Container>
      <Footer />
    </>
  );
}
