import { deleteUser } from '@/requests';
import { Button, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useMutation } from '@tanstack/react-query';

export function CloseAccountForm({ user }) {
  const form = useForm({
    mode: 'uncontrolled',
    validate: {
      email: (value) =>
        !value
          ? 'Please enter email address'
          : value !== user.email
            ? 'Email address incorrect'
            : null,
    },
  });

  const deleteThemeMutation = useMutation({
    mutationFn: () => deleteUser(),
    onSuccess: () => {
      window.location.reload();
    },
  });

  return (
    <form onSubmit={form.onSubmit(() => deleteThemeMutation.mutate())}>
      <TextInput
        label="Enter your email address to continue"
        placeholder="Your email address"
        data-autofocus
        mb="md"
        key={form.key('email')}
        {...form.getInputProps('email')}
      />
      <Button fullWidth bg="red" type="submit">
        Close account
      </Button>
    </form>
  );
}
