import { formatDate } from '@/helpers';
import {
  Avatar,
  Box,
  Container,
  Grid,
  Group,
  Text,
  Title,
  TypographyStylesProvider,
  UnstyledButton,
  createTheme,
  rem,
} from '@mantine/core';
import { useEffect, useState } from 'react';
import Markdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import { Footer } from '../HomePage/Footer';
import { NavBar } from '../HomePage/NavBar';
import { TableOfContents } from '../TableOfContents/TableOfContents';
import articles from './articles.json';
import { NavSidebar } from './NavSidebar';

const theme = createTheme({
  /** Your theme override here */
});

const generateId = (text) => {
  return text
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/^-+|-+$/g, '');
};

export function HelpArticle() {
  const { id } = useParams();
  const [links, setLinks] = useState([]);
  const [article, setArticle] = useState('');
  import(`./articles/${id}.md`).then((res) => {
    fetch(res.default)
      .then((response) => response.text())
      .then((text) => setArticle(text));
  });
  const metadata = articles.find((article) => article.slug === id);

  useEffect(() => {
    const generateLinks = () => {
      const linksArray = [];
      const headings = document.querySelectorAll('h2, h3, h4');

      headings.forEach((heading) => {
        const level = heading.tagName.toLowerCase();
        const order = parseInt(level.replace('h', ''));
        heading.id = generateId(heading.textContent);
        const text = heading.textContent;

        linksArray.push({ label: text, link: `#${heading.id}`, order });
      });

      setLinks(linksArray);
    };

    generateLinks();
  }, [article]);

  return (
    <>
      <NavBar />
      <Box h={{ base: 60, sm: 70 }} w="100%" />
      <Container size="lg">
        <Grid mt={rem(100)} gutter={100}>
          <Grid.Col span={{ base: 12, md: 4 }}>
            <NavSidebar />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 8 }}>
            <Box component="section">
              <Title order={1} fz={{ sm: rem(30), md: rem(50) }} fw={900} mb="lg">
                {metadata.title}
              </Title>
              <Text size="xl" mb="lg">
                {metadata.summary}
              </Text>
              <Group justify="space-between" mb="lg" align="center">
                <Group align="center">
                  <Avatar src={metadata.authorImg} radius="xl" />
                  <UnstyledButton component="a" href={`/help?author=${metadata.author}`}>
                    <Text fw={500}>{metadata.author}</Text>
                  </UnstyledButton>
                  <Text fz="xs" c="dimmed">
                    {formatDate(metadata.publishedAt)}
                  </Text>
                </Group>
              </Group>
              <TableOfContents links={links} />
              <TypographyStylesProvider styles={{ root: { fontSize: rem(18) } }}>
                <Markdown>{article}</Markdown>
              </TypographyStylesProvider>
            </Box>
          </Grid.Col>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}
