import { loadFont, normalize, processError } from '@/helpers';
import { useRefState } from '@/hooks/useRefState';
import { getFonts, getPresentation, getUser, updateTheme } from '@/requests';
import { AppShell, Container, Modal, Skeleton } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure, useFullscreen } from '@mantine/hooks';
import { ModalsProvider } from '@mantine/modals';
import { Notifications, notifications } from '@mantine/notifications';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { NotFoundTitle } from '../NotFound/NotFound';
import { PricingTables } from '../PricingTables/PricingTables';
import appShell from './AppShell.module.css';
import { NavBar } from './NavBar';
import { PassCode } from './PassCode';
import { Screens } from './Screens';
import { StyleNavbar } from './StyleNavbar';

const initialStyles = {
  background_color: '#fff',
  body_alignment: 'left',
  body_font_size: 40,
  body_font_color: '#000',
  body_font_family: 'roboto',
  body_font_weight: '400',
  body_italic: false,
  body_underlined: false,
  body_strikethrough: false,
  all_headings_alignment: 'left',
  all_headings_font_size: 60,
  all_headings_font_color: '#000',
  all_headings_font_family: 'roboto',
  all_headings_font_weight: '400',
  all_headings_italic: false,
  all_headings_underlined: false,
  all_headings_strikethrough: false,
  h1_alignment: 'left',
  h1_font_size: 60,
  h1_font_color: '#000',
  h1_font_family: 'roboto',
  h1_font_weight: '400',
  h1_italic: false,
  h1_underlined: false,
  h1_strikethrough: false,
  h2_alignment: 'left',
  h2_font_size: 50,
  h2_font_color: '#000',
  h2_font_family: 'roboto',
  h2_font_weight: '400',
  h2_italic: false,
  h2_underlined: false,
  h2_strikethrough: false,
  h3_alignment: 'left',
  h3_font_size: 40,
  h3_font_color: '#000',
  h3_font_family: 'roboto',
  h3_font_weight: '400',
  h3_italic: false,
  h3_underlined: false,
  h3_strikethrough: false,
};

export function Presentation() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  let { id } = useParams();
  const { toggle: toggleFullscreen, fullscreen } = useFullscreen();
  const [sidenavOpened, { toggle: toggleSidenav, close: closeSidenav }] = useDisclosure(false);
  const [upgradeModalOpened, { close: closeUpgradeModal, open: openUpgradeModal }] =
    useDisclosure(false);
  const [showThemeCardTitleInput, setShowThemeCardTitleInput] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [enteredPasswordRef, enteredPassword, setEnteredPassword] = useRefState(null);
  const [updateFunctionRef, updateFunction, setUpdateFunction] = useRefState(true);
  const presentationRef = useRef(null);

  const {
    data: presentation,
    isLoading: presentationLoading,
    refetch,
    error,
  } = useQuery({
    queryKey: ['presentation', id],
    queryFn: () => getPresentation(id, enteredPasswordRef.current),
  });

  const { data: user, isLoading: userLoading } = useQuery({
    queryKey: ['user'],
    queryFn: getUser,
  });

  const updateThemeMutation = useMutation({
    mutationFn: updateTheme,
    onSuccess: async () => {
      setUpdateFunction(null);
    },
    onError: processError,
  });

  const form = useForm({
    mode: 'uncontrolled',
    initialValues: initialStyles,
    onValuesChange: (values) => {
      setFormValues(values);
      if (updateFunctionRef.current) {
        clearTimeout(updateFunctionRef.current);
      }
      const updateFunction = setTimeout(
        () =>
          updateThemeMutation.mutate({
            ...form.getValues(),
            ...{ id: presentationRef.current?.theme?.id, presentationId: id },
          }),
        3000
      );
      setUpdateFunction(updateFunction);
    },
  });

  // Avoid stale value of presentationRef due to closures.
  useEffect(() => {
    presentationRef.current = presentation;
  }, [presentation]);

  // Avoid stale value of presentationRef due to closures.
  useEffect(() => {
    enteredPasswordRef.current = enteredPassword;
  }, [enteredPassword]);

  const { data: fonts } = useQuery({
    queryKey: ['fonts'],
    queryFn: getFonts,
  });
  const normalizedFonts = normalize(fonts);

  useEffect(() => {
    if (Object.values(normalizedFonts).length === 0) return;

    if (formValues.h1_font_family) {
      loadFont(normalizedFonts[formValues.h1_font_family], formValues.h1_font_weight);
    }
    if (formValues.h2_font_family) {
      loadFont(normalizedFonts[formValues.h2_font_family], formValues.h2_font_weight);
    }
    if (formValues.h3_font_family) {
      loadFont(normalizedFonts[formValues.h3_font_family], formValues.h3_font_weight);
    }
    if (formValues.body_font_family) {
      loadFont(normalizedFonts[formValues.body_font_family], formValues.body_font_weight);
    }
  }, [
    normalizedFonts,
    formValues.h1_font_family,
    formValues.h2_font_family,
    formValues.h3_font_family,
    formValues.body_font_family,
  ]);

  function handleSubmit() {
    refetch().then((result) => {
      if (result.data?.error) {
        notifications.show({ message: result.data.error });
      }
    });
  }

  useEffect(() => {
    setUpdateFunction(null);
    if (presentation?.theme?.styles) {
      const styles = presentation.theme.styles;
      form.initialize({
        ...styles,
        logo_url: presentation.theme?.logo_url,
        attachment_id: presentation.theme?.attachment_id,
      });
    }
  }, [presentation]);

  function renderResponse() {
    if (presentationLoading || userLoading) {
      return (
        <Container w="1400px" p="2rem">
          <Skeleton height={150} circle mb="xl" />
          <Skeleton height={8} radius="xl" />
          <Skeleton height={8} mt={6} radius="xl" />
          <Skeleton height={8} mt={6} width="70%" radius="xl" />
        </Container>
      );
    }
    if (error) {
      if (error.message === 'Passcode required') {
        return (
          <PassCode setEnteredPassword={setEnteredPassword} handleSubmit={handleSubmit}></PassCode>
        );
      } else {
        return <NotFoundTitle />;
      }
    }
    if ((presentation && presentation?.content?.length) || isAuthenticated) {
      return (
        <AppShell
          pos="relative"
          header={{ height: 60, collapsed: fullscreen }}
          navbar={{
            width: 500,
            breakpoint: 'sm',
            collapsed: {
              mobile: fullscreen || !sidenavOpened,
              desktop: fullscreen || !sidenavOpened,
            },
          }}
        >
          <AppShell.Header>
            <NavBar
              user={user}
              toggleFullscreen={toggleFullscreen}
              presentation={presentation}
              handleEditStyles={toggleSidenav}
              updateFunction={updateFunction}
            />
          </AppShell.Header>
          <AppShell.Navbar p="md">
            <StyleNavbar
              user={user}
              openUpgradeModal={openUpgradeModal}
              showThemeCardTitleInput={showThemeCardTitleInput}
              setShowThemeCardTitleInput={setShowThemeCardTitleInput}
              form={form}
              formValues={formValues}
              closeSidenav={closeSidenav}
            />
          </AppShell.Navbar>
          <AppShell.Main className={appShell.main} pos="relative">
            <Screens
              updateFunctionRef={updateFunctionRef}
              setUpdateFunction={setUpdateFunction}
              user={user}
              sidenavOpened={sidenavOpened}
              fullscreen={fullscreen}
              formValues={formValues}
              isLoading={presentationLoading || userLoading}
              presentation={presentation}
            />
          </AppShell.Main>
        </AppShell>
      );
    }
  }

  return (
    <ModalsProvider>
      <Modal
        opened={upgradeModalOpened}
        onClose={closeUpgradeModal}
        size="auto"
        withCloseButton={false}
        styles={{ body: { padding: 0 } }}
      >
        <PricingTables />
      </Modal>
      <Notifications />
      {renderResponse()}
    </ModalsProvider>
  );
}
