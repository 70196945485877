import { useState } from 'react';
import { Title, SegmentedControl, ColorInput, Text, Slider } from '@mantine/core';

export function Background({ form, formValues }) {
  const [colorType, setColorType] = useState(formValues.background_color_type || 'solid');

  return (
    <form>
      <Title size="h3" mb="md">
        Background
      </Title>

      <SegmentedControl
        withItemsBorders={false}
        fullWidth
        value={colorType}
        onChange={(value) => {
          form.setFieldValue('background_color_type', value);
          setColorType(value);
        }}
        data={[
          { label: 'Solid', value: 'solid' },
          { label: 'Gradient', value: 'gradient' },
        ]}
        mb="md"
      />
      {colorType === 'solid' ? (
        <ColorInput
          w="100%"
          id={'background_color'}
          name={'background_color'}
          format="hex"
          swatches={[
            '#2e2e2e',
            '#868e96',
            '#fa5252',
            '#e64980',
            '#be4bdb',
            '#7950f2',
            '#4c6ef5',
            '#228be6',
            '#15aabf',
            '#12b886',
            '#40c057',
            '#82c91e',
            '#fab005',
            '#fd7e14',
          ]}
          mb="sm"
          key={form.key('background_color')}
          {...form.getInputProps('background_color')}
        />
      ) : (
        <>
          <ColorInput
            w="100%"
            id={'background_color_gradient_from'}
            name={'background_color_gradient_from'}
            format="hex"
            swatches={[
              '#2e2e2e',
              '#868e96',
              '#fa5252',
              '#e64980',
              '#be4bdb',
              '#7950f2',
              '#4c6ef5',
              '#228be6',
              '#15aabf',
              '#12b886',
              '#40c057',
              '#82c91e',
              '#fab005',
              '#fd7e14',
            ]}
            mb="sm"
            key={form.key('background_color_gradient_from')}
            {...form.getInputProps('background_color_gradient_from')}
          />
          <ColorInput
            w="100%"
            id={'background_color_gradient_to'}
            name={'background_color_gradient_to'}
            format="hex"
            swatches={[
              '#2e2e2e',
              '#868e96',
              '#fa5252',
              '#e64980',
              '#be4bdb',
              '#7950f2',
              '#4c6ef5',
              '#228be6',
              '#15aabf',
              '#12b886',
              '#40c057',
              '#82c91e',
              '#fab005',
              '#fd7e14',
            ]}
            mb="sm"
            key={form.key('background_color_gradient_to')}
            {...form.getInputProps('background_color_gradient_to')}
          />
          <Text size="sm">Gradient angle</Text>
          <Slider
            color="blue"
            marks={[
              { value: 0, label: '0°' },
              { value: 90, label: '90°' },
              { value: 180, label: '180°' },
              { value: 270, label: '270°' },
              { value: 360, label: '360°' },
            ]}
            min={0}
            max={360}
            value={formValues.background_color_gradient_angle}
            onChangeEnd={(value) => form.setFieldValue('background_color_gradient_angle', value)}
          />
        </>
      )}
    </form>
  );
}
