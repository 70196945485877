import { useState, useRef } from 'react';
import {
  IconCheck,
  IconMinus,
  IconPlus,
  IconItalic,
  IconUnderline,
  IconStrikethrough,
  IconAlignLeft,
  IconAlignCenter,
  IconAlignRight,
  IconAlignJustified,
} from '@tabler/icons-react';
import {
  Flex,
  Group,
  Select,
  SelectProps,
  Text,
  ColorInput,
  ActionIcon,
  NumberInput,
  NumberInputHandlers,
  Title,
} from '@mantine/core';
import { loadFont } from '@/helpers';

const iconProps = {
  stroke: 1.5,
  color: 'currentColor',
  opacity: 0.6,
  size: 18,
};

const renderFontFamilyOption: SelectProps['renderOption'] = ({ option, checked }) => {
  return (
    <Group flex="1" gap="xs">
      <Text style={{ fontFamily: option.label }}>{option.label}</Text>
      {checked && <IconCheck style={{ marginInlineStart: 'auto' }} {...iconProps} />}
    </Group>
  );
};

const renderFontWeightOption: SelectProps['renderOption'] = ({ option, checked }) => {
  return (
    <Group flex="1" gap="xs">
      <Text style={{ fontWeight: option.value }}>{option.label}</Text>
      {checked && <IconCheck style={{ marginInlineStart: 'auto' }} {...iconProps} />}
    </Group>
  );
};

function returnVariants(rawData) {
  if (!rawData) return [{ value: 'regular', label: 'Normal' }];

  const mappings = {
    '100': 'Thin (Hairline)',
    '200': 'Extra Light (Ultra Light)',
    '300': 'Light',
    '400': 'Normal',
    '500': 'Medium',
    '600': 'Semi Bold (Demi Bold)',
    '700': 'Bold',
    '800': 'Extra Bold (Ultra Bold)',
    '900': 'Black (Heavy)',
  };

  return rawData
    .filter((item) => !item.includes('italic')) // Filter out italic options
    .map((item) => {
      let value = item === 'regular' ? '400' : item;

      const label = mappings[value];

      return { value, label };
    });
}

function ActionIconCycle({ name, getValues, setFieldValue, options }) {
  const iconsMapping = {
    left: IconAlignLeft,
    center: IconAlignCenter,
    right: IconAlignRight,
    justify: IconAlignJustified,
  };
  const [currentIndex, setCurrentIndex] = useState(
    getValues()[name] ? options.indexOf(getValues()[name]) : 0
  );
  const Component = iconsMapping[options[currentIndex]];

  function toggleNextIcon() {
    const nextAlignmentIndex = currentIndex + 1;
    const newIndex = nextAlignmentIndex <= options.length - 1 ? nextAlignmentIndex : 0;
    setCurrentIndex(newIndex);
    setFieldValue(name, options[newIndex]);
  }

  return (
    <ActionIcon size={36} variant="default" onClick={toggleNextIcon}>
      <Component />
    </ActionIcon>
  );
}

function ActionIconToggle({ icon, name, getValues, setFieldValue }) {
  const [enabled, setEnabled] = useState(getValues()[name]);
  const Component = icon;

  return (
    <ActionIcon
      bg={enabled ? 'green' : 'none'}
      size={36}
      variant="default"
      onClick={() => {
        const newValue = !enabled;
        setEnabled(newValue);
        setFieldValue(name, newValue);
      }}
    >
      <Component />
    </ActionIcon>
  );
}

export function StylesGroup({ level, fonts, normalizedFonts, formValues, form }) {
  const fontSizeHandlerRef = useRef<NumberInputHandlers>(null);
  const availableHeadingFontWeights = returnVariants(
    normalizedFonts[formValues[`${level}_font_family`]]?.metadata?.variants
  );
  const fontId = normalizedFonts[formValues[`${level}_font_family`]]?.label;

  return (
    <>
      <Title size="h4" mb="sm">
        {level.charAt(0).toUpperCase() + level.slice(1)} Text
      </Title>
      <Select
        id={`${level}_font_family`}
        name={`${level}_font_family`}
        styles={{
          input: { fontFamily: fontId },
        }}
        w="100%"
        placeholder="Select"
        data={fonts}
        renderOption={renderFontFamilyOption}
        searchable
        mb="sm"
        key={form.key(`${level}_font_family`)}
        {...form.getInputProps(`${level}_font_family`)}
        onChange={(value) => {
          form.setFieldValue(`${level}_font_family`, value);
          form.setFieldValue(`${level}_font_weight`, '400');
          loadFont(normalizedFonts[value], '400');
        }}
      />
      <Select
        id={`${level}_font_weight`}
        name={`${level}_font_weight`}
        styles={{ input: { fontWeight: formValues[`${level}_font_weight`] } }}
        w="100%"
        placeholder="Select"
        data={availableHeadingFontWeights}
        renderOption={renderFontWeightOption}
        searchable
        mb="sm"
        key={form.key(`${level}_font_weight`)}
        {...form.getInputProps(`${level}_font_weight`)}
        onChange={(value, option) => {
          const fontWeight = value === 'regular' ? '400' : value;
          form.setFieldValue(`${level}_font_weight`, fontWeight);
          loadFont(normalizedFonts[formValues[`${level}_font_family`]], fontWeight);
        }}
      />
      <ColorInput
        id={`${level}_font_color`}
        name={`${level}_font_color`}
        format="hex"
        swatches={[
          '#2e2e2e',
          '#868e96',
          '#fa5252',
          '#e64980',
          '#be4bdb',
          '#7950f2',
          '#4c6ef5',
          '#228be6',
          '#15aabf',
          '#12b886',
          '#40c057',
          '#82c91e',
          '#fab005',
          '#fd7e14',
        ]}
        mb="sm"
        key={form.key(`${level}_font_color`)}
        {...form.getInputProps(`${level}_font_color`)}
      />
      <Group justify="space-between">
        <Flex>
          <ActionIcon
            size={36}
            variant="default"
            onClick={() => fontSizeHandlerRef.current?.decrement()}
          >
            <IconMinus />
          </ActionIcon>
          <NumberInput
            id={`${level}_font_size`}
            name={`${level}_font_size`}
            hideControls
            w="50px"
            styles={{
              input: { textAlign: 'center', fontWeight: '600' },
            }}
            key={form.key(`${level}_font_size`)}
            {...form.getInputProps(`${level}_font_size`)}
            min={0}
            max={150}
            handlersRef={fontSizeHandlerRef}
          />
          <ActionIcon
            size={36}
            variant="default"
            onClick={() => {
              fontSizeHandlerRef.current?.increment();
            }}
          >
            <IconPlus />
          </ActionIcon>
        </Flex>
        <ActionIcon.Group>
          <ActionIconCycle
            name={`${level}_alignment`}
            getValues={form.getValues}
            setFieldValue={form.setFieldValue}
            options={['left', 'center', 'right', 'justify']}
          />
          <ActionIconToggle
            name={`${level}_italic`}
            icon={IconItalic}
            getValues={form.getValues}
            setFieldValue={form.setFieldValue}
          />
          <ActionIconToggle
            icon={IconUnderline}
            name={`${level}_underlined`}
            getValues={form.getValues}
            setFieldValue={form.setFieldValue}
          />
          <ActionIconToggle
            icon={IconStrikethrough}
            name={`${level}_strikethrough`}
            getValues={form.getValues}
            setFieldValue={form.setFieldValue}
          />
        </ActionIcon.Group>
      </Group>
    </>
  );
}
