import {
  Badge,
  Box,
  Container,
  Divider,
  getThemeColor,
  Grid,
  Image,
  List,
  rem,
  SimpleGrid,
  Stack,
  Text,
  ThemeIcon,
  Title,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import {
  IconArrowsDownUp,
  IconCheck,
  IconClipboardText,
  IconDevices,
  IconHourglassHigh,
  IconLock,
  IconPalette,
  IconPresentation,
  IconQrcode,
  IconTypography,
  IconWorldWww,
  IconWriting,
} from '@tabler/icons-react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { BlogList } from '../Blog/BlogList';
import { PricingTables } from '../PricingTables/PricingTables';
import { UpdateToTermsAndPrivacyPolicy } from '../UpdateToTermsAndPrivacy/UpdateToTermsAndPrivacy';
import { Faqs } from './Faq';
import { Footer } from './Footer';
import { Hero } from './Hero';
import { NavBar } from './NavBar';
import { WavyDivider } from './WavyDivider';

export function HomePage() {
  const { colorScheme } = useMantineColorScheme();
  const theme = useMantineTheme();
  const { hash } = useLocation();

  useEffect(() => {
    const element = document.getElementById(hash.substring(1));
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, [hash]);

  return (
    <>
      <UpdateToTermsAndPrivacyPolicy />
      <NavBar />
      <Box component="section" pos="relative" py={{ base: rem(150), md: rem(250) }}>
        <Hero />
        <WavyDivider
          fill={
            colorScheme === 'light'
              ? getThemeColor('gray.3', theme)
              : getThemeColor('dark.9', theme)
          }
        />
      </Box>
      <Box
        id="convert-to-google-slides"
        component="section"
        pos="relative"
        py={rem(80)}
        bg="light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-9))"
      >
        <Container size="lg" ta="center">
          <Badge variant="filled" color="#FF2A77" size="lg" mb="sm">
            Convert to Google Slides
          </Badge>
          <Title order={2} fz={{ base: rem(30), sm: rem(40) }} ta="center" mb="xl" fw="800">
            Simplify your presentation workflow.
          </Title>
          <Text c="dimmed" mb={rem(80)} fz={{ base: rem(20), sm: rem(25) }}>
            Creating and managing dozens of presentation files is not a lot of fun when done at
            scale. By converting Google Docs to Google Slides presentations in a click, Google Docs
            becomes the source of truth to create and manage your presentation content. Here are the
            key features that make all of this possible.
          </Text>
          <Grid ta="left" gutter="xl" mb={rem(80)}>
            <Grid.Col span={{ base: 12, sm: 6 }} order={1}>
              <Stack justify="center" h="100%">
                <Title order={3} fz={{ base: rem(25), sm: rem(35) }} mb="md">
                  One document. Multiple presentations.
                </Title>
                <Text c="dimmed" fz={{ base: rem(20), sm: rem(25) }}>
                  Create multiple presentations in one document and generate them all at once.
                </Text>
              </Stack>
            </Grid.Col>
            <Grid.Col span={{ base: 12, sm: 6 }} order={2}>
              <Stack justify="center" h="100%">
                <Image
                  src="/images/one-doc-multiple-presentations.png"
                  alt="One document multiple presentations"
                />
              </Stack>
            </Grid.Col>
            <Grid.Col span={{ base: 12, sm: 6 }} order={{ base: 3, sm: 4 }}>
              <Stack justify="center" h="100%">
                <Title order={3} fz={{ base: rem(25), sm: rem(35) }} mb="md">
                  Keep your formatting and hyperlinks
                </Title>
                <List icon={<IconCheck size={16} color="green" />}>
                  <List.Item>
                    <Text c="dimmed" fz={{ base: rem(20), sm: rem(25) }}>
                      Bold, Italics, Underline
                    </Text>
                  </List.Item>
                  <List.Item>
                    <Text c="dimmed" fz={{ base: rem(20), sm: rem(25) }}>
                      Strikethrough
                    </Text>
                  </List.Item>
                  <List.Item>
                    <Text c="dimmed" fz={{ base: rem(20), sm: rem(25) }}>
                      Superscript and subscript
                    </Text>
                  </List.Item>
                  <List.Item>
                    <Text c="dimmed" fz={{ base: rem(20), sm: rem(25) }}>
                      Font colour and background colour
                    </Text>
                  </List.Item>
                  <List.Item>
                    <Text c="dimmed" fz={{ base: rem(20), sm: rem(25) }}>
                      Hyperlinks
                    </Text>
                  </List.Item>
                </List>
              </Stack>
            </Grid.Col>
            <Grid.Col span={{ base: 12, sm: 6 }} order={{ base: 4, sm: 3 }}>
              <Stack justify="center" h="100%">
                <Image
                  src="/images/keep-your-formatting.png"
                  alt="Bring over key presentation elements"
                />
              </Stack>
            </Grid.Col>
            <Grid.Col span={{ base: 12, sm: 6 }} order={5}>
              <Stack justify="center" h="100%">
                <Title order={3} fz={{ base: rem(25), sm: rem(35) }} mb="md">
                  Bring over key presentation elements
                </Title>
                <List icon={<IconCheck size={16} color="green" />}>
                  <List.Item>
                    <Text c="dimmed" fz={{ base: rem(20), sm: rem(25) }}>
                      Tables
                    </Text>
                  </List.Item>
                  <List.Item>
                    <Text c="dimmed" fz={{ base: rem(20), sm: rem(25) }}>
                      Headings and body text
                    </Text>
                  </List.Item>
                  <List.Item>
                    <Text c="dimmed" fz={{ base: rem(20), sm: rem(25) }}>
                      Lists
                    </Text>
                  </List.Item>
                  <List.Item>
                    <Text c="dimmed" fz={{ base: rem(20), sm: rem(25) }}>
                      Images
                    </Text>
                  </List.Item>
                </List>
              </Stack>
            </Grid.Col>
            <Grid.Col span={{ base: 12, sm: 6 }} order={6}>
              <Stack justify="center" h="100%">
                <Image
                  src="/images/key-presentation-elements.png"
                  alt="One document multiple presentations"
                />
              </Stack>
            </Grid.Col>
          </Grid>
          <Title order={2} fz={{ base: rem(30), sm: rem(40) }} ta="center" mb="xl" fw="800">
            Be ready to present in just a few clicks.
          </Title>
          <Text c="dimmed" mb={rem(80)} fz={{ base: rem(20), sm: rem(25) }}>
            There is a lot of manual work involved in creating presentations. Let us automate the
            boring stuff so you can spend more time on the things you care about.
          </Text>
          <SimpleGrid cols={{ base: 1, sm: 3 }} mb={rem(80)}>
            <Box>
              <ThemeIcon variant="filled" size={rem(60)} color="#FF2A77" radius="xl" mb="md">
                <IconClipboardText style={{ width: rem(36), height: rem(36) }} stroke={1.5} />
              </ThemeIcon>
              <Title order={3} fz={{ base: rem(25), sm: rem(30) }} mb="md">
                1. Gather content
              </Title>
              <Text c="dimmed" fz={{ base: rem(20), sm: rem(25) }}>
                Use an existing document or quickly create one with our slide shortcuts.
              </Text>
            </Box>
            <Box>
              <ThemeIcon variant="filled" size={rem(60)} color="#FF2A77" radius="xl" mb="md">
                <IconTypography style={{ width: rem(36), height: rem(36) }} stroke={1.5} />
              </ThemeIcon>
              <Title order={3} fz={{ base: rem(25), sm: rem(30) }} mb="md">
                2. Choose your styling
              </Title>
              <Text c="dimmed" fz={{ base: rem(20), sm: rem(25) }}>
                Optionally choose styling to apply across all slides.
              </Text>
            </Box>
            <Box>
              <ThemeIcon variant="filled" size={rem(60)} color="#FF2A77" radius="xl" mb="md">
                <IconPresentation style={{ width: rem(36), height: rem(36) }} stroke={1.5} />
              </ThemeIcon>
              <Title order={3} fz={{ base: rem(25), sm: rem(30) }} mb="md">
                3. Generate slides
              </Title>
              <Text c="dimmed" fz={{ base: rem(20), sm: rem(25) }}>
                Click a button and be ready to present in just a few moments.
              </Text>
            </Box>
          </SimpleGrid>
        </Container>
        <WavyDivider
          fill={
            colorScheme === 'light' ? getThemeColor('white', theme) : getThemeColor('dark.7', theme)
          }
        />
      </Box>
      <Box id="present-as-web-slides" component="section" pos="relative" py={rem(80)}>
        <Container size="lg" ta="center">
          <Badge variant="filled" color="#FF2A77" size="lg" mb="sm">
            Present as Web Slides
          </Badge>
          <Title order={2} fz={{ base: rem(30), sm: rem(40) }} ta="center" mb="xl" fw="800">
            Embrace the new way to present documents.
          </Title>
          <Text c="dimmed" mb={rem(80)} fz={{ base: rem(20), sm: rem(25) }}>
            Present your documents in a click using presentation software of the future. Think
            scrollable and interactive slides, embedded websites and widgets to help keep your
            audience engaged.
          </Text>
          <SimpleGrid cols={{ base: 1, sm: 3 }} mb={rem(80)} ta="left" spacing="xl">
            <Box>
              <Box mb="md">
                <IconWorldWww size={60} color="#FF2A77" />
              </Box>
              <Title order={3} fz={{ base: rem(20), sm: rem(25) }} mb="lg">
                Embedded websites
              </Title>
              <Divider size="md" w="20%" mb="lg" color="#FF2A77" />
              <Text c="dimmed" fz={{ base: rem(20), sm: rem(25) }}>
                Present in one place. We detect links in your content and embed websites and videos
                directly into your slides.
              </Text>
            </Box>
            <Box>
              <Box mb="md">
                <IconArrowsDownUp size={60} color="#FF2A77" />
              </Box>
              <Title order={3} fz={{ base: rem(20), sm: rem(25) }} mb="lg">
                Scrollable slides
              </Title>
              <Divider size="md" w="20%" mb="lg" color="#FF2A77" />
              <Text c="dimmed" fz={{ base: rem(20), sm: rem(25) }}>
                Present without limits. Don't worry about fitting content into textboxes. Let your
                content overflow.
              </Text>
            </Box>
            <Box>
              <Box mb="md">
                <IconLock size={60} color="#FF2A77" />
              </Box>
              <Title order={3} fz={{ base: rem(20), sm: rem(25) }} mb="lg">
                Password protection
              </Title>
              <Divider size="md" w="20%" mb="lg" color="#FF2A77" />
              <Text c="dimmed" fz={{ base: rem(20), sm: rem(25) }}>
                Present in peace. Choose to publish or unpublish your presentations. Protect them
                with a password.
              </Text>
            </Box>
            <Box>
              <Box mb="md">
                <IconQrcode size={60} color="#FF2A77" />
              </Box>
              <Title order={3} fz={{ base: rem(20), sm: rem(25) }} mb="lg">
                Easy sharing
              </Title>
              <Divider size="md" w="20%" mb="lg" color="#FF2A77" />
              <Text c="dimmed" fz={{ base: rem(20), sm: rem(25) }}>
                Present to the world. Easily share your presentations by publishing them and posting
                a link or QR Code.
              </Text>
            </Box>
            <Box>
              <Box mb="md">
                <IconDevices size={60} color="#FF2A77" />
              </Box>
              <Title order={3} fz={{ base: rem(20), sm: rem(25) }} mb="lg">
                Device-optimized
              </Title>
              <Divider size="md" w="20%" mb="lg" color="#FF2A77" />
              <Text c="dimmed" fz={{ base: rem(20), sm: rem(25) }}>
                Present anywhere. Whether it be a phone, tablet, or desktop, engage your users on
                any device.
              </Text>
            </Box>
            <Box>
              <Box mb="md">
                <IconPalette size={60} color="#FF2A77" />
              </Box>
              <Title order={3} fz={{ base: rem(20), sm: rem(25) }} mb="lg">
                Easy styling
              </Title>
              <Divider size="md" w="20%" mb="lg" color="#FF2A77" />
              <Text c="dimmed" fz={{ base: rem(20), sm: rem(25) }}>
                Present in style. Quickly and easily personalize your presentations with minimal
                effort.
              </Text>
            </Box>
          </SimpleGrid>
          <Title order={2} fz={{ base: rem(30), sm: rem(40) }} ta="center" mb="xl" fw="800">
            Make your presentations interactive.
          </Title>
          <Text c="dimmed" mb={rem(80)} fz={{ base: rem(20), sm: rem(25) }}>
            Presentations are more than just content. Enhance your presentations with built-in
            widgets. Useful apps to make your life easier.
          </Text>
          <SimpleGrid cols={{ base: 1, sm: 2 }} mb={rem(80)} ta="left" spacing="xl">
            <Box>
              <Box mb="md">
                <IconHourglassHigh size={60} color="#FE9920" />
              </Box>
              <Title order={3} fz={{ base: rem(20), sm: rem(25) }} mb="lg">
                Timer app
              </Title>
              <Divider size="md" w="20%" mb="lg" color="#FE9920" />
              <Text c="dimmed" fz={{ base: rem(20), sm: rem(25) }}>
                Stay in control and manage time effectively in classrooms and training sessions. Set
                the mood with some music.
              </Text>
            </Box>
            <Box>
              <Box mb="md">
                <IconWriting size={60} color="#FE9920" />
              </Box>
              <Title order={3} fz={{ base: rem(20), sm: rem(25) }} mb="lg">
                Annotations app
              </Title>
              <Divider size="md" w="20%" mb="lg" color="#FE9920" />
              <Text c="dimmed" fz={{ base: rem(20), sm: rem(25) }}>
                Turn your slides into a whiteboard. Make notes and drawings on your slides to help
                explain ideas and concepts.
              </Text>
            </Box>
          </SimpleGrid>
        </Container>
        <WavyDivider
          fill={
            colorScheme === 'light'
              ? getThemeColor('gray.3', theme)
              : getThemeColor('dark.9', theme)
          }
        />
      </Box>
      <Box
        id="pricing"
        component="section"
        pos="relative"
        bg="light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-9))"
        py={rem(80)}
      >
        <PricingTables />
        <WavyDivider
          fill={
            colorScheme === 'light' ? getThemeColor('white', theme) : getThemeColor('dark.7', theme)
          }
        />
      </Box>
      <Box component="section" pos="relative" pt={rem(80)}>
        <Faqs />
        <Title order={2} fz={rem(50)} ta="center" my={rem(80)} fw="800">
          Blog
        </Title>
        <BlogList />
      </Box>
      <Footer />
    </>
  );
}
