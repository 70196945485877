import { useCallback, useEffect, useRef, useState } from 'react';

export function useRefState(initial) {
  const [value, setValue] = useState(initial);
  const valueRef = useRef(value);

  const updateValue = useCallback((newValue) => {
    setValue(newValue);
  }, []);

  // Avoid stale value of presentationRef due to closures.
  useEffect(() => {
    valueRef.current = value;
  }, [value]);

  return [valueRef, value, updateValue];
}
