import { AspectRatio, Box } from '@mantine/core';
import { useInViewport } from '@mantine/hooks';

export function Video({ src }) {
  const { ref, inViewport } = useInViewport();

  return (
    <AspectRatio ref={ref}>
      {inViewport ? (
        <iframe
          src={src}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
          sandbox="allow-scripts allow-same-origin"
          tabIndex={-1}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            border: 0,
          }}
        ></iframe>
      ) : (
        <Box
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            border: 0,
          }}
        ></Box>
      )}
    </AspectRatio>
  );
}
