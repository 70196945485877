import { TextInput, Group } from '@mantine/core';
import { useFocusTrap } from '@mantine/hooks';
import { useForm } from '@mantine/form';
import { useParams } from 'react-router-dom';
import { notifications } from '@mantine/notifications';
import { updateTheme } from '@/requests';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { processError } from '@/helpers';

export function ThemeCardTextInput({ setShowEdit, setShowThemeCardTitleInput, theme }) {
  const focusTrapRef = useFocusTrap();
  const name = `name-${theme.id}`;
  const form = useForm({ mode: 'uncontrolled', initialValues: { [name]: theme.name } });
  let { id } = useParams();
  const queryClient = useQueryClient();
  const updateThemeMutation = useMutation({
    mutationFn: updateTheme,
    onSuccess: (data) => {
      queryClient.setQueryData(['themes'], (oldData: []) => {
        return oldData.map((item) => (item.id === data.id ? data : item));
      });
      form.resetDirty({ [`name-${theme.id}`]: theme.name });
      notifications.show({
        message: 'Successfully updated theme name!',
      });
    },
    onError: processError,
  });
  const handleSubmit = () => {
    setShowEdit(false);
    setShowThemeCardTitleInput(false);
    if (form.isDirty(`name-${theme.id}`)) {
      updateThemeMutation.mutate({
        name: Object.values(form.getValues())[0],
        id: theme.id,
        presentationId: id,
      });
    }
  };

  return (
    <Group ref={focusTrapRef}>
      <TextInput
        w="210px"
        key={form.key(name)}
        {...form.getInputProps(name)}
        variant="unstyled"
        placeholder="Enter theme name"
        onBlur={handleSubmit}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSubmit();
          }
        }}
        styles={{
          input: {
            color: '#242424',
            fontSize: 'var(--text-fz, var(--mantine-font-size-md))',
          },
        }}
      />
    </Group>
  );
}
