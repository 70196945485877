import { useDisclosure } from '@mantine/hooks';
import { Modal, PasswordInput, Title, Button } from '@mantine/core';
import { useForm } from '@mantine/form';

export function PassCode({ setEnteredPassword, handleSubmit }) {
  const [opened, { close }] = useDisclosure(true);
  const form = useForm({
    mode: 'uncontrolled',
    onValuesChange: (values) => {
      setEnteredPassword(values.password);
    },
  });

  return (
    <>
      <Modal
        size="auto"
        opened={opened}
        onClose={close}
        withCloseButton={false}
        closeOnClickOutside={false}
        closeOnEscape={false}
        centered
      >
        <Title order={3} mb="1rem">
          Enter passcode
        </Title>
        <PasswordInput
          size="lg"
          mb="md"
          key={form.key('password')}
          {...form.getInputProps('password')}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSubmit(form.getValues());
            }
          }}
        />
        <Button
          fullWidth
          size="lg"
          variant="outline"
          onClick={() => handleSubmit(form.getValues())}
        >
          Access Presentation
        </Button>
      </Modal>
    </>
  );
}
