import { getUser } from '@/requests';
import {
  Anchor,
  AppShell,
  Burger,
  Button,
  CloseButton,
  Divider,
  Group,
  Image,
  Menu,
  Modal,
  NavLink,
  Switch,
  rem,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import {
  IconHelpCircle,
  IconHomeQuestion,
  IconLogout,
  IconMoon,
  IconPencilQuestion,
  IconSettings,
  IconSlideshow,
  IconSun,
  IconUsers,
} from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import { PricingTables } from '../PricingTables/PricingTables';
import { UpdateToTermsAndPrivacyPolicy } from '../UpdateToTermsAndPrivacy/UpdateToTermsAndPrivacy';
import { Collaborators } from './Collaborators';
import { Presentations } from './Presentations';
import { UserInfo } from './UserInfo';
import { UserSettings } from './UserSettings';

export function Dashboard() {
  const [navbarOpened, { toggle: toggleNavbar, close: closeNavbar }] = useDisclosure();
  const [upgradeModalOpened, { close: closeUpgradeModal, open: openUpgradeModal }] =
    useDisclosure(false);
  const { pathname } = useLocation();
  const theme = useMantineTheme();
  const { colorScheme, setColorScheme } = useMantineColorScheme();

  const { data: user } = useQuery({
    queryKey: ['user'],
    queryFn: getUser,
  });

  return (
    <ModalsProvider>
      <Notifications />
      <UpdateToTermsAndPrivacyPolicy />
      <AppShell
        header={{ height: 60 }}
        navbar={{ width: 300, breakpoint: 'sm', collapsed: { mobile: !navbarOpened } }}
        padding="md"
      >
        <Modal
          opened={upgradeModalOpened}
          onClose={closeUpgradeModal}
          size="auto"
          withCloseButton={false}
          styles={{ body: { padding: 0 } }}
        >
          <PricingTables />
        </Modal>
        <AppShell.Header>
          <Group h="100%" px="md" justify="space-between" gap="0">
            <Group>
              <Burger opened={navbarOpened} onClick={toggleNavbar} size="sm" hiddenFrom="sm" />
              <Anchor href="/">
                <Image src="/images/slides-generator-branding.png" h={30} />
              </Anchor>
            </Group>
            <Group gap={0}>
              <Switch
                checked={colorScheme === 'light'}
                onChange={(event) => setColorScheme(event.currentTarget.checked ? 'light' : 'dark')}
                size="lg"
                onLabel={
                  <IconSun
                    style={{ width: rem(16), height: rem(16) }}
                    stroke={2.5}
                    color={theme.colors.yellow[4]}
                  />
                }
                offLabel={
                  <IconMoon
                    style={{ width: rem(16), height: rem(16) }}
                    stroke={2.5}
                    color={theme.colors.blue[6]}
                  />
                }
              />
            </Group>
          </Group>
        </AppShell.Header>
        <AppShell.Navbar p="md">
          <Group align="center" wrap="nowrap" gap="0" mb="lg">
            <Menu width={280}>
              <Menu.Target>
                <UserInfo name={user?.name} email={user?.email} image={user?.image}></UserInfo>
              </Menu.Target>
              <Menu.Dropdown>
                <form method="post" action="/users/sign_out">
                  <Menu.Item
                    color="red"
                    leftSection={<IconLogout style={{ width: rem(14), height: rem(14) }} />}
                    type="submit"
                    name="submit"
                    fw="600"
                  >
                    <input type="hidden" name="_method" value="delete" />
                    <input
                      type="hidden"
                      name="authenticity_token"
                      value={
                        document
                          .querySelector("meta[name='csrf-token']")
                          ?.getAttribute('content') || ''
                      }
                    />
                    Sign Out
                  </Menu.Item>
                </form>
                <Menu.Item
                  leftSection={<IconSettings style={{ width: rem(14), height: rem(14) }} />}
                  fw="600"
                  component="a"
                  href="/user/settings"
                >
                  Settings
                </Menu.Item>
                {user?.active_subscription?.domain_id && user?.admin && (
                  <Menu.Item
                    leftSection={<IconUsers style={{ width: rem(14), height: rem(14) }} />}
                    fw="600"
                    component="a"
                    href="/user/collaborators"
                  >
                    View Workspace Collaborators
                  </Menu.Item>
                )}
              </Menu.Dropdown>
            </Menu>
            <CloseButton size="lg" hiddenFrom="sm" onClick={closeNavbar} />
          </Group>

          <NavLink
            href="/presentations"
            label="View presentations"
            leftSection={<IconSlideshow size="1rem" stroke={1.5} />}
          />
          <Divider my="md" />
          {user && !user.active_subscription && (
            <Button
              size="lg"
              variant="gradient"
              gradient={{ from: '#fe9920', to: '#ff2a77', deg: 90 }}
              mb="md"
              onClick={openUpgradeModal}
            >
              Upgrade
            </Button>
          )}
          <NavLink
            href="#tally-open=mJ6BOY&tally-emoji-text=👋&tally-emoji-animation=wave"
            label="Contact support"
            leftSection={<IconHelpCircle size="1rem" stroke={1.5} />}
          />
          <NavLink
            href="/help/get-started"
            label="Visit help center"
            target="_blank"
            leftSection={<IconHomeQuestion size="1rem" stroke={1.5} />}
          />
          <NavLink
            href="#tally-open=mYQVLd&tally-emoji-text=👋&tally-emoji-animation=wave"
            label="Leave us feedback"
            leftSection={<IconPencilQuestion size="1rem" stroke={1.5} />}
          />
          <NavLink
            href="/user/settings"
            label="Settings"
            leftSection={<IconSettings size="1rem" stroke={1.5} />}
          />
        </AppShell.Navbar>
        <AppShell.Main>
          {pathname === '/presentations' && <Presentations user={user} />}
          {pathname === '/user/settings' && user && <UserSettings user={user} />}
          {pathname === '/user/collaborators' && user && <Collaborators user={user} />}
        </AppShell.Main>
      </AppShell>
    </ModalsProvider>
  );
}
