import { useScaleToContainer } from '@/hooks/useScaleToContainer';
import { Group, Image } from '@mantine/core';

export function SlideLogo({ presentation, formValues }) {
  const ref = useScaleToContainer({ width: 200 });

  return (
    <Group style={{ position: 'absolute', top: '10px', right: '10px' }}>
      <Image
        ref={ref}
        src={
          presentation.user_subscribed
            ? formValues.logo_url
            : '/images/slides-generator-branding.png'
        }
        w="200px"
        radius="md"
      />
    </Group>
  );
}
