import { UnstyledButton, Group, Avatar, Text, Stack } from '@mantine/core';
import { forwardRef } from 'react';
import { IconChevronDown } from '@tabler/icons-react';

interface UserButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  name: string;
  email: string;
  image: string;
}

export const UserInfo = forwardRef<HTMLButtonElement, UserButtonProps>(
  ({ name, email, image, ...others }: UserButtonProps, ref) => (
    <UnstyledButton ref={ref} {...others} flex="1" style={{ overflow: 'hidden' }}>
      <Group wrap="nowrap">
        <Avatar src={image} alt={name} color="indigo" />
        <Stack gap={5}>
          <Text size="sm" fw={700} style={{ lineHeight: 1 }}>
            {name}
          </Text>
          <Text c="dimmed" size="xs" style={{ lineHeight: 1 }} truncate="end">
            {email}
          </Text>
        </Stack>
        <IconChevronDown size="1rem" />
      </Group>
    </UnstyledButton>
  )
);
