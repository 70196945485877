import { Anchor, CloseButton, Group, Paper, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

export function UpdateToTermsAndPrivacyPolicy() {
  const [opened, { close }] = useDisclosure(true);

  if (!opened) return;

  return (
    <Paper
      withBorder
      p="lg"
      radius="md"
      shadow="md"
      pos="fixed"
      style={{ zIndex: 102, bottom: 0, left: 0, right: 0 }}
    >
      <Group justify="space-between" mb="xs">
        <Text fz="md" fw={500} flex="1">
          Update to Terms of Use and Privacy Policy
        </Text>
        <CloseButton mr={-9} mt={-9} onClick={close} />
      </Group>
      <Text c="dimmed" fz="sm">
        We have updated our <Anchor inherit href="/terms_of_use">Terms of Use</Anchor> and{' '}
        <Anchor inherit href="/privacy_policy">Privacy Policy</Anchor>. You can learn more about these
        changes <Anchor inherit href="/blog/changes-to-our-terms-and-privacy-policy">here</Anchor>.
      </Text>
    </Paper>
  );
}
