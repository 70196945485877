import { formatDate } from '@/helpers';
import {
  Avatar,
  Badge,
  Card,
  Container,
  Group,
  Image,
  SimpleGrid,
  Text,
  Title,
  UnstyledButton,
  rem,
  useMantineTheme,
} from '@mantine/core';
import { useSearchParams } from 'react-router-dom';
import posts from './posts.json';

export function BlogList() {
  const theme = useMantineTheme();
  let [searchParams] = useSearchParams();

  return (
    <Container size="lg">
      <SimpleGrid cols={{ base: 1, md: 2, lg: 3 }}>
        {posts
          .filter((post) =>
            searchParams.get('tag') ? post.tags.includes(searchParams.get('tag')) : post
          )
          .filter((post) =>
            searchParams.get('author') ? post.author.includes(searchParams.get('author')) : post
          )
          .map((post) => (
            <UnstyledButton component="a" href={`/blog/${post.slug}`}>
              <Card withBorder padding="lg" radius="md">
                <Card.Section mb="sm">
                  <Image src={post.image} alt={post.title} height={180} />
                </Card.Section>

                <Group gap="2px">
                  {post.tags.map((tag) => (
                    <UnstyledButton component="a" href={`/blog?tag=${tag}`}>
                      <Badge
                        w="fit-content"
                        variant="light"
                        size="sm"
                        styles={{
                          root: {
                            '&:hover': {
                              backgroundColor: '#ddd',
                            },
                          },
                        }}
                      >
                        {tag}
                      </Badge>
                    </UnstyledButton>
                  ))}
                </Group>

                <Text fz={rem(20)} fw={700} mt="xs">
                  {post.title}
                </Text>

                <Group mt="lg">
                  <Avatar src={post.authorImg} radius="xl" />
                  <UnstyledButton component="a" href={`/blog?author=${post.author}`}>
                    <Text fw={500}>{post.author}</Text>
                  </UnstyledButton>
                  <Text fz="xs" c="dimmed">
                    {formatDate(post.publishedAt)}
                  </Text>
                </Group>
              </Card>
            </UnstyledButton>
          ))}
      </SimpleGrid>
    </Container>
  );
}
