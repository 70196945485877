import { createSlide } from '@/requests';
import { ActionIcon, ColorPicker, Group, Paper, TextInput, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import {
  IconBorderBottomPlus,
  IconBorderTopPlus,
  IconClick,
  IconEraser,
  IconLetterT,
  IconPencil,
  IconX,
} from '@tabler/icons-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { Rnd } from 'react-rnd';
import { useParams, useSearchParams } from 'react-router-dom';

function ColorPickerComponent({ color, setColor, closeColorPicker }) {
  return (
    <Paper p="md" pt="xl">
      <Group pos="absolute" gap="0" style={{ top: 5, right: 5 }}>
        <Tooltip label="Close color picker">
          <ActionIcon
            onClick={closeColorPicker}
            radius="sm"
            size="sm"
            variant="subtle"
            aria-label="Close"
            c="bright"
            className="cancel"
          >
            <IconX style={{ width: '80%', height: '80%' }} stroke={1.5} />
          </ActionIcon>
        </Tooltip>
      </Group>
      <TextInput
        variant="filled"
        mb="sm"
        value={color}
        onChange={(e) => setColor(e.currentTarget.value)}
      />
      <ColorPicker
        value={color}
        onChange={setColor}
        format="hex"
        swatches={[
          '#2e2e2e',
          '#868e96',
          '#fa5252',
          '#e64980',
          '#be4bdb',
          '#7950f2',
          '#4c6ef5',
          '#228be6',
          '#15aabf',
          '#12b886',
          '#40c057',
          '#82c91e',
          '#fab005',
          '#fd7e14',
        ]}
      />
    </Paper>
  );
}

export function AnnotationsToolbar({ settings, updateSettings, closeAnnotations }) {
  const [showColorPicker, { open: openColorPicker, close: closeColorPicker }] = useDisclosure(true);
  const { mode, penColor, textColor } = settings;
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const [mainToolbarPosition, setMainToolbarPosition] = useState({ x: 50, y: 5 });
  const [secondaryToolbarPosition, setSecondaryToolbarPosition] = useState({ x: 50, y: 75 });
  const createSlideMutation = useMutation({
    mutationFn: createSlide,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['presentation', id] });
      notifications.show({
        message: 'New slide added successfully!',
      });
      window.emblaInstance.scrollTo(data.order);
    },
  });

  const addSlideAt = (index) => {
    createSlideMutation.mutate({ presentation_id: id, index });
  };

  return (
    <>
      <Rnd
        enableResizing={false}
        style={{ zIndex: 1 }}
        position={mainToolbarPosition}
        onDragStop={(e, d) => {
          setMainToolbarPosition({ x: d.x, y: d.y });
        }}
        cancel=".cancel"
      >
        <Paper p="3px">
          <Group justify="end">
            <Tooltip label="Close annotations">
              <ActionIcon
                onClick={closeAnnotations}
                radius="sm"
                size="sm"
                variant="subtle"
                aria-label="Close"
                c="bright"
                className="cancel"
              >
                <IconX style={{ width: '80%', height: '80%' }} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Group>
          <Group gap="3px">
            <Tooltip label="Selection tool">
              <ActionIcon
                size="lg"
                variant={mode === 'select' ? 'filled' : 'subtle'}
                aria-label="Select"
                onClick={() => updateSettings('mode', 'select')}
                className="cancel"
              >
                <IconClick style={{ width: '70%', height: '70%' }} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Pen tool">
              <ActionIcon
                size="lg"
                variant={mode === 'pen' ? 'filled' : 'subtle'}
                aria-label="Pencil"
                onClick={() => {
                  updateSettings('mode', 'pen');
                  openColorPicker();
                }}
                className="cancel"
              >
                <IconPencil style={{ width: '70%', height: '70%' }} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Eraser tool">
              <ActionIcon
                size="lg"
                variant={mode === 'erase' ? 'filled' : 'subtle'}
                aria-label="Erase"
                onClick={() => updateSettings('mode', 'erase')}
                className="cancel"
              >
                <IconEraser style={{ width: '70%', height: '70%' }} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Text tool">
              <ActionIcon
                size="lg"
                variant={mode === 'text' ? 'filled' : 'subtle'}
                aria-label="Add text"
                onClick={() => {
                  updateSettings('mode', 'text');
                  openColorPicker();
                }}
                className="cancel"
              >
                <IconLetterT style={{ width: '70%', height: '70%' }} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Add blank page after">
              <ActionIcon
                size="lg"
                variant="subtle"
                aria-label="Add blank page after"
                onClick={() => addSlideAt(parseInt(searchParams.get('slide')) + 1)}
                className="cancel"
              >
                <IconBorderBottomPlus style={{ width: '70%', height: '70%' }} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Add blank page before">
              <ActionIcon
                size="lg"
                variant="subtle"
                aria-label="Add blank page above"
                onClick={() => addSlideAt(parseInt(searchParams.get('slide')))}
                className="cancel"
              >
                <IconBorderTopPlus style={{ width: '70%', height: '70%' }} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Group>
        </Paper>
      </Rnd>

      {showColorPicker && (
        <Rnd
          enableResizing={false}
          style={{ zIndex: 1 }}
          position={secondaryToolbarPosition}
          onDragStop={(e, d) => {
            setSecondaryToolbarPosition({ x: d.x, y: d.y });
          }}
          cancel=".cancel"
        >
          {mode === 'pen' && (
            <ColorPickerComponent
              closeColorPicker={closeColorPicker}
              color={penColor}
              setColor={(value) => updateSettings('penColor', value)}
            />
          )}
          {mode === 'text' && (
            <ColorPickerComponent
              closeColorPicker={closeColorPicker}
              color={textColor}
              setColor={(value) => updateSettings('textColor', value)}
            />
          )}
        </Rnd>
      )}
    </>
  );
}
