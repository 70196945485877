import { useRefState } from '@/hooks/useRefState';
import { updateSlide } from '@/requests';
import { Box, Textarea } from '@mantine/core';
import { useClickOutside, useHotkeys } from '@mantine/hooks';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Layer, Line, Stage, Text, Transformer } from 'react-konva';
import { Html } from 'react-konva-utils';
import { useParams } from 'react-router-dom';

const CanvasText = ({ textbox, selectedId, setSelectedId, onChange }) => {
  const textboxRef = useRef();
  const transformerRef = useRef();
  const [isEditing, setIsEditing] = useState(false);
  const [textInputValue, setTextInputValue] = useState(textbox.text);
  const textInputRef = useClickOutside(() => {
    onChange({
      ...textbox,
      text: textInputValue,
    });
    setIsEditing(false);
  });
  const isSelected = selectedId === textbox.id;
  const setSelected = () => setSelectedId(textbox.id);

  useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      transformerRef.current?.nodes([textboxRef.current]);
      transformerRef.current?.getLayer().batchDraw();
    }
  }, [selectedId]);

  return isEditing ? (
    <Html groupProps={{ x: textbox.x, y: textbox.y }}>
      <Textarea
        autosize
        styles={{
          input: {
            color: textbox.color,
            lineHeight: 1,
            fontSize: textbox.fontSize,
            fontWeight: textbox.fontStyle === 'bold' ? '700' : '400',
          },
        }}
        variant="unstyled"
        ref={textInputRef}
        value={textInputValue}
        onChange={(e) => {
          e.stopPropagation();
          setTextInputValue(e.currentTarget.value);
        }}
      />
    </Html>
  ) : (
    <>
      <Text
        fill={textbox.color}
        fontSize={textbox.fontSize}
        text={textInputValue}
        fontStyle={textbox.fontStyle}
        onClick={setSelected}
        onTap={setSelected}
        onDblClick={() => setIsEditing(true)}
        onDblTap={() => setIsEditing(true)}
        ref={textboxRef}
        draggable
        onDragEnd={(e) => {
          onChange({
            ...textbox,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        onTransform={() => {
          const textNode = textboxRef.current;
          const newWidth = textNode.width() * textNode.scaleX();
          textNode.setAttrs({
            width: newWidth,
            scaleX: 1,
          });
        }}
        onTransformEnd={(e) => {
          const node = textboxRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          onChange({
            ...textbox,
            x: node.x(),
            y: node.y(),
            width: Math.max(5, node.width() * scaleX),
            height: Math.max(node.height() * scaleY),
          });
        }}
        {...textbox}
      />
      {isSelected && (
        <Transformer
          ref={transformerRef}
          rotateEnabled={false}
          flipEnabled={false}
          enabledAnchors={['middle-left', 'middle-right']}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (Math.abs(newBox.width) < 5 || Math.abs(newBox.height) < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  );
};

export function Annotations({
  slide,
  updateFunctionRef,
  setUpdateFunction,
  showAnnotations,
  annotationSettingsRef,
  updateAnnotationSettings,
}) {
  const { id } = useParams();
  const boxRef = useRef(null);
  const documentRef = useRef(document);
  const [lines, setLines] = useState(slide.annotations?.lines || []);
  const [textboxes, setTextboxes] = useState(slide.annotations?.textboxes || []);
  const [history, setHistory] = useState([
    { lines: slide.annotations?.lines, textboxes: slide.annotations?.textboxes },
  ]);
  const [historyIndex, setHistoryIndex] = useState(0);
  const [redoStack, setRedoStack] = useState([]);
  const [selectedIdRef, selectedId, setSelectedId] = useRefState('');
  const isDrawing = useRef(false);
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [isMouseDown, setIsMouseDown] = useState(false);

  const updateSlideMutation = useMutation({
    mutationFn: updateSlide,
    onSuccess: async () => {
      setUpdateFunction(null);
    },
  });
  const updateAnnotations = (slideAnnotations) => {
    if (updateFunctionRef.current) {
      clearTimeout(updateFunctionRef.current);
    }
    const updateFunction = setTimeout(
      () =>
        updateSlideMutation.mutate({
          presentation_id: id,
          id: slide.id,
          annotations: slideAnnotations,
        }),
      3000
    );
    setUpdateFunction(updateFunction);
  };

  const handleDeleteTextbox = useCallback((e) => {
    if (e.key === 'Delete' || e.key === 'Backspace') {
      setTextboxes((prevState) => {
        const updatedTextboxes = prevState.filter(
          (textbox) => textbox.id !== selectedIdRef.current
        );
        updateAnnotations({ textboxes: updatedTextboxes });
        return updatedTextboxes;
      });
    }
  }, []);

  const appendHistory = (data) => {
    const newEntry = data;
    const newHistory = history.slice(0, historyIndex + 1);

    setHistory([...newHistory, newEntry]);
    setHistoryIndex(historyIndex + 1);
    setRedoStack([]);
  };

  const undo = () => {
    if (historyIndex > 0) {
      setRedoStack([...redoStack, history[historyIndex]]);
      setHistoryIndex(historyIndex - 1);
      const updatedLines = history[historyIndex - 1].lines;
      const updatedTextboxes = history[historyIndex - 1].textboxes;
      setLines(updatedLines);
      setTextboxes(updatedTextboxes);
      updateAnnotations({ lines: updatedLines, textboxes: updatedTextboxes });
    }
  };

  const redo = () => {
    if (redoStack.length > 0) {
      const lastRedo = redoStack[redoStack.length - 1];

      setRedoStack(redoStack.slice(0, -1));
      setHistoryIndex(historyIndex + 1);
      setHistory([...history, lastRedo]);
      setLines(lastRedo.lines);
      setTextboxes(lastRedo.textboxes);
      updateAnnotations({ lines: lastRedo.lines, textboxes: lastRedo.textboxes });
    }
  };

  useHotkeys([
    ['mod+Z', () => undo()],
    ['mod+Shift+Z', () => redo()],
    ['mod+Y', () => redo()],
  ]);

  useEffect(() => {
    documentRef.current.addEventListener('keydown', handleDeleteTextbox);
    return () => documentRef.current.removeEventListener('keydown', handleDeleteTextbox);
  }, [handleDeleteTextbox]);

  useEffect(() => {
    setUpdateFunction(null);
  }, [slide]);

  // Trigger re-render when new slide is added.
  useEffect(() => {
    setLines(slide.annotations?.lines || []);
    setTextboxes(slide.annotations?.textboxes || []);
  }, [slide.annotations]);

  useEffect(() => {
    if (boxRef.current) {
      setDimensions({
        width: boxRef.current.offsetWidth,
        height: boxRef.current.offsetHeight,
      });
    }
  }, [boxRef.current?.offsetHeight]);

  const handleClick = (e) => {
    const pos = e.target.getStage().getPointerPosition();

    if (annotationSettingsRef.current.mode === 'text') {
      const newTextboxes = [
        ...textboxes,
        {
          id: crypto.randomUUID(),
          text: 'Text',
          x: pos.x,
          y: pos.y,
          fontSize: 60,
          fontStyle: 'bold',
          color: annotationSettingsRef.current.textColor,
        },
      ];
      setTextboxes(newTextboxes);
      updateAnnotations({
        textboxes: newTextboxes,
      });
      updateAnnotationSettings('mode', 'select');
      appendHistory({ lines, textboxes: newTextboxes });
    } else {
      const clickedOnEmpty = e.target === e.target.getStage();
      if (clickedOnEmpty) {
        setSelectedId(null);
      }
    }
  };

  const handleMouseDown = (e) => {
    setIsMouseDown(true);
    const pos = e.target.getStage().getPointerPosition();

    if (annotationSettingsRef.current.mode === 'pen') {
      isDrawing.current = true;
      setLines([
        ...lines,
        {
          tool: annotationSettingsRef.current.mode,
          color: annotationSettingsRef.current.penColor,
          points: [pos.x, pos.y],
        },
      ]);
    } else if (annotationSettingsRef.current.mode === 'eraser') {
    }
  };

  const handleMouseMove = (e) => {
    // no drawing - skipping
    if (!isDrawing.current) {
      return;
    }
    const stage = e.target.getStage();
    const point = stage.getPointerPosition();
    let lastLine = lines[lines.length - 1];
    // add point
    lastLine.points = lastLine.points.concat([point.x, point.y]);

    // replace last
    lines.splice(lines.length - 1, 1, lastLine);
    setLines(lines.concat());
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
    isDrawing.current = false;
    if (annotationSettingsRef.current.mode === 'pen') {
      updateAnnotations({
        lines,
      });
      appendHistory({ lines, textboxes });
    }
  };

  return (
    <Box
      pos="absolute"
      w="100%"
      h="100%"
      style={{ top: 0, left: 0, pointerEvents: showAnnotations ? 'auto' : 'none' }}
      ref={boxRef}
    >
      <Stage
        width={dimensions.width}
        height={dimensions.height}
        onClick={handleClick}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onTouchStart={handleMouseDown}
        onTouchMove={handleMouseMove}
        onTouchEnd={handleMouseUp}
      >
        <Layer>
          {lines.map((line, i) => (
            <Line
              key={i}
              points={line.points}
              stroke={line.color}
              strokeWidth={5}
              tension={0.5}
              lineCap="round"
              lineJoin="round"
              globalCompositeOperation="source-over"
              onClick={() => {
                if (annotationSettingsRef.current.mode !== 'erase') return;
                setLines((prevLines) => {
                  const updatedLines = prevLines.filter((_, index) => index !== i);
                  updateAnnotations({ lines: updatedLines });
                  appendHistory({ lines: updatedLines, textboxes });
                  return updatedLines;
                });
              }}
              onMouseEnter={() => {
                if (isMouseDown) {
                  if (annotationSettingsRef.current.mode !== 'erase') return;
                  setLines((prevLines) => {
                    const updatedLines = prevLines.filter((_, index) => index !== i);
                    updateAnnotations({ lines: updatedLines });
                    appendHistory({ lines: updatedLines, textboxes });
                    return updatedLines;
                  });
                }
              }}
            />
          ))}
          {textboxes.map((textbox, i) => (
            <CanvasText
              key={i}
              textbox={textbox}
              selectedId={selectedId}
              setSelectedId={setSelectedId}
              onChange={(newAttrs) => {
                textboxes[i] = newAttrs;
                appendHistory({ lines, textboxes });
                updateAnnotations({ textboxes: textboxes });
              }}
            />
          ))}
        </Layer>
      </Stage>
    </Box>
  );
}
