import { Anchor, Button, Group, Image, Paper, Text, Title } from '@mantine/core';
import { IconBrandGoogleFilled } from '@tabler/icons-react';
import { UpdateToTermsAndPrivacyPolicy } from '../UpdateToTermsAndPrivacy/UpdateToTermsAndPrivacy';
import classes from './SignIn.module.css';

export function SignIn() {
  return (
    <div className={classes.wrapper}>
      <UpdateToTermsAndPrivacyPolicy />
      <Paper className={classes.form} radius={0} p={30}>
        <Anchor href="/" w={250}>
          <Image src="/images/slides-generator-branding.png" w={250} />
        </Anchor>
        <Title order={2} mt={50} mb="md" fz={40} fw="800">
          Sign in
        </Title>
        <form method="post" action="/users/auth/google_oauth2">
          <input
            type="hidden"
            name="authenticity_token"
            value={document.querySelector("meta[name='csrf-token']").getAttribute('content')}
          />
          <Group grow mb="md">
            <Button
              size="lg"
              radius="md"
              type="submit"
              name="submit"
              variant="gradient"
              gradient={{ from: '#fe9920', to: '#ff2a77', deg: 90 }}
              leftSection={<IconBrandGoogleFilled />}
            >
              Continue with Google
            </Button>
          </Group>
        </form>
        <Text mb="md" size="lg">
          Don&apos;t have an account?{' '}
          <Anchor href="/users/sign_up" fw={700}>
            Sign up
          </Anchor>
        </Text>

        <Text size="sm">
          By signing in to or signing up for Slides Generator, you acknowledge that you agree to
          Slides Generator's{' '}
          <Anchor href="/terms_of_use" fw={700}>
            Terms of Use
          </Anchor>{' '}
          and{' '}
          <Anchor href="/privacy_policy" fw={700}>
            Privacy Policy
          </Anchor>
          .
        </Text>
      </Paper>
    </div>
  );
}
