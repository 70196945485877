import { loadFont, normalize } from '@/helpers';
import { getFonts, getThemes } from '@/requests';
import { Badge, Center, SegmentedControl, Stack } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ThemeCards } from './ThemeCards';

const PRESET_THEMES = [
  {
    name: 'Monochrome',
    styles: {
      background_color: '#FFF',
      background_color_type: 'solid',
      background_color_gradient_from: '#2f4f4f',
      background_color_gradient_to: '#708090',
      background_color_gradient_angle: 120,
      body_alignment: 'left',
      body_font_size: 40,
      body_font_color: '#242424',
      body_font_family: 'poppins',
      body_font_weight: '400',
      body_italic: false,
      body_underlined: false,
      body_strikethrough: false,
      all_headings_alignment: 'left',
      all_headings_font_size: 80,
      all_headings_font_color: '#242424',
      all_headings_font_family: 'poppins',
      all_headings_font_weight: '700',
      all_headings_italic: false,
      all_headings_underlined: false,
      all_headings_strikethrough: false,
      h1_alignment: 'left',
      h1_font_size: 80,
      h1_font_color: '#242424',
      h1_font_family: 'poppins',
      h1_font_weight: '700',
      h1_italic: false,
      h1_underlined: false,
      h1_strikethrough: false,
      h2_alignment: 'left',
      h2_font_size: 50,
      h2_font_color: '#242424',
      h2_font_family: 'poppins',
      h2_font_weight: '700',
      h2_italic: false,
      h2_underlined: false,
      h2_strikethrough: false,
      h3_alignment: 'left',
      h3_font_size: 40,
      h3_font_color: '#242424',
      h3_font_family: 'poppins',
      h3_font_weight: '400',
      h3_italic: false,
      h3_underlined: false,
      h3_strikethrough: false,
    },
  },
  {
    name: 'Dark',
    styles: {
      background_color: '#242424',
      background_color_type: 'solid',
      background_color_gradient_from: '#242424',
      background_color_gradient_to: '#242424',
      background_color_gradient_angle: 120,
      body_alignment: 'left',
      body_font_size: 40,
      body_font_color: '#FFFFFF',
      body_font_family: 'poppins',
      body_font_weight: '400',
      body_italic: false,
      body_underlined: false,
      body_strikethrough: false,
      all_headings_alignment: 'left',
      all_headings_font_size: 80,
      all_headings_font_color: '#FAD12B',
      all_headings_font_family: 'poppins',
      all_headings_font_weight: '700',
      all_headings_italic: false,
      all_headings_underlined: false,
      all_headings_strikethrough: false,
      h1_alignment: 'left',
      h1_font_size: 80,
      h1_font_color: '#FAD12B',
      h1_font_family: 'poppins',
      h1_font_weight: '700',
      h1_italic: false,
      h1_underlined: false,
      h1_strikethrough: false,
      h2_alignment: 'left',
      h2_font_size: 50,
      h2_font_color: '#FAD12B',
      h2_font_family: 'poppins',
      h2_font_weight: '700',
      h2_italic: false,
      h2_underlined: false,
      h2_strikethrough: false,
      h3_alignment: 'left',
      h3_font_size: 40,
      h3_font_color: '#FAD12B',
      h3_font_family: 'poppins',
      h3_font_weight: '400',
      h3_italic: false,
      h3_underlined: false,
      h3_strikethrough: false,
    },
  },
  {
    name: 'Rosettes and Cream',
    styles: {
      background_color: '#00aaff',
      background_color_type: 'gradient',
      background_color_gradient_from: '#EF7C8E',
      background_color_gradient_to: '#D8A7B1',
      background_color_gradient_angle: 120,
      body_alignment: 'left',
      body_font_size: 40,
      body_font_color: '#FAE8E0',
      body_font_family: 'roboto',
      body_font_weight: '400',
      body_italic: false,
      body_underlined: false,
      body_strikethrough: false,
      all_headings_alignment: 'left',
      all_headings_font_size: 80,
      all_headings_font_color: '#B6E2D3',
      all_headings_font_family: 'poppins',
      all_headings_font_weight: '700',
      all_headings_italic: false,
      all_headings_underlined: false,
      all_headings_strikethrough: false,
      h1_alignment: 'left',
      h1_font_size: 80,
      h1_font_color: '#B6E2D3',
      h1_font_family: 'poppins',
      h1_font_weight: '700',
      h1_italic: false,
      h1_underlined: false,
      h1_strikethrough: false,
      h2_alignment: 'left',
      h2_font_size: 50,
      h2_font_color: '#B6E2D3',
      h2_font_family: 'poppins',
      h2_font_weight: '700',
      h2_italic: false,
      h2_underlined: false,
      h2_strikethrough: false,
      h3_alignment: 'left',
      h3_font_size: 40,
      h3_font_color: '#B6E2D3',
      h3_font_family: 'poppins',
      h3_font_weight: '400',
      h3_italic: false,
      h3_underlined: false,
      h3_strikethrough: false,
    },
  },
  {
    name: 'Summer Splash',
    styles: {
      background_color: '#05445E',
      background_color_type: 'gradient',
      background_color_gradient_from: '#05445E',
      background_color_gradient_to: '#189AB4',
      background_color_gradient_angle: 120,
      body_alignment: 'left',
      body_font_size: 40,
      body_font_color: '#D4F1F4',
      body_font_family: 'lato',
      body_font_weight: '400',
      body_italic: false,
      body_underlined: false,
      body_strikethrough: false,
      all_headings_alignment: 'left',
      all_headings_font_size: 80,
      all_headings_font_color: '#75E6DA',
      all_headings_font_family: 'montserrat',
      all_headings_font_weight: '700',
      all_headings_italic: false,
      all_headings_underlined: false,
      all_headings_strikethrough: false,
      h1_alignment: 'left',
      h1_font_size: 80,
      h1_font_color: '#75E6DA',
      h1_font_family: 'montserrat',
      h1_font_weight: '700',
      h1_italic: false,
      h1_underlined: false,
      h1_strikethrough: false,
      h2_alignment: 'left',
      h2_font_size: 50,
      h2_font_color: '#75E6DA',
      h2_font_family: 'montserrat',
      h2_font_weight: '700',
      h2_italic: false,
      h2_underlined: false,
      h2_strikethrough: false,
      h3_alignment: 'left',
      h3_font_size: 40,
      h3_font_color: '#75E6DA',
      h3_font_family: 'montserrat',
      h3_font_weight: '400',
      h3_italic: false,
      h3_underlined: false,
      h3_strikethrough: false,
    },
  },
  {
    name: 'Green Lemons',
    styles: {
      background_color: '#013A20',
      background_color_type: 'gradient',
      background_color_gradient_from: '#013A20',
      background_color_gradient_to: '#478C5C',
      background_color_gradient_angle: 120,
      body_alignment: 'left',
      body_font_size: 40,
      body_font_color: '#CDD193',
      body_font_family: 'opensans',
      body_font_weight: '400',
      body_italic: false,
      body_underlined: false,
      body_strikethrough: false,
      all_headings_alignment: 'left',
      all_headings_font_size: 80,
      all_headings_font_color: '#BACC81',
      all_headings_font_family: 'oswald',
      all_headings_font_weight: '700',
      all_headings_italic: false,
      all_headings_underlined: false,
      all_headings_strikethrough: false,
      h1_alignment: 'left',
      h1_font_size: 80,
      h1_font_color: '#BACC81',
      h1_font_family: 'oswald',
      h1_font_weight: '700',
      h1_italic: false,
      h1_underlined: false,
      h1_strikethrough: false,
      h2_alignment: 'left',
      h2_font_size: 50,
      h2_font_color: '#BACC81',
      h2_font_family: 'oswald',
      h2_font_weight: '700',
      h2_italic: false,
      h2_underlined: false,
      h2_strikethrough: false,
      h3_alignment: 'left',
      h3_font_size: 40,
      h3_font_color: '#BACC81',
      h3_font_family: 'oswald',
      h3_font_weight: '400',
      h3_italic: false,
      h3_underlined: false,
      h3_strikethrough: false,
    },
  },
  {
    name: 'Cool Cream Strawberry',
    styles: {
      background_color: '#E7625F',
      background_color_type: 'gradient',
      background_color_gradient_from: '#E7625F',
      background_color_gradient_to: '#C85250',
      background_color_gradient_angle: 120,
      body_alignment: 'left',
      body_font_size: 40,
      body_font_color: '#E9EAE0',
      body_font_family: 'nunito',
      body_font_weight: '400',
      body_italic: false,
      body_underlined: false,
      body_strikethrough: false,
      all_headings_alignment: 'left',
      all_headings_font_size: 80,
      all_headings_font_color: '#F7BEC0',
      all_headings_font_family: 'merriweather',
      all_headings_font_weight: '700',
      all_headings_italic: false,
      all_headings_underlined: false,
      all_headings_strikethrough: false,
      h1_alignment: 'left',
      h1_font_size: 80,
      h1_font_color: '#F7BEC0',
      h1_font_family: 'merriweather',
      h1_font_weight: '700',
      h1_italic: false,
      h1_underlined: false,
      h1_strikethrough: false,
      h2_alignment: 'left',
      h2_font_size: 50,
      h2_font_color: '#F7BEC0',
      h2_font_family: 'merriweather',
      h2_font_weight: '700',
      h2_italic: false,
      h2_underlined: false,
      h2_strikethrough: false,
      h3_alignment: 'left',
      h3_font_size: 40,
      h3_font_color: '#F7BEC0',
      h3_font_family: 'merriweather',
      h3_font_weight: '400',
      h3_italic: false,
      h3_underlined: false,
      h3_strikethrough: false,
    },
  },
  {
    name: 'Bubblegum Sweetness',
    styles: {
      background_color: '#0C6170',
      background_color_type: 'gradient',
      background_color_gradient_from: '#0C6170',
      background_color_gradient_to: '#37BEB0',
      background_color_gradient_angle: 120,
      body_alignment: 'left',
      body_font_size: 40,
      body_font_color: '#DBF5F0',
      body_font_family: 'pt sans',
      body_font_weight: '400',
      body_italic: false,
      body_underlined: false,
      body_strikethrough: false,
      all_headings_alignment: 'left',
      all_headings_font_size: 80,
      all_headings_font_color: '#A4E5E0',
      all_headings_font_family: 'raleway',
      all_headings_font_weight: '700',
      all_headings_italic: false,
      all_headings_underlined: false,
      all_headings_strikethrough: false,
      h1_alignment: 'left',
      h1_font_size: 80,
      h1_font_color: '#A4E5E0',
      h1_font_family: 'raleway',
      h1_font_weight: '700',
      h1_italic: false,
      h1_underlined: false,
      h1_strikethrough: false,
      h2_alignment: 'left',
      h2_font_size: 50,
      h2_font_color: '#A4E5E0',
      h2_font_family: 'raleway',
      h2_font_weight: '700',
      h2_italic: false,
      h2_underlined: false,
      h2_strikethrough: false,
      h3_alignment: 'left',
      h3_font_size: 40,
      h3_font_color: '#A4E5E0',
      h3_font_family: 'raleway',
      h3_font_weight: '400',
      h3_italic: false,
      h3_underlined: false,
      h3_strikethrough: false,
    },
  },
  {
    name: 'Emerald Entrance',
    styles: {
      background_color: '#B68D40',
      background_color_type: 'gradient',
      background_color_gradient_from: '#B68D40',
      background_color_gradient_to: '#D6AD60',
      background_color_gradient_angle: 120,
      body_alignment: 'left',
      body_font_size: 40,
      body_font_color: '#F4EBD0',
      body_font_family: 'sourcesanspro',
      body_font_weight: '400',
      body_italic: false,
      body_underlined: false,
      body_strikethrough: false,
      all_headings_alignment: 'left',
      all_headings_font_size: 80,
      all_headings_font_color: '#122620',
      all_headings_font_family: 'playfairdisplay',
      all_headings_font_weight: '700',
      all_headings_italic: false,
      all_headings_underlined: false,
      all_headings_strikethrough: false,
      h1_alignment: 'left',
      h1_font_size: 80,
      h1_font_color: '#122620',
      h1_font_family: 'playfairdisplay',
      h1_font_weight: '700',
      h1_italic: false,
      h1_underlined: false,
      h1_strikethrough: false,
      h2_alignment: 'left',
      h2_font_size: 50,
      h2_font_color: '#122620',
      h2_font_family: 'playfairdisplay',
      h2_font_weight: '700',
      h2_italic: false,
      h2_underlined: false,
      h2_strikethrough: false,
      h3_alignment: 'left',
      h3_font_size: 40,
      h3_font_color: '#122620',
      h3_font_family: 'playfairdisplay',
      h3_font_weight: '400',
      h3_italic: false,
      h3_underlined: false,
      h3_strikethrough: false,
    },
  },
  {
    name: 'Pasture of Dreams',
    styles: {
      background_color: '#211522',
      background_color_type: 'gradient',
      background_color_gradient_from: '#211522',
      background_color_gradient_to: '#613659',
      background_color_gradient_angle: 120,
      body_alignment: 'left',
      body_font_size: 40,
      body_font_color: '#D3B1C2',
      body_font_family: 'quicksand',
      body_font_weight: '400',
      body_italic: false,
      body_underlined: false,
      body_strikethrough: false,
      all_headings_alignment: 'left',
      all_headings_font_size: 80,
      all_headings_font_color: '#C197D2',
      all_headings_font_family: 'librebaskerville',
      all_headings_font_weight: '700',
      all_headings_italic: false,
      all_headings_underlined: false,
      all_headings_strikethrough: false,
      h1_alignment: 'left',
      h1_font_size: 80,
      h1_font_color: '#C197D2',
      h1_font_family: 'librebaskerville',
      h1_font_weight: '700',
      h1_italic: false,
      h1_underlined: false,
      h1_strikethrough: false,
      h2_alignment: 'left',
      h2_font_size: 50,
      h2_font_color: '#C197D2',
      h2_font_family: 'librebaskerville',
      h2_font_weight: '700',
      h2_italic: false,
      h2_underlined: false,
      h2_strikethrough: false,
      h3_alignment: 'left',
      h3_font_size: 40,
      h3_font_color: '#C197D2',
      h3_font_family: 'librebaskerville',
      h3_font_weight: '400',
      h3_italic: false,
      h3_underlined: false,
      h3_strikethrough: false,
    },
  },
  {
    name: 'Trees in Bloom',
    styles: {
      background_color: '#391306',
      background_color_type: 'gradient',
      background_color_gradient_from: '#391306',
      background_color_gradient_to: '#AA1945',
      background_color_gradient_angle: 120,
      body_alignment: 'left',
      body_font_size: 40,
      body_font_color: '#F1CED4',
      body_font_family: 'cabin',
      body_font_weight: '400',
      body_italic: false,
      body_underlined: false,
      body_strikethrough: false,
      all_headings_alignment: 'left',
      all_headings_font_size: 80,
      all_headings_font_color: '#F9CCD3',
      all_headings_font_family: 'alegreya',
      all_headings_font_weight: '700',
      all_headings_italic: false,
      all_headings_underlined: false,
      all_headings_strikethrough: false,
      h1_alignment: 'left',
      h1_font_size: 80,
      h1_font_color: '#F9CCD3',
      h1_font_family: 'alegreya',
      h1_font_weight: '700',
      h1_italic: false,
      h1_underlined: false,
      h1_strikethrough: false,
      h2_alignment: 'left',
      h2_font_size: 50,
      h2_font_color: '#F9CCD3',
      h2_font_family: 'alegreya',
      h2_font_weight: '700',
      h2_italic: false,
      h2_underlined: false,
      h2_strikethrough: false,
      h3_alignment: 'left',
      h3_font_size: 40,
      h3_font_color: '#F9CCD3',
      h3_font_family: 'alegreya',
      h3_font_weight: '400',
      h3_italic: false,
      h3_underlined: false,
      h3_strikethrough: false,
    },
  },
  {
    name: 'Warm Sunset',
    styles: {
      background_color: '#FD7F20',
      background_color_type: 'gradient',
      background_color_gradient_from: '#FD7F20',
      background_color_gradient_to: '#FC2E20',
      background_color_gradient_angle: 120,
      body_alignment: 'left',
      body_font_size: 40,
      body_font_color: '#FDB750',
      body_font_family: 'dosis',
      body_font_weight: '400',
      body_italic: false,
      body_underlined: false,
      body_strikethrough: false,
      all_headings_alignment: 'left',
      all_headings_font_size: 80,
      all_headings_font_color: '#010100',
      all_headings_font_family: 'lora',
      all_headings_font_weight: '700',
      all_headings_italic: false,
      all_headings_underlined: false,
      all_headings_strikethrough: false,
      h1_alignment: 'left',
      h1_font_size: 80,
      h1_font_color: '#010100',
      h1_font_family: 'lora',
      h1_font_weight: '700',
      h1_italic: false,
      h1_underlined: false,
      h1_strikethrough: false,
      h2_alignment: 'left',
      h2_font_size: 50,
      h2_font_color: '#010100',
      h2_font_family: 'lora',
      h2_font_weight: '700',
      h2_italic: false,
      h2_underlined: false,
      h2_strikethrough: false,
      h3_alignment: 'left',
      h3_font_size: 40,
      h3_font_color: '#010100',
      h3_font_family: 'lora',
      h3_font_weight: '400',
      h3_italic: false,
      h3_underlined: false,
      h3_strikethrough: false,
    },
  },
  {
    name: 'Purple Fabric',
    styles: {
      background_color: '#695E93',
      background_color_type: 'solid',
      background_color_gradient_from: '#695E93',
      background_color_gradient_to: '#8155BA',
      background_color_gradient_angle: 120,
      body_alignment: 'left',
      body_font_size: 40,
      body_font_color: '#BEAFC2',
      body_font_family: 'rubik',
      body_font_weight: '400',
      body_italic: false,
      body_underlined: false,
      body_strikethrough: false,
      all_headings_alignment: 'left',
      all_headings_font_size: 80,
      all_headings_font_color: '#281C2D',
      all_headings_font_family: 'noticiatext',
      all_headings_font_weight: '700',
      all_headings_italic: false,
      all_headings_underlined: false,
      all_headings_strikethrough: false,
      h1_alignment: 'left',
      h1_font_size: 80,
      h1_font_color: '#281C2D',
      h1_font_family: 'noticiatext',
      h1_font_weight: '700',
      h1_italic: false,
      h1_underlined: false,
      h1_strikethrough: false,
      h2_alignment: 'left',
      h2_font_size: 50,
      h2_font_color: '#281C2D',
      h2_font_family: 'noticiatext',
      h2_font_weight: '700',
      h2_italic: false,
      h2_underlined: false,
      h2_strikethrough: false,
      h3_alignment: 'left',
      h3_font_size: 40,
      h3_font_color: '#281C2D',
      h3_font_family: 'noticiatext',
      h3_font_weight: '400',
      h3_italic: false,
      h3_underlined: false,
      h3_strikethrough: false,
    },
  },
  {
    name: 'Smiling Roses',
    styles: {
      background_color: '#2E765E',
      background_color_type: 'gradient',
      background_color_gradient_from: '#2E765E',
      background_color_gradient_to: '#638C80',
      background_color_gradient_angle: 120,
      body_alignment: 'left',
      body_font_size: 40,
      body_font_color: '#F1CCD7',
      body_font_family: 'noto sans',
      body_font_weight: '400',
      body_italic: false,
      body_underlined: false,
      body_strikethrough: false,
      all_headings_alignment: 'left',
      all_headings_font_size: 80,
      all_headings_font_color: '#E2808A',
      all_headings_font_family: 'crimsontext',
      all_headings_font_weight: '700',
      all_headings_italic: false,
      all_headings_underlined: false,
      all_headings_strikethrough: false,
      h1_alignment: 'left',
      h1_font_size: 80,
      h1_font_color: '#E2808A',
      h1_font_family: 'crimsontext',
      h1_font_weight: '700',
      h1_italic: false,
      h1_underlined: false,
      h1_strikethrough: false,
      h2_alignment: 'left',
      h2_font_size: 50,
      h2_font_color: '#E2808A',
      h2_font_family: 'crimsontext',
      h2_font_weight: '700',
      h2_italic: false,
      h2_underlined: false,
      h2_strikethrough: false,
      h3_alignment: 'left',
      h3_font_size: 40,
      h3_font_color: '#E2808A',
      h3_font_family: 'crimsontext',
      h3_font_weight: '400',
      h3_italic: false,
      h3_underlined: false,
      h3_strikethrough: false,
    },
  },
  {
    name: 'Pastel Dreams',
    styles: {
      background_color: '#ffb6c1',
      background_color_type: 'gradient',
      background_color_gradient_from: '#ffb6c1',
      background_color_gradient_to: '#ff69b4',
      background_color_gradient_angle: 120,
      body_alignment: 'left',
      body_font_size: 40,
      body_font_color: '#484848',
      body_font_family: 'ubuntu',
      body_font_weight: '400',
      body_italic: false,
      body_underlined: false,
      body_strikethrough: false,
      all_headings_alignment: 'left',
      all_headings_font_size: 80,
      all_headings_font_color: '#ff1493',
      all_headings_font_family: 'bitter',
      all_headings_font_weight: '700',
      all_headings_italic: false,
      all_headings_underlined: false,
      all_headings_strikethrough: false,
      h1_alignment: 'left',
      h1_font_size: 80,
      h1_font_color: '#ff1493',
      h1_font_family: 'bitter',
      h1_font_weight: '700',
      h1_italic: false,
      h1_underlined: false,
      h1_strikethrough: false,
      h2_alignment: 'left',
      h2_font_size: 50,
      h2_font_color: '#ff1493',
      h2_font_family: 'bitter',
      h2_font_weight: '700',
      h2_italic: false,
      h2_underlined: false,
      h2_strikethrough: false,
      h3_alignment: 'left',
      h3_font_size: 40,
      h3_font_color: '#ff1493',
      h3_font_family: 'bitter',
      h3_font_weight: '400',
      h3_italic: false,
      h3_underlined: false,
      h3_strikethrough: false,
    },
  },
  {
    name: 'Urban Chic',
    styles: {
      background_color: '#434343',
      background_color_type: 'gradient',
      background_color_gradient_from: '#434343',
      background_color_gradient_to: '#000000',
      background_color_gradient_angle: 120,
      body_alignment: 'left',
      body_font_size: 40,
      body_font_color: '#ffffff',
      body_font_family: 'montserrat',
      body_font_weight: '400',
      body_italic: false,
      body_underlined: false,
      body_strikethrough: false,
      all_headings_alignment: 'left',
      all_headings_font_size: 80,
      all_headings_font_color: '#ff6347',
      all_headings_font_family: 'firasans',
      all_headings_font_weight: '700',
      all_headings_italic: false,
      all_headings_underlined: false,
      all_headings_strikethrough: false,
      h1_alignment: 'left',
      h1_font_size: 80,
      h1_font_color: '#ff6347',
      h1_font_family: 'firasans',
      h1_font_weight: '700',
      h1_italic: false,
      h1_underlined: false,
      h1_strikethrough: false,
      h2_alignment: 'left',
      h2_font_size: 50,
      h2_font_color: '#ff6347',
      h2_font_family: 'firasans',
      h2_font_weight: '700',
      h2_italic: false,
      h2_underlined: false,
      h2_strikethrough: false,
      h3_alignment: 'left',
      h3_font_size: 40,
      h3_font_color: '#ff6347',
      h3_font_family: 'firasans',
      h3_font_weight: '400',
      h3_italic: false,
      h3_underlined: false,
      h3_strikethrough: false,
    },
  },
  {
    name: 'Sunshine Bliss',
    styles: {
      background_color: '#fdfd96',
      background_color_type: 'gradient',
      background_color_gradient_from: '#fdfd96',
      background_color_gradient_to: '#ffd700',
      background_color_gradient_angle: 120,
      body_alignment: 'left',
      body_font_size: 40,
      body_font_color: '#4f4f4f',
      body_font_family: 'poppins',
      body_font_weight: '400',
      body_italic: false,
      body_underlined: false,
      body_strikethrough: false,
      all_headings_alignment: 'left',
      all_headings_font_size: 80,
      all_headings_font_color: '#ffa500',
      all_headings_font_family: 'merriweather',
      all_headings_font_weight: '700',
      all_headings_italic: false,
      all_headings_underlined: false,
      all_headings_strikethrough: false,
      h1_alignment: 'left',
      h1_font_size: 80,
      h1_font_color: '#ffa500',
      h1_font_family: 'merriweather',
      h1_font_weight: '700',
      h1_italic: false,
      h1_underlined: false,
      h1_strikethrough: false,
      h2_alignment: 'left',
      h2_font_size: 50,
      h2_font_color: '#ffa500',
      h2_font_family: 'merriweather',
      h2_font_weight: '700',
      h2_italic: false,
      h2_underlined: false,
      h2_strikethrough: false,
      h3_alignment: 'left',
      h3_font_size: 40,
      h3_font_color: '#ffa500',
      h3_font_family: 'merriweather',
      h3_font_weight: '400',
      h3_italic: false,
      h3_underlined: false,
      h3_strikethrough: false,
    },
  },
  {
    name: 'Neon Mirage',
    styles: {
      background_color: '#00DBDE',
      background_color_type: 'gradient',
      background_color_gradient_from: '#228be6',
      background_color_gradient_to: '#FC00FF',
      background_color_gradient_angle: 120,
      body_alignment: 'left',
      body_font_size: 40,
      body_font_color: '#FFFFFF',
      body_font_family: 'kalam',
      body_font_weight: '400',
      body_italic: false,
      body_underlined: false,
      body_strikethrough: false,
      all_headings_alignment: 'left',
      all_headings_font_size: 80,
      all_headings_font_color: '#FFFFFF',
      all_headings_font_family: 'merriweathersans',
      all_headings_font_weight: '700',
      all_headings_italic: false,
      all_headings_underlined: false,
      all_headings_strikethrough: false,
      h1_alignment: 'left',
      h1_font_size: 80,
      h1_font_color: '#FFFFFF',
      h1_font_family: 'merriweathersans',
      h1_font_weight: '700',
      h1_italic: false,
      h1_underlined: false,
      h1_strikethrough: false,
      h2_alignment: 'left',
      h2_font_size: 50,
      h2_font_color: '#FFFFFF',
      h2_font_family: 'merriweathersans',
      h2_font_weight: '700',
      h2_italic: false,
      h2_underlined: false,
      h2_strikethrough: false,
      h3_alignment: 'left',
      h3_font_size: 40,
      h3_font_color: '#FFFFFF',
      h3_font_family: 'merriweathersans',
      h3_font_weight: '400',
      h3_italic: false,
      h3_underlined: false,
      h3_strikethrough: false,
    },
  },
  {
    name: 'Sunny Mint',
    styles: {
      background_color: '#85FFBD',
      background_color_type: 'gradient',
      background_color_gradient_from: '#85FFBD',
      background_color_gradient_to: '#FFFB7D',
      background_color_gradient_angle: 120,
      body_alignment: 'left',
      body_font_size: 40,
      body_font_color: '#2f2f2f',
      body_font_family: 'raleway',
      body_font_weight: '400',
      body_italic: false,
      body_underlined: false,
      body_strikethrough: false,
      all_headings_alignment: 'left',
      all_headings_font_size: 80,
      all_headings_font_color: '#000000',
      all_headings_font_family: 'lora',
      all_headings_font_weight: '700',
      all_headings_italic: false,
      all_headings_underlined: false,
      all_headings_strikethrough: false,
      h1_alignment: 'left',
      h1_font_size: 80,
      h1_font_color: '#000000',
      h1_font_family: 'lora',
      h1_font_weight: '700',
      h1_italic: false,
      h1_underlined: false,
      h1_strikethrough: false,
      h2_alignment: 'left',
      h2_font_size: 50,
      h2_font_color: '#000000',
      h2_font_family: 'lora',
      h2_font_weight: '700',
      h2_italic: false,
      h2_underlined: false,
      h2_strikethrough: false,
      h3_alignment: 'left',
      h3_font_size: 40,
      h3_font_color: '#000000',
      h3_font_family: 'lora',
      h3_font_weight: '400',
      h3_italic: false,
      h3_underlined: false,
      h3_strikethrough: false,
    },
  },
];

export function Themes({
  height = '100px',
  user,
  openUpgradeModal,
  form,
  showThemeCardTitleInput,
  setShowThemeCardTitleInput,
}) {
  const [themeType, setThemeType] = useState('preset');
  const { id } = useParams();
  const { data: fonts } = useQuery({
    queryKey: ['fonts'],
    queryFn: getFonts,
  });
  const { data: customThemes } = useQuery({
    queryKey: ['themes'],
    queryFn: getThemes,
  });
  const normalizedFonts = normalize(fonts);

  useEffect(() => {
    if (Object.values(normalizedFonts).length > 0)
      PRESET_THEMES.forEach((theme) => {
        loadFont(
          normalizedFonts[theme.styles.all_headings_font_family],
          theme.styles.all_headings_font_weight
        );
        loadFont(normalizedFonts[theme.styles.body_font_family], theme.styles.body_font_weight);
      });
  }, [normalizedFonts]);

  return (
    <Stack w="100%" h={height} flex="1">
      <SegmentedControl
        withItemsBorders={false}
        fullWidth
        value={themeType}
        onChange={(value) => {
          if (user.active_subscription || value === 'preset') {
            setThemeType(value);
          } else {
            openUpgradeModal();
          }
        }}
        data={[
          { label: 'Preset', value: 'preset' },
          {
            label: (
              <Center>
                <span>Custom</span>
                <Badge
                  ml="xs"
                  radius="sm"
                  size="xs"
                  variant="gradient"
                  gradient={{ from: '#FE9920', to: '#FF2A77' }}
                >
                  PRO
                </Badge>
              </Center>
            ),
            value: 'custom',
          },
        ]}
        mb="md"
      />
      <Stack gap="0" style={{ overflow: 'auto' }} h="100%" align="center">
        <ThemeCards
          showThemeCardTitleInput={showThemeCardTitleInput}
          setShowThemeCardTitleInput={setShowThemeCardTitleInput}
          themeType={themeType}
          form={form}
          themes={themeType === 'preset' ? PRESET_THEMES : customThemes}
        />
      </Stack>
    </Stack>
  );
}
