import { formatDate } from '@/helpers';
import {
  Avatar,
  Badge,
  Container,
  Group,
  Image,
  Text,
  Title,
  TypographyStylesProvider,
  UnstyledButton,
  rem,
} from '@mantine/core';
import { useEffect, useState } from 'react';
import Markdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import { Footer } from '../HomePage/Footer';
import { NavBar } from '../HomePage/NavBar';
import { TableOfContents } from '../TableOfContents/TableOfContents';
import posts from './posts.json';

const generateId = (text) => {
  return text
    .toLowerCase() // Convert to lower case
    .replace(/[^a-z0-9]+/g, '-') // Replace non-alphanumeric characters with hyphens
    .replace(/^-+|-+$/g, ''); // Remove leading and trailing hyphens
};

export function BlogPost() {
  const { id } = useParams();
  const [links, setLinks] = useState([]);
  const [post, setPost] = useState('');
  import(`./posts/${id}.md`).then((res) => {
    fetch(res.default)
      .then((response) => response.text())
      .then((text) => setPost(text));
  });
  const metadata = posts.find((post) => post.slug === id);

  useEffect(() => {
    const generateLinks = () => {
      const linksArray = [];
      const headings = document.querySelectorAll('h2, h3, h4');

      headings.forEach((heading) => {
        const level = heading.tagName.toLowerCase();
        const order = parseInt(level.replace('h', ''));
        heading.id = generateId(heading.textContent);
        const text = heading.textContent;

        linksArray.push({ label: text, link: `#${heading.id}`, order });
      });

      setLinks(linksArray);
    };

    generateLinks();
  }, [post]);

  return (
    <>
      <NavBar />
      <Container size="lg" pt={70}>
        <Container size="md">
          <Title order={1} fz={{ sm: rem(30), md: rem(50) }} fw={900} mt={rem(90)} mb="lg">
            {metadata.title}
          </Title>
          <Text size="xl" mb="lg">
            {metadata.summary}
          </Text>
          <Group justify="space-between" mb="lg" align="center">
            <Group align="center">
              <Avatar src={metadata.authorImg} radius="xl" />
              <UnstyledButton component="a" href={`/blog?author=${metadata.author}`}>
                <Text fw={500}>{metadata.author}</Text>
              </UnstyledButton>
              <Text fz="xs" c="dimmed">
                {formatDate(metadata.publishedAt)}
              </Text>
            </Group>
            <Group gap="2px">
              {metadata.tags.map((tag) => (
                <UnstyledButton component="a" href={`/blog?tag=${tag}`}>
                  <Badge w="fit-content" variant="light" size="md">
                    {tag}
                  </Badge>
                </UnstyledButton>
              ))}
            </Group>
          </Group>
        </Container>
        <Image src={metadata.image} mb="lg"></Image>
        <Container size="md">
          <TableOfContents links={links} />
          <TypographyStylesProvider styles={{ root: { fontSize: rem(18) } }}>
            <Markdown>{post}</Markdown>
          </TypographyStylesProvider>
        </Container>
      </Container>
      <Footer />
    </>
  );
}
