import { Box, Divider, NavLink, rem, Text } from '@mantine/core';
import articles from './articles.json';

export function NavSidebar() {
  return (
    <Box>
      <Text id="choose-topic" fz={rem(25)} fw={600} mb="md" px="md">
        Choose a topic
      </Text>
      <Divider />
      <NavLink
        href="#"
        label="Convert to Google Slides"
        py="sm"
        px="md"
        styles={{ label: { fontSize: rem(18) } }}
      >
        {articles
          .filter((article) => article.product === 'Convert to Google Slides')
          .map((article, index, filteredArticles) => (
            <>
              <NavLink
                href={`/help/${article.slug}`}
                label={article.title}
                py="sm"
                px="md"
                styles={{ label: { fontSize: rem(17) } }}
              />
              {index !== filteredArticles.length - 1 && <Divider />}
            </>
          ))}
      </NavLink>
      <Divider />
      {articles
        .filter((article) => !article.product)
        .map((article) => (
          <>
            <NavLink
              href={`/help/${article.slug}`}
              label={article.title}
              py="sm"
              px="md"
              styles={{ label: { fontSize: rem(18) } }}
            />
            <Divider />
          </>
        ))}
    </Box>
  );
}
