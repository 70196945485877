import { ActionIcon, Paper, Stack, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
  IconCalculator,
  IconCaretLeft,
  IconCaretRight,
  IconHourglassHigh,
  IconReorder,
  IconWriting,
} from '@tabler/icons-react';
import { useState } from 'react';
import { Rnd } from 'react-rnd';
import { PageList } from './PageList';
import { Timer } from './Timer';
import { Calculator } from '../Calculator/Calculator';

export function Widgets({
  presentation,
  formValues,
  belongsToUser,
  openAnnotations,
  closeAnnotations,
}) {
  const [showTimer, { open: openTimer, close: closeTimer }] = useDisclosure(false);
  const [showCalculator, { open: openCalculator, close: closeCalculator }] = useDisclosure(false);
  const [showPageList, { open: openPageList, close: closePageList }] = useDisclosure(false);
  const [position, setPosition] = useState({ x: 5, y: 5 });

  return (
    <>
      <Rnd
        enableResizing={false}
        style={{ zIndex: 1 }}
        position={position}
        onDragStop={(e, d) => {
          setPosition({ x: d.x, y: d.y });
        }}
        cancel=".cancel"
      >
        <Paper p="3px">
          <Stack gap="3px">
            <Tooltip label="Previous page">
              <ActionIcon
                size="lg"
                variant="subtle"
                aria-label="Scroll previous"
                onClick={() => window.emblaInstance.scrollPrev()}
                className="cancel"
              >
                <IconCaretLeft style={{ width: '70%', height: '70%' }} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Next page">
              <ActionIcon
                size="lg"
                variant="subtle"
                aria-label="Scroll next"
                onClick={() => window.emblaInstance.scrollNext()}
                className="cancel"
              >
                <IconCaretRight style={{ width: '70%', height: '70%' }} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
            {belongsToUser && (
              <Tooltip label="Page list">
                <ActionIcon
                  size="lg"
                  variant="subtle"
                  aria-label="Page list"
                  onClick={() => {
                    openPageList();
                    closeTimer();
                    closeAnnotations();
                    closeCalculator();
                  }}
                  className="cancel"
                >
                  <IconReorder style={{ width: '70%', height: '70%' }} stroke={1.5} />
                </ActionIcon>
              </Tooltip>
            )}
            {belongsToUser && (
              <Tooltip label="Timer">
                <ActionIcon
                  size="lg"
                  variant="subtle"
                  aria-label="Timer"
                  onClick={() => {
                    openTimer();
                    closePageList();
                    closeAnnotations();
                    closeCalculator();
                  }}
                  className="cancel"
                >
                  <IconHourglassHigh style={{ width: '70%', height: '70%' }} stroke={1.5} />
                </ActionIcon>
              </Tooltip>
            )}
            {belongsToUser && (
              <Tooltip label="Annotations">
                <ActionIcon
                  size="lg"
                  variant="subtle"
                  aria-label="Timer"
                  onClick={() => {
                    openAnnotations();
                    closePageList();
                    closeTimer();
                    closeCalculator();
                  }}
                  className="cancel"
                >
                  <IconWriting style={{ width: '70%', height: '70%' }} stroke={1.5} />
                </ActionIcon>
              </Tooltip>
            )}
            {belongsToUser && (
              <Tooltip label="Calculator">
                <ActionIcon
                  size="lg"
                  variant="subtle"
                  aria-label="Calculator"
                  onClick={() => {
                    openCalculator();
                    closePageList();
                    closeTimer();
                    closeAnnotations();
                  }}
                  className="cancel"
                >
                  <IconCalculator style={{ width: '70%', height: '70%' }} stroke={1.5} />
                </ActionIcon>
              </Tooltip>
            )}
          </Stack>
        </Paper>
      </Rnd>
      {showTimer && <Timer closeTimer={closeTimer} />}
      {showPageList && (
        <PageList
          presentation={presentation}
          closePageList={closePageList}
          formValues={formValues}
        />
      )}
      {showCalculator && <Calculator closeCalculator={closeCalculator} />}
    </>
  );
}
