import { Dashboard } from '@/components/Dashboard/Dashboard';
import { HomePage } from '@/components/HomePage/HomePage';
import { Presentation } from '@/components/Presentation/Presentation';
import { PrivacyPolicy } from '@/components/PrivacyPolicy/PrivacyPolicy';
import { SignIn } from '@/components/SignIn/SignIn';
import { SignUp } from '@/components/SignUp/SignUp';
import { TermsOfUse } from '@/components/TermsOfUse/TermsOfUse';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { BlogIndex } from './components/Blog/BlogIndex';
import { BlogPost } from './components/Blog/BlogPost';
import { HelpArticle } from './components/HelpCenter/HelpArticle';

const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />,
  },
  {
    path: '/presentations',
    element: <Dashboard />,
  },
  {
    path: '/presentations/:id',
    element: <Presentation />,
  },
  {
    path: '/user/settings',
    element: <Dashboard />,
  },
  {
    path: '/user/collaborators',
    element: <Dashboard />,
  },
  {
    path: '/users/sign_in',
    element: <SignIn />,
  },
  {
    path: '/users/sign_up',
    element: <SignUp />,
  },
  {
    path: '/terms_of_use',
    element: <TermsOfUse />,
  },
  {
    path: '/privacy_policy',
    element: <PrivacyPolicy />,
  },
  {
    path: '/blog',
    element: <BlogIndex />,
  },
  {
    path: '/blog/:id',
    element: <BlogPost />,
  },
  {
    path: '/help/:id',
    element: <HelpArticle />,
  },
]);

export function Router() {
  return <RouterProvider router={router} />;
}
