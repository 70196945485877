import { processError } from '@/helpers';
import { createTheme } from '@/requests';
import {
  ActionIcon,
  Badge,
  Button,
  CloseButton,
  Group,
  Popover,
  Stack,
  Tabs,
  TextInput,
  Title,
  rem,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import {
  IconBackground,
  IconBadgeTm,
  IconPhotoPlus,
  IconPlus,
  IconSlideshow,
  IconTypography,
} from '@tabler/icons-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { Background } from './Background';
import { ImageUpload } from './ImageUpload';
import classes from './StyleNavbar.module.css';
import { Themes } from './Themes';
import { Typography } from './Typography';
import { useClickOutside } from '@mantine/hooks';

const sidebarLinksData = [
  { link: '', label: 'Themes', icon: IconSlideshow, paywalled: false },
  { link: '', label: 'Typography', icon: IconTypography, paywalled: false },
  { link: '', label: 'Background', icon: IconBackground, paywalled: false },
  { link: '', label: 'Logo', icon: IconBadgeTm, paywalled: true },
];

function Logo({ form, formValues }) {
  return (
    <form>
      <Title size="h3" mb="md">
        Logo
      </Title>
      <ImageUpload form={form} formValues={formValues} />
    </form>
  );
}

function CreateCustomTheme({
  opened,
  setOpened,
  user,
  openUpgradeModal,
  createThemeForm,
  createThemeMutation,
  form,
}) {
  return (
    <Popover
      width={300}
      trapFocus
      position="bottom-start"
      shadow="md"
      opened={opened}
      onChange={setOpened}
    >
      <Popover.Target>
        <Button
          variant="default"
          leftSection={<IconPhotoPlus size={14} />}
          onClick={() => (user.active_subscription ? setOpened((o) => !o) : openUpgradeModal())}
        >
          Create New Theme
          <Badge
            ml="xs"
            radius="sm"
            size="xs"
            variant="gradient"
            gradient={{ from: '#FE9920', to: '#FF2A77' }}
          >
            PRO
          </Badge>
        </Button>
      </Popover.Target>
      <Popover.Dropdown>
        <Group align="center">
          <TextInput
            style={{ flexGrow: 1 }}
            key={createThemeForm.key('name')}
            {...createThemeForm.getInputProps('name')}
            placeholder="New Theme"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                createThemeMutation.mutate({
                  ...form.getValues(),
                  ...createThemeForm.getValues(),
                });
              }
            }}
          />
          <ActionIcon
            size={36}
            variant="filled"
            aria-label="Settings"
            onClick={() =>
              createThemeMutation.mutate({
                ...form.getValues(),
                ...createThemeForm.getValues(),
              })
            }
          >
            <IconPlus style={{ width: '70%', height: '70%' }} stroke={1.5} />
          </ActionIcon>
        </Group>
      </Popover.Dropdown>
    </Popover>
  );
}

export function StyleNavbar({
  user,
  openUpgradeModal,
  showThemeCardTitleInput,
  setShowThemeCardTitleInput,
  form,
  formValues,
  closeSidenav,
}) {
  const [active, setActive] = useState('Themes');
  const queryClient = useQueryClient();
  const createThemeForm = useForm({ mode: 'uncontrolled' });
  const [openedCreateCustomThemeForDesktop, setOpenedCreateCustomThemeForDesktop] = useState(false);
  const [openedCreateCustomThemeForMobile, setOpenedCreateCustomThemeForMobile] = useState(false);
  const createThemeMutation = useMutation({
    mutationFn: createTheme,
    onSuccess: (data) => {
      queryClient.setQueryData(['themes'], (oldData: []) => [...oldData, data]);
      createThemeForm.reset();
      setOpenedCreateCustomThemeForDesktop(false);
      setOpenedCreateCustomThemeForMobile(false);
      notifications.show({
        message: 'Successfully created custom theme!',
      });
    },
    onError: processError,
  });

  return (
    <>
      <Tabs
        defaultValue="themes"
        hiddenFrom="sm"
        h="100%"
        display="flex"
        style={{ flexDirection: 'column' }}
      >
        <Group justify="right">
          <CloseButton size="md" onClick={closeSidenav} />
        </Group>
        <CreateCustomTheme
          opened={openedCreateCustomThemeForMobile}
          setOpened={setOpenedCreateCustomThemeForMobile}
          user={user}
          openUpgradeModal={openUpgradeModal}
          createThemeForm={createThemeForm}
          createThemeMutation={createThemeMutation}
          form={form}
        />
        <Group gap="0" align="top">
          <Tabs.List mb="md" flex="1">
            {sidebarLinksData.map((item) => (
              <Tabs.Tab
                size="xs"
                value={item.label.toLowerCase()}
                leftSection={<item.icon style={{ width: rem(12), height: rem(12) }} />}
                disabled={item.paywalled && !user.active_subscription}
              >
                {item.label}
                {item.paywalled && (
                  <Badge
                    ml="xs"
                    radius="sm"
                    size="xs"
                    variant="gradient"
                    gradient={{ from: '#FE9920', to: '#FF2A77' }}
                  >
                    PRO
                  </Badge>
                )}
              </Tabs.Tab>
            ))}
          </Tabs.List>
        </Group>

        <Tabs.Panel value="themes" flex="1" h="100px">
          <Themes
            height="100%"
            openUpgradeModal={openUpgradeModal}
            user={user}
            showThemeCardTitleInput={showThemeCardTitleInput}
            setShowThemeCardTitleInput={setShowThemeCardTitleInput}
            form={form}
          />
        </Tabs.Panel>
        <Tabs.Panel value="typography">
          <Typography form={form} formValues={formValues} />
        </Tabs.Panel>
        <Tabs.Panel value="background">
          <Background form={form} formValues={formValues} />
        </Tabs.Panel>
        <Tabs.Panel value="logo">
          <Logo form={form} formValues={formValues} />
        </Tabs.Panel>
      </Tabs>

      <Group align="start" h="100%" visibleFrom="sm">
        <nav className={classes.navbar} style={{ height: '100%' }}>
          <div className={classes.navbarMain}>
            {sidebarLinksData.map((item) => (
              <a
                className={classes.link}
                data-active={item.label === active || undefined}
                href={item.link}
                key={item.label}
                onClick={(event) => {
                  event.preventDefault();
                  item.paywalled && !user.active_subscription
                    ? openUpgradeModal()
                    : setActive(item.label);
                }}
              >
                <item.icon className={classes.linkIcon} stroke={1.5} />
                <span>{item.label}</span>
                {item.paywalled && (
                  <Badge
                    ml="xs"
                    radius="sm"
                    size="xs"
                    variant="gradient"
                    gradient={{ from: '#FE9920', to: '#FF2A77' }}
                  >
                    PRO
                  </Badge>
                )}
              </a>
            ))}
          </div>
        </nav>
        <Stack flex="1" w="100%" h="100%">
          <Group justify="right">
            <CloseButton size="md" onClick={closeSidenav} />
          </Group>
          <Group visibleFrom="sm" justify="end" mb="md">
            <CreateCustomTheme
              opened={openedCreateCustomThemeForDesktop}
              setOpened={setOpenedCreateCustomThemeForDesktop}
              user={user}
              openUpgradeModal={openUpgradeModal}
              createThemeForm={createThemeForm}
              createThemeMutation={createThemeMutation}
              form={form}
            />
          </Group>
          {active === 'Themes' && (
            <Themes
              openUpgradeModal={openUpgradeModal}
              user={user}
              showThemeCardTitleInput={showThemeCardTitleInput}
              setShowThemeCardTitleInput={setShowThemeCardTitleInput}
              form={form}
            />
          )}
          {active === 'Typography' && <Typography form={form} formValues={formValues} />}
          {active === 'Background' && <Background form={form} formValues={formValues} />}
          {active === 'Logo' && <Logo form={form} formValues={formValues} />}
        </Stack>
      </Group>
    </>
  );
}
