import {
  Box,
  Button,
  Card,
  Group,
  SimpleGrid,
  Stack,
  Text,
  Title
} from '@mantine/core';
import { modals } from '@mantine/modals';
import { CloseAccountForm } from './CloseAccountForm';

export function UserSettings({ user }) {
  const manageSubscriptionUrl = () => {
    const baseUrl = 'https://billing.stripe.com/p/login/14k02t8QRcD03yUdQQ?prefilled_email=';
    const email = user.email;
    const encodedEmail = encodeURIComponent(email);
    return baseUrl + encodedEmail;
  };

  return (
    <>
      <Title order={2} my="md">
        User Settings
      </Title>
      <SimpleGrid cols={{ base: 1, md: 2, lg: 3 }}>
        {user && user.active_subscription && (
          <Card>
            <Title order={3} mb="sm">
              Subscription information
            </Title>

            <Group mb="lg" gap="lg">
              <Box>
                <Title fw={400} order={4} mb="xs">
                  Product
                </Title>
                <Text fw={600}>{user.active_subscription.apps[0]}</Text>
              </Box>
              <Box>
                <Title fw={400} order={4} mb="xs">
                  Renewal date
                </Title>
                <Text fw={600}>
                  {new Date(user.active_subscription.expires_at).toLocaleDateString(undefined, {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}
                </Text>
              </Box>
            </Group>
            <Stack h="100%" justify="flex-end">
              <Button component="a" href={manageSubscriptionUrl()} target="_blank">
                Manage Subscription
              </Button>
            </Stack>
          </Card>
        )}

        <Card>
          <Title order={3} mb="sm">
            Close your account
          </Title>

          <Text flex="1" mb="lg">
            By closing your account, presentations, themes, subscriptions and user profile data
            belonging to you will be permanently deleted. This operation is permanent and cannot be
            reversed.{' '}
          </Text>
          <Stack h="100%" justify="flex-end">
            <Button
              bg="red"
              onClick={() => {
                modals.open({
                  title: 'Close account',
                  children: <CloseAccountForm user={user} />,
                });
              }}
            >
              Close account
            </Button>
          </Stack>
        </Card>
      </SimpleGrid>
    </>
  );
}
