import { Router } from '@/Router';
import '@mantine/carousel/styles.css';
import { MantineProvider, createTheme } from '@mantine/core';
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import './global.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { MathJaxContext } from 'better-react-mathjax';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <MantineProvider
        theme={createTheme({
          colors: {
            dark: [
              '#C9C9C9',
              '#b8b8b8',
              '#828282',
              '#696969',
              '#424242',
              '#3b3b3b',
              '#2e2e2e',
              '#000',
              '#1f1f1f',
              '#141414',
            ],
          },
        })}
      >
        <MathJaxContext
          config={{
            loader: { load: ['[tex]/html'] },
            tex: {
              packages: { '[+]': ['html'] },
              inlineMath: [
                ['$', '$'],
                ['\\(', '\\)'],
              ],
              displayMath: [
                ['$$', '$$'],
                ['\\[', '\\]'],
              ],
            },
          }}
        >
          <Router />
        </MathJaxContext>
      </MantineProvider>
    </QueryClientProvider>
  );
}
