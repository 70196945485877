import { useRefState } from '@/hooks/useRefState';
import { Carousel } from '@mantine/carousel';
import { Box } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AnnotationsToolbar } from './AnnotationsToolbar';
import { Skel } from './Skel';
import { Slides } from './Slides';
import { Widgets } from './Widgets';

export function Screens({
  updateFunctionRef,
  setUpdateFunction,
  fullscreen,
  formValues,
  isLoading,
  presentation,
  user,
  height = '100%',
  displayOnly = false,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialSlideRef = useRef(parseInt(searchParams.get('slide')) || 0);
  const [embla, setEmbla] = useState(null);
  const [keydownEventListenerAdded, setKeydownEventListenerAdded] = useState(false);
  const fullscreenRef = useRef(fullscreen);
  const [
    showAnnotations,
    { open: openAnnotations, toggle: toggleAnnotations, close: closeAnnotations },
  ] = useDisclosure(false);
  const [annotationSettingsRef, annotationSettings, setAnnotationSettings] = useRefState({
    mode: 'pen',
    penColor: '#0000FF',
    textColor: '#0000FF',
  });
  const updateAnnotationSettings = (key, value) => {
    setAnnotationSettings((prevSettings) => ({
      ...prevSettings,
      [key]: value,
    }));
  };
  const getInitialSlide = () => initialSlideRef.current;

  useEffect(() => {
    fullscreenRef.current = fullscreen;
  }, [fullscreen]);

  useEffect(() => {
    if (embla && !keydownEventListenerAdded) {
      document.querySelector('body').addEventListener('keydown', (event) => {
        if (['BODY', 'BUTTON'].includes(event.target?.tagName))
          switch (event.key) {
            case 'ArrowUp':
            case 'PageUp':
            case 'ArrowLeft':
              embla.scrollPrev();
              break;
            case 'ArrowDown':
            case 'PageDown':
            case 'ArrowRight':
            case 'Enter':
            case 'Tab':
            case ' ':
              embla.scrollNext();
              break;
            default:
              break;
          }
      });
      setKeydownEventListenerAdded(true);
      return;
    }
  }, [embla]);

  const belongsToUser = user && presentation && user.user_id === presentation.user_id;

  return (
    <Box pos="relative">
      {!displayOnly && (
        <Widgets
          presentation={presentation}
          formValues={formValues}
          belongsToUser={belongsToUser}
          toggleAnnotations={toggleAnnotations}
          openAnnotations={openAnnotations}
          closeAnnotations={closeAnnotations}
        />
      )}
      {showAnnotations && (
        <AnnotationsToolbar
          closeAnnotations={closeAnnotations}
          settings={annotationSettings}
          updateSettings={updateAnnotationSettings}
        />
      )}
      <Carousel
        initialSlide={getInitialSlide()}
        onSlideChange={(index) => {
          searchParams.set('slide', index.toString());
          setSearchParams(searchParams);
          initialSlideRef.current = index;
        }}
        withKeyboardEvents={false}
        draggable={!showAnnotations}
        height="100%"
        orientation="vertical"
        styles={{
          viewport: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height:
              height === '100%' ? 'calc(100dvh - var(--app-shell-header-offset, 0rem))' : height,
          },
          container: {
            width: '100%',
          },
        }}
        withControls={false}
        getEmblaApi={(emblaInstance) => {
          window.emblaInstance = emblaInstance;
          setEmbla(emblaInstance);
        }}
        pos="relative"
      >
        {isLoading ? (
          <Skel />
        ) : presentation ? (
          <Slides
            presentation={presentation}
            formValues={formValues}
            displayOnly={displayOnly}
            showAnnotations={showAnnotations}
            annotationSettingsRef={annotationSettingsRef}
            updateAnnotationSettings={updateAnnotationSettings}
            setUpdateFunction={setUpdateFunction}
            updateFunctionRef={updateFunctionRef}
          />
        ) : null}
      </Carousel>
    </Box>
  );
}
