import { Container, Title } from '@mantine/core';
import Markdown from 'react-markdown';
import { Footer } from '../HomePage/Footer';
import { NavBar } from '../HomePage/NavBar';
import TermsOfUseMarkdown from './TermsOfUse.md?raw';

export function TermsOfUse() {
  return (
    <>
      <NavBar />
      <Container my="lg">
        <Title order={1}>Terms of Use</Title>
        <Markdown>{TermsOfUseMarkdown}</Markdown>
      </Container>
      <Footer />
    </>
  );
}
